import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from './Footer'
import { FormControl, InputLabel, Select, Checkbox, ListItemText, MenuItem } from '@mui/material';
import SlideShowDialog from "./SlideShowDialog";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Speedometer from 'react-d3-speedometer';
import Slider from '@mui/material/Slider';
import Image from 'mui-image'
import _ from "lodash";
import PlayIcon from '@mui/icons-material/PlayCircle';

class CompareFavorities extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.mapImage = "general/cartoon-map.png"

    this.state = {
      selectedLocation: "1234 Main Street Lane ($545,000)",

      compare_1:null,
      compare_2:null,
      compare_3:null,

      selectedTerm: '30',
      selectedPercentage: '5.5',
      selectedFinancing: '250000',
      selectedTerm: '15',
      showSlideShowDialog: false,
      pictureHeight: "100%",
      pictureWidth: "60%",
      Favorities: [],
      property:{
        summary:{
          id: "1",
          image: "homepic1.jpg",
          price: "$245,000",
          livingarea:"2,450",
          bedroom: "3",
          bathroom:"2.5",
          mls: "12344567",
          broker:"Howard Hanna"
        },  
        details:{

        },
        location:{
              lat: 43.1733377059405,
              lng: -76.29868574434212,
        },
        narative:{

        }, 
        pictures: [
            "homepic2.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
        ],
      }
    };
  }

  toggleSlideShowDialog = () => {
    this.setState({
      showSlideShowDialog: !this.state.showSlideShowDialog
    })
  }

  toggleCompareFavorities = () => {
    this.setState({
      CompareFavorities: !this.state.CompareFavorities,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };


  getPrimaryTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >

        {
        this.props.listing?
        <CommonTile
          uid={this.props.listing.ListingId}
          animationType={null}
          animationTime={null}
          tagTopRightIcon={ 

            this.props.favorities.includes(this.props.listing.ListingId) ? 
            <React.Fragment>
               <Image src={"logos/loveit-transp.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(this.props.listing)} fit="fit" height={40} width={60}/>              
              </React.Fragment>              
              :
              <React.Fragment>
               <Image src={"logos/loveit-transp-off.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(this.props.listing)} fit="fit" height={40} width={60}/>              
              </React.Fragment>    

          }
          tagTopRightTxt={null}
          tagTopRightPadding={"5px"}
          tagTopRightTxtColor={"black"}
          tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
          tagTopRightPaddingRight={"2px"}
          tagTopRightPaddingTop={"2px"}
          tagTopRightTxtType={"bold"}
          tagTopRightTxtAlign={"center"}
          tagTopRightBgColor={"rgba(255,255,255,0)"}
          tagTopRightBorder={"0px solid black"}
          tagTopRightBorderRadius={"5px"}
          tagTopRightCallback={null}

          tagBottomRightIcon={
            null
          }
          tagBottomRightTxt={null}
          tagBottomRightPadding={"5px"}
          tagBottomRightTxtColor={"black"}
          tagBottomRightTxtSize={["1rem", "1rem", "1rem"]}
          tagBottomRightPaddingRight={"5px"}
          tagBottomRightPaddingBottom={"5px"}
          tagBottomRightTxtType={"bold"}
          tagBottomRightTxtAlign={"center"}
          tagBottomRightBgColor={"rgba(255,255,255,.5)"}
          tagBottomRightBorder={"0px solid black"}
          tagBottomRightBorderRadius={"5px"}
          tagBottomRightCallback={null}

          tagTopLeftIcon={null}
          tagTopLeftTxt={
            this.props.listing.status
          }
          tagTopLeftPadding={"5px 10px 5px 10px"}
          tagTopLeftTxtColor={"black"}
          tagTopLeftTxtSize={[".75rem", ".75rem",  ".75rem"]}
          tagTopLeftPaddingLeft={"0px"}
          tagTopLeftPaddingTop={"0px"}
          tagTopLeftTxtType={"bold"}
          tagTopLeftTxtAlign={"center"}
          tagTopLeftBgColor={this.props.listing.status === "Available" ? "rgba(115, 190, 115,.95)" : "rgba(255, 255, 0,.85)"}
          tagTopLeftBorder={"0px solid black"}
          tagTopLeftBorderRadius={"0px"}

          tagBottomLeftIcon={null}
                tagBottomLeftTxt={this.props.listing.videos.length > 0 ?                 
                  <Button variant="filled" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".75rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
                    Video
                  </Button>                
                  : null
                }
            tagBottomLeftPadding={"0px"}
            tagBottomLeftTxtColor={this.props.theme.bannerColor}
            tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
            tagBottomLeftPaddingLeft={"5px"}
            tagBottomLeftPaddingBottom={"5px"}
            tagBottomLeftTxtType={"bold"}
            tagBottomLeftTxtAlign={"center"}
            tagBottomLeftBgColor={"rgba(255,255,255,1)"}
            tagBottomLeftBorder={"0px solid black"}
            tagBottomLeftBorderRadius={"20px"}
            tagBottomLeftCallback={this.showListingReport}

          topCallback={null}
          topPadding={"0px"}
          topPicture={null}
          topPictureAlign={"center"}
          topPictureSize={"cover"}
          topTxt={null}
          topTxtColor={"white"}
          topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
          topTxtType={"bold"}
          topTxtAlign={"left"}
          topVerticalAlign={"flex-end"}
          topHorizontalAlign={"flex-start"}
          topBgColor={"rgba(255,255,255,0)"}
          topHeight={["250px", "70%", "70%"]}
          topGraphType={null}
          topGraphData={null}
          topWidget={
            <Image src={this.props.listing.image} fit="cover" duration={500} />
          }

          mdlTagIcon={null}
          mdlTagTxt={null}
          mdlTagPadding={"0px"}
          mdlTagTxtColor={"black"}
          mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
          mdlTagPaddingRight={"4px"}
          mdlTagPaddingTop={"2px"}
          mdlTagTxtType={"bold"}
          mdlTagTxtAlign={"center"}
          mdlTagBgColor={"rgba(255,255,255,0)"}
          mdlTagBorder={"0px solid black"}
          mdlTagBorderRadius={"5px"}                
          mdlPadding={"0px"}
          mdlPicture={null}
          mdlPictureAlign={null}
          mdlTxtLineHeight={1.5}
          mdlTxt={null}

          mdlWidget={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "#FFFFFF",
                  height:"100%",
                  width:"100%",     
                  border: "0px solid #AAAAAA",
                }}
              >
                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      fontSize: {
                        xs:"1rem",
                        sm:"1rem",
                        md:"1rem",
                        lg:"1rem",
                        xl:"1rem",
                      },
                      fontWeight:"normal",
                    }}
                    xs={12}
                  >
                     &nbsp;
                      <Box sx={{
                        fontSize:{
                        xs:"1.25rem",
                        sm: "1.25rem",
                        md:"1.25rem",
                        lg:"1.25rem",
                        xl:"1.25rem"
                        },
                        fontWeight:"bold", display:"inline"}} >
                     &nbsp;{this.props.listing.price}
                     </Box>
                      &nbsp;|&nbsp;
                     {this.props.listing.details.sqf} sf &nbsp;|&nbsp;
                     {this.props.listing.details.bed} bd&nbsp;|&nbsp;
                     {this.props.listing.details.bath} ba
                </Grid>                           
    
                  <Grid
                item
                sx={{
                  overflowX:"hidden",
                  textAlign:"center",
                  fontSize: {
                    xs:"1rem",
                    sm: "1rem",
                    md:"1rem",
                    lg:"1rem",
                  }
                }}
                xs={12}
              >
                {this.props.listing.address}
              </Grid>                                 
              </Grid>                 
            }
          mdlTxtColor={"black"}
          mdlTxtSize={["1rem", "1rem",  "1rem"]}
          mdlTxtType={"normal"}
          mdlTxtAlign={"center"}
          mdlHorizontalAlign={"center"}
          mdlVerticalAlign={"flex-end"}
          mdlBgColor={"#FFFFFF"}
          mdlBorder={"1px solid black"}
          mdlHeight={["75px", "20%", "20%"]}
          mdlGraphType={null}
          mdlGraphData={null}                
          btmPadding={"2px"}
          btmPicture={null}
          btmPictureAlign={null}
          btmTxt={ 
          <center>
            <div style={{fontSize:".5rem"}}>
            <img alt="image" src="howardhanna/idx-logo-sm.png" height="10px"/>  MLS:  {this.props.listing.mls} - Listing By {this.props.listing.broker}
            </div>
          </center>      
        }
          btmTxtColor={"#333333"}
          btmTxtSize={[".75rem", ".75rem",  ".75rem"]}
          btmTxtType={"normal"}
          btmTxtAlign={"left"}
          btmHorizontalAlign={"center"}
          btmVerticalAlign={"center"}
          btmBgColor={"white"}
          btmBorder={"0px solid black"}
          btmHeight={["50px", "10%", "10%"]}
          btmGraphType={null}
          btmGraphData={null}
          border={"1px solid #333333"}
          shadow={4}
          downloads={""}
          linksTo={""}
          growTime={4000}
          padding={"0px"}
          borderRadius={null}
          bgcolor={"rgba(255,255,255,1)"}
        />
        :
        null
        }
        </Grid>
      </React.Fragment>
    );
  }


  getDetailsTiles = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={null}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["0px", "32px", "24px"]}
            topTxtType={"strong"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["0px", "0px", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"Features"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "16px", "16px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"0px solid black"}
            mdlHeight={["50px", "15%", "15%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
                    <Grid item sx={{borderRight:"1px solid #CCCCCC"}} xs={6}>
                     <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Home Style</Grid><Grid item xs={6}>Ranch</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Year Built</Grid><Grid item xs={6}>2002</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Heating</Grid><Grid item xs={6}>Gas</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Cooling</Grid><Grid item xs={6}>Yes</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Lot Size</Grid><Grid item xs={6}>.45 Acre</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Taxes</Grid><Grid item xs={6}>12,000</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Garage</Grid><Grid item xs={6}>2</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Bedrooms</Grid><Grid item xs={6}>4</Grid>
                      <Grid item sx={{fontWeight:"bold"}} xs={6}>Bathrooms</Grid><Grid item xs={6}>2.5</Grid>
                    </Grid>
                    </Grid>

                    <Grid item sx={{paddingLeft:"5px" }} xs={6}>
                      <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Basement</Grid><Grid item xs={6}>Full/Unfinished</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Fireplace</Grid><Grid item xs={6}>No</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Pool</Grid><Grid item xs={6}>No</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Living Area</Grid><Grid item xs={6}>2,600</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Flooring</Grid><Grid item xs={6}>Hardwood/Carpet</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Water/Sewer</Grid><Grid item xs={6}>Yes</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Waterfront</Grid><Grid item xs={6}>No</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Taxes</Grid><Grid item xs={6}>2,600</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>School</Grid><Grid item xs={6}>Badwinsville</Grid>
                      </Grid>
                    </Grid>
                </Grid>
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={[".75rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"1px solid black"}
            btmHeight={["400px", "85%", "85%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getWhatsNearbyTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={null}
            topTxtColor={"#333333"}
            topTxtSize={["1rem", "1rem", "1rem"]}
            topTxtType={"strong"}
            topTxtAlign={"center"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"#F5F5F5"}
            topHeight={["0px", "0px", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"What's Nearby"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["1rem", "1rem", "1rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"0px solid black"}
            mdlHeight={["50px", "15%", "15%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                    <Grid item xs={3}>Square Ftg</Grid><Grid item xs={3}>2,600</Grid>
                </Grid>
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"1px solid black"}
            btmHeight={["400px", "85%", "85%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getNarativeTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "32px", "24px"]}
            topTxtType={"strong"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(155,155,155,1)"}
            topHeight={["0px", "0px", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"About This House"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["1rem", "1rem", "1rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"0px solid black"}
            mdlHeight={["50px", "15%", "15%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              "Brand new home without the wait!! This castle on the hill is situated up high w/ beautiful views of rolling hills & stunning sunsets. Take advantage of countless model upgrades. Starting from the exterior you will see the beautiful landscaping, custom walkway, & full front porch. A modern farmhouse design with black framed windows and board and batten siding accents. Inside you will find a large mudroom with custom built-ins that connects the front entryway with the garage entryway making for a great use of space. Spacious kitchen overlooks the dining area and family room for an open-concept. The kitchen has dramatic two-tiered cabinetry with under cabinet lighting, custom range hood, and large kitchen island with plenty of seating. Gas fireplace in family room with custom trim design. A home office with French doors is right off the front of the home. This is on a special lot located on a cul-de-sac making for no through-traffic and there is also no rear neighbors for extra privacy. Convenient 2nd floor laundry. Primary suite bath as all of the bells and whistles. If this isn't enough for you, the basement offers great finishable space with roughed-in plumbing for future bath."
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", ".8rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1"}
            btmOverflow={"auto"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"1px solid black"}
            btmHeight={["400px", "85%", "85%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );

  }

  getMapTile = () => {
//<img alt="image" width="100%" height="100%" src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.props.listing ? this.props.listing.position.lat : null},${this.props.listing ? this.props.listing.position.lng : null}&zoom=18&size=600x400&markers=color:blue|${this.props.listing ? this.props.listing.position.lat : null},${this.props.listing ? this.props.listing.position.lng : null}&key=AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8&format=png`} alt="Map" />
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >

          <CommonTile
                uid={0}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={null}
                tagTopRightTxt={null}
                tagTopRightPadding={"5px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["12px", "12px", "16px"]}
                tagTopRightPaddingRight={"4px"}
                tagTopRightPaddingTop={"4px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,.5)"}
                tagTopRightBorder={"0px solid black"}
                tagTopRightBorderRadius={"5px"}
                tagTopRightCallback={null}
                tagTopLeftIcon={null}
                tagTopLeftTxt={null}
                tagTopLeftPadding={"5px 20px 5px 20px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={["12px", "12px", "16px"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"0%"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"#73be73"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={"0px"}
                topPadding={"0px"}
                topWidget={null}                
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={null}
                topTxtColor={"white"}
                topTxtSize={["12px", "12px", "32px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgbs(255,255,255,0)"}
                topHeight={["0px", "0%", "0%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["12px", "12px", "16px"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={"Location"}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"0px solid black"}
                mdlHeight={["50px", "15%", "15%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={"general/cartoon-map.png"}
                btmPictureAlign={"center"}
                btmPictureSize={"cover"}
                btmTxt={null}
                btmTxtColor={"#333333"}
                btmTxtSize={["16px", "16px", "16px"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["400px", "85%", "85%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
    </Grid>
  </React.Fragment>
  );

}


  getMortgageTile = () => {

  }

  getNearbyRestaurants = (lat, lng) => {

    /*
    https://developers.google.com/maps/documentation/places/web-service/supported_types
    Hotels and lodging
    Museums and art galleries
    Parks and outdoor attractions
    Shopping centers and malls
    Bars and nightclubs
    Hospitals and medical centers
    Schools and universities
    Airports and train stations
    Gas stations and convenience stores


    function search(query) {
      var request = {
        query: query,
        fields: ['name', 'formatted_address', 'geometry']
      };

      var service = new google.maps.places.PlacesService(document.createElement('div'));

      service.textSearch(request, function(results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          console.log(results);
        } else {
          console.error('Search failed:', status);
        }
      });
    }

    search('hotels in San Francisco');    
    */

    /*
      if(lat && lng){
      console.log("GET NEAR BY ===========================")
      console.log(lat + ", " + lng)
      let llat = lat 
      let llng = lng
      // Create a new PlacesService object
      let location = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));

      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    
      // Define the search parameters
      const request = {
        location: {
          lat: 43.1833377059405,
          lng: -76.29868574434212
        },
        radius: 8046.72, // 5 miles in meters
        type: 'restaurant'
      };
    
      // Send the search request to the PlacesService
      placesService.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Iterate through the search results and display the names and addresses of the restaurants
          results.forEach((place) => {
            console.log(place.name);
            console.log(place.vicinity);
          });
        } else {
          console.log(`PlacesService error: ${status}`);
        }
      });
    }
    */
  }

  getPictureTiles = () => {
    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                
                padding: "10px",
                height: {
                  xl: "75%",
                  lg: "75%",
                  md: "75%",
                  sm: "75%",
                },
                background: "#FFFFFF",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <CommonTile
                uid={null}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={null}
                tagTopRightTxt={null}
                tagTopRightPadding={"5px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                tagTopRightPaddingRight={"4px"}
                tagTopRightPaddingTop={"4px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,.5)"}
                tagTopRightBorder={"0px solid black"}
                tagTopRightBorderRadius={"5px"}
                tagTopRightCallback={null}
                tagTopLeftIcon={null}
                tagTopLeftTxt={null}
                tagTopLeftPadding={"5px 20px 5px 20px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={["1rem", "1rem", "1rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"0%"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"#73be73"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={"0px"}
                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={"Pictures"}
                topCallback={null}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"center"}
                topVerticalAlign={"center"}
                topHorizontalAlign={"center"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={["75px", "15%", "15%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["1rem", "1rem", "1rem"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", ".75rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#FFFFFF"}
                mdlBorder={"0px solid black"}
                mdlHeight={["75px", "75%", "75%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: "100%",
                      width: { md: "100%", xs: "100%" },
                      padding: "0px",               
                      color:this.props.theme.bannerTextColor,
                      fontSize:{
                        xs:"0rem",
                        sm:"0rem",
                        md:".8rem",
                        lg:".8rem",
                        xl:".8rem",
                      }
                    }}
                  >
                      <Button
                      size="small"
                      onClick={this.props.toggleCompareFavorities}
                      variant={this.props.theme.buttonVariant}
                      sx={{
                        '&:hover': {
                          border:"0px solid " + this.props.theme.buttonTextColor,
                          background:this.props.theme.buttonHoverColor
                        },            
                        marginRight: "0px",
                        borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                        textTransform: "none",
                        textAlign: "center",
                        color:this.props.theme.buttonTextColor,
                        borderRight:"1px solid " + this.props.theme.bannerColor,
                        fontSize:{
                          xs:".5rem",
                          sm:".5rem",
                          md:".7rem",
                          lg:".75rem",
                          xl:".75rem",
                        },
                        width:"50%",
                        height:"100%"
                      }}
                    >
                      Previous
                    </Button>

                    <Button
                      size="small"
                      onClick={this.props.toggleCompareFavorities}
                      variant={this.props.theme.buttonVariant}
                      sx={{
                        '&:hover': {
                          border:"0px solid " + this.props.theme.buttonTextColor,
                          background:this.props.theme.buttonHoverColor
                        },            
                        marginLeft: "0px",
                        borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                        textTransform: "none",
                        textAlign: "center",
                        color:this.props.theme.buttonTextColor,
                        borderLeft:"1px solid " + this.props.theme.buttonTextColor,
                        fontSize:{
                          xs:".5rem",
                          sm:".5rem",
                          md:".7rem",
                          lg:".75rem",
                          xl:".75rem",
                        },
                        width:"50%",
                        height:"100%"
                      }}
                    >
                      Next
                    </Button>
                  </Grid>

                }
                btmTxtColor={this.props.theme.bannerTextColor}
                btmTxtSize={["1rem", "1rem", ".75rem"]}
                btmTxtType={"normal"}
                btmTxtAlign={"right"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={this.props.theme.bannerColor}
                btmBorder={"0px solid black"}
                btmHeight={["50px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };

  
  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"profilepics/contact-profile.png"}
            topPictureAlign={"100% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["200px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={

              <React.Fragment>              
              <Button
              onClick={() => this.showRequestShowingOfFavorities}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"95%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "15px",
                  xl: "15px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Request A Showing</Button>
              </React.Fragment>           
            }
            mdlCallback={null}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getContactFinanceTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"downloads/comp-report.png"}
            topPictureAlign={"50% 70%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["200px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={<React.Fragment>              
              <Button
              onClick={() => this.showRequestShowingOfFavorities}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },    
                width:"95%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "15px",
                  xl: "15px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Download My Listing Report</Button>
              </React.Fragment>           
            }
            mdlCallback={null}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getHomeValueTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"10px"}
            topPicture={null}
            topWidget={
              <div>
              <Speedometer
                  needleHeightRatio={0.7}
                  value={777}
                  customSegmentStops={[0, 250, 750, 1000]}
                  segmentColors={['#b0deb6', '#61bd6d', '#b0deb6']}
                  currentValueText=" "
                  customSegmentLabels={[
                    {
                      text: 'Below',
                      position: 'OUTSIDE',
                      color: '#333333',
                    },
                    {
                      text: 'Fair',
                      position: 'OUTSIDE',
                      color: '#333333',
                    },
                    {
                      text: 'Above',
                      position: 'OUTSIDE',
                      color: '#333333',
                    },
                  ]}
                  ringWidth={45}
                  needleTransitionDuration={3333}
                  needleTransition="easeElastic"
                  needleColor={'#333333'}
                  textColor={'#333333'}
              />
            </div>
            }
            topPictureAlign={"100% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["200px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={
              <div> This lisitng price is <b>*Fair</b> which means the seller has priced the home at approximate market value.  There will likely be a wide range of offes on this home.</div>

            }
            mdlCallback={null}
            mdlTxtColor={"#333333"}
            mdlTxtSize={["16px", ".85rem", ".85rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"rgba(255, 255, 255, 1)"}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

  handleFinancingChange = (event, newValue) => {
    this.setState({ selectedFinancing: newValue });
  };

  handlePercentageChange = (event, newValue) => {
    this.setState({ selectedPercentage: newValue });
  };

  handleTermChange = (event, newValue) => {
    this.setState({ selectedTerm: newValue });
  };

  handleChange = (event, newValue) => {
    this.setState({ selectedTerm: newValue });
  };

  formatDollarValueLabel = (value) => {
    // Format the value as desired
    return "$"+value.toLocaleString();
  };

  formatPercentValueLabel = (value) => {
    // Format the value as desired
    return (Number(value.toFixed(2)) + "%");
  };

  formatTermValueLabel = (value) => {
    // Format the value as desired
    return (Number(value.toFixed(2)) + " Yrs");
  };

  getFinancingTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"center"}
            tagHorizontalAlign={"center"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"20px 50px 5px 50px"}
            topPicture={null}
            topWidget={
              <React.Fragment>
               <Slider
                marks={true}
                min={50000}
                max={1000000}
                step={25000}
                valueLabelDisplay="on"
                getAriaLabel={() => 'Prices'}
                value={this.state.selectedFinancing}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: 12, // Set the font size here
                    background:this.props.theme.textColor
                  },
                  color:this.props.theme.textColor
                }}
                valueLabelFormat={this.formatDollarValueLabel}
                onChange={this.handleFinancingChange}
              />   
              <Slider
              marks={true}
              min={1}
              max={10}
              step={.25}
              valueLabelDisplay="on"
              getAriaLabel={() => 'Percentage'}
              value={this.state.selectedPercentage}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: 12, // Set the font size here
                  background:this.props.theme.textColor
                },
                color:this.props.theme.textColor
              }}
              valueLabelFormat={this.formatPercentValueLabel}
              onChange={this.handlePercentageChange}
            />   
            <Slider
              marks={true}
              min={10}
              max={30}
              step={5}
              valueLabelDisplay="on"
              getAriaLabel={() => 'Term'}
              value={this.state.selectedTerm}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: 12, // Set the font size here
                  background:this.props.theme.textColor
                },
                color:this.props.theme.textColor
              }}
              valueLabelFormat={this.formatTermValueLabel}
              onChange={this.handleTermChange}
            />   
            {/*
           <ToggleButtonGroup
                   value={this.state.selectedTerm}
                   exclusive
                   onChange={this.handleChange}
                   aria-label="toggle button"
                   sx={{ padding: '5px' }} 
                 >
                   <ToggleButton sx={{"padding": "10px", "fontSize": "12px"}} value="10" aria-label="10 year">
                     10 yr
                   </ToggleButton>
                   <ToggleButton sx={{"padding": "10px", "fontSize": "12px"}} value="15" aria-label="15 year">
                     15 yr
                   </ToggleButton>
                   <ToggleButton sx={{"padding": "10px", "fontSize": "12px"}} value="20" aria-label="20 year">
                     20 yr
                   </ToggleButton>
                   <ToggleButton sx={{"padding": "10px", "fontSize": "12px"}} value="30" aria-label="30 year">
                     30 yr
                   </ToggleButton>
            </ToggleButtonGroup>  
            */}
            </React.Fragment>
            }
            topPictureAlign={null}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "1rem", "1rem"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["300px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlWidget={null}
            mdlCallback={null}
            mdlTxtColor={"#333333"}
            mdlTxtSize={["16px", ".75rem", ".75rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255, 255, 255, 0)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"10px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={ <div>If you borrow <b>${this.state.selectedFinancing}</b> at <b>{this.state.selectedPercentage}%</b> over <b>{this.state.selectedTerm} years</b> your monthly mortgate payment will be approximtaly <b>**$2,250</b>.</div>
          }
            btmCallback={null}
            btmTxtColor={"#333333"}
            btmTxtSize={["16px", ".85rem", ".85rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255, 255, 255, 0)"}
            btmBorder={"1px solid black"}
            btmHeight={["100px", "30%", "30%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getIdxTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "0px",
            height: {
              xl: "10%",
              lg: "15%",
              md: "15%",
              sm: "50%",
            },
            border:"0px solid black"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"0px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "0px", "0px"]}
            topTxtType={"normal"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["0px", "0%", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["0px", "0px", "0px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"#FFFFFF"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={1}
                        >
                            <img alt="image" src="howardhanna/idx-lg.jpg" height={25} width={40}/>&nbsp;&nbsp;
                        </Grid>
                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:".55rem",
                              sm:".55rem",
                              md:".55rem",
                              lg:".55rem",
                              xl:".55rem"
                            },
                            height:"100%",
                            width:"100%",   
                            fontWeight:"normal",
                          }}
                          xs={10}
                        >                          
                          The data relating to real estate on this web site comes in part from the Internet Data Exchange (IDX) 
                          Program of NYSAMLS’s. Real estate listings held by firms other than Howard Hanna Real Estate Services, are marked 
                          with the Listing Broker’s Firm Name. Data provided is for consumers’ personal, non-commercial use and 
                          may not be used for any purpose other than to identify prospective properties that consumers may be 
                          interested in purchasing. Listing Data last updated at {this.props.listing ? this.props.listing.ModificationTimestamp : null}. 
                          <br/>
                          Disclaimer: All information deemed reliable but not guaranteed and should be independently verified. All 
                          properties are subject to prior sale, change or withdrawal. Neither the listing broker(s) nor Howard Hanna Real 
                          Estate Services shall be responsible for any typographical errors, misinformation, misprints, and shall be held 
                          totally harmless. © 2024 CNYIS, UNYREIS, WNYREIS. All rights reserved.
                        </Grid>
                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={1}
                        >
                        </Grid>
                        </Grid>
            }
            btmTxtColor={"black"}
            btmTxtSize={["1rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"justify"}
            btmHorizontalAlign={"flex-end"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["425px", "100%", "100%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"0px solid black"}
            shadow={0}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"10px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getMortgageCalculatorTile = () => {

  }

  getFooter = () => {
    return (
      <Grid
      item
      sx={{
        width: "100%",
        height: {
          xl: "10%",
          lg: "10%",
          md: "10%",
          sm: "10%",
        },
        padding: "5px",
        fontSize: "2rem",
      }}
      xs={12}
    >
      <Footer theme={this.props.theme} />
    </Grid>
    )
  } 


  render() {

    const locations = [
      "1234 Main Street Lane ($545,000)", 
      "Baldwinsville",
      "Brewerton",
      "Bridgeport",
      "Camillus",
      "Cicero",
      "Clay",
      "East Syracuse",
      "Warners",];

      const { selectedLocation } = this.state;

  // Call the searchNearbyRestaurants function with your current location
  this.getNearbyRestaurants(this.props.listing ? this.props.listing.position.lat : null, this.props.listing ? this.props.listing.position.lng : null);


    return (
      <Drawer
          anchor="bottom"
          open={this.props.showCompareFavorities}
          onClose={this.props.toggleCompareFavorities}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "75%",
                sm: "75%",
                md: "80%",
                lg: "80%",
                xl: "80%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",
              marginLeft: "auto",
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#FFFFFF",
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "0px solid #333333",
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "0px",               
                color:this.props.theme.bannerTextColor,
                fontSize:{
                  xs:"0rem",
                  sm:"0rem",
                  md:".8rem",
                  lg:".8rem",
                  xl:".8rem",
                }
              }}
            >
              <FormControl sx={{
              width:{
                xs:"95%", 
                sm:"95%", 
                md:"95%", 
                lg:"95%", 
                xl:"95%" 
              },
              color:this.props.theme.bannerTextColor,
              padding:"2px 15px 2px 2px"}}>

                <InputLabel
                  sx={{
                    '&.Mui-focused': {
                      color: this.props.theme.bannerTextColor,
                    },
                    color:this.props.theme.bannerTextColor
                  }}            
                  id="location-select-label"></InputLabel>
                <Select
                  size="small"
                  labelId="location-select-label"
                  id="location-select"
                  sx={{
                    '& .MuiSelect-select.MuiSelect-select':{
                      backgroundColor: "white"
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border:"1px solid " + this.props.theme.bannerTextColor
                    },
                    background:"white",
                    fontSize:{
                      xs:"1rem", 
                      sm:"1rem", 
                      md:"1rem", 
                      lg:"1rem", 
                      xl:"1rem"
                    },
                    padding:{
                      xs:"2px", 
                      sm:"2px", 
                      md:"0px", 
                      lg:"5px", 
                      xl:"5px"
                    }
                }}
                  value={selectedLocation}
                  onChange={this.handleLocationsChange}
                >
                  {this.props.favorites && this.props.favorites.map((location) => (
                    <MenuItem key={location.ListingId} value={location.ListingId}>
                      <ListItemText primary={location.UnparsedAddress +" ($" + location.ListPrice.toLocaleString() + ")"} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <FormControl sx={{
              width:{
                xs:"95%", 
                sm:"95%", 
                md:"95%", 
                lg:"95%", 
                xl:"95%" 
              },
              color:this.props.theme.bannerTextColor,
              padding:"2px 15px 2px 2px"}}>

                <InputLabel
                  sx={{
                    '&.Mui-focused': {
                      color: this.props.theme.bannerTextColor,
                    },
                    color:this.props.theme.bannerTextColor
                  }}            
                  id="location-select-label"></InputLabel>
                <Select
                  size="small"
                  labelId="location-select-label"
                  id="location-select"
                  sx={{
                    '& .MuiSelect-select.MuiSelect-select':{
                      backgroundColor: "white"
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border:"1px solid " + this.props.theme.bannerTextColor
                    },
                    background:"white",
                    fontSize:{
                      xs:"1rem", 
                      sm:"1rem", 
                      md:"1rem", 
                      lg:"1rem", 
                      xl:"1rem"
                    },
                    padding:{
                      xs:"2px", 
                      sm:"2px", 
                      md:"0px", 
                      lg:"5px", 
                      xl:"5px"
                    }
                }}
                  value={selectedLocation}
                  onChange={this.handleLocationsChange}
                >
                  {this.props.favorites && this.props.favorites.map((location) => (
                    <MenuItem key={location.ListingId} value={location.ListingId}>
                      <ListItemText primary={location.UnparsedAddress +" ($" + location.ListPrice.toLocaleString() + ")"} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>      
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <FormControl sx={{
              width:{
                xs:"95%", 
                sm:"95%", 
                md:"95%", 
                lg:"95%", 
                xl:"95%" 
              },
              color:this.props.theme.bannerTextColor,
              padding:"2px 15px 2px 2px"}}>

                <InputLabel
                  sx={{
                    '&.Mui-focused': {
                      color: this.props.theme.bannerTextColor,
                    },
                    color:this.props.theme.bannerTextColor
                  }}            
                  id="location-select-label"></InputLabel>
                <Select
                  size="small"
                  labelId="location-select-label"
                  id="location-select"
                  sx={{
                    '& .MuiSelect-select.MuiSelect-select':{
                      backgroundColor: "white"
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border:"1px solid " + this.props.theme.bannerTextColor
                    },
                    background:"white",
                    fontSize:{
                      xs:"1rem", 
                      sm:"1rem", 
                      md:"1rem", 
                      lg:"1rem", 
                      xl:"1rem"
                    },
                    padding:{
                      xs:"2px", 
                      sm:"2px", 
                      md:"0px", 
                      lg:"5px", 
                      xl:"5px"
                    }
                }}
                  value={selectedLocation}
                  onChange={this.handleLocationsChange}
                >
                  {this.props.favorites && this.props.favorites.map((location) => (
                    <MenuItem key={location.ListingId} value={location.ListingId}>
                      <ListItemText primary={location.UnparsedAddress +" ($" + location.ListPrice.toLocaleString() + ")"} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>        
  
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "#FFFFFF",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  background: "#FFFFFF",
                  height: "100%",
                  width:"100%",
                  
                  border: "0px solid #333333",
                }}
              >
                {this.getPrimaryTile()}
                {this.getPrimaryTile()}
                {this.getPrimaryTile()}

                {this.getNarativeTile()}
                {this.getNarativeTile()}
                {this.getNarativeTile()}

                {this.getDetailsTiles()}                
                {this.getDetailsTiles()}                
                {this.getDetailsTiles()}                

                {this.getMapTile()}                
                {this.getMapTile()}                
                {this.getMapTile()}                

                {this.getWhatsNearbyTile()}                
                {this.getWhatsNearbyTile()}                
                {this.getWhatsNearbyTile()}                

                {this.getPictureTiles()}
                {this.getPictureTiles()}
                {this.getPictureTiles()}

                {this.getHomeValueTile()}
                {this.getHomeValueTile()}
                {this.getHomeValueTile()}

                {this.getFinancingTile()}
                {this.getFinancingTile()}
                {this.getFinancingTile()}

                {this.getContactFinanceTile()}
                {this.getContactFinanceTile()}
                {this.getContactFinanceTile()}

                {this.getIdxTile()}
                {this.getFooter()}

              </Grid>
              
        </Grid>
      </Grid>
      <SlideShowDialog 
        open={this.state.showSlideShowDialog}
        onClose={this.toggleSlideShowDialog}
        photos={[]}
        listing={this.props.listing ? this.props.listing: null}
        showRequestShowing={this.props.showRequestShowing}
        mapImage={this.mapImage}
        theme={this.props.theme}
        doNext={true}
        doPrevious={true}
        doCounter={true}
      />
      </Drawer>
    );
  }
}

export default CompareFavorities;
