import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import CommonTile from "./CommonTile";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-before-after-slider-component/dist/build.css';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import HTMLFlipBook from "react-pageflip";
import Image from 'mui-image'
import "./index.css";


class MagazineComponent extends Component {

  constructor(props) {
    super(props);
    
    this.markers = [];
  
    this.state = {
      page: 0,
      totalPage: 0,

      slideIndex: 0,
      requestMagazine: false,
      inProgress: false,
      name: '',
      phoneNumber: '',
      email: '',
      homeAddress: '',
      errors: {
        name: '',
        phoneNumber: '',
        email: '',
        homeAddress:''
      },
      requestSent:false,
    }
  }

  
  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    const errors = this.state.errors;

    errors[name] = ''

    this.setState({ [name]: newValue, errors: errors, requestSent:false});

  };  

  sendContactCard = (event) => {

    event.preventDefault();

    const { name, phoneNumber, email, homeAddress } = this.state;
    const errors = {};

    if (!name) {
      errors.name = 'Name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name must be letters only';
    }

   // Phone number validation
    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else {
      let strippedPhoneNumber = phoneNumber.replace(/-/g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\./g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\s/g, ''); // Remove dashes from the input

      if (!/^\d{10}$/.test(strippedPhoneNumber)) {
        errors.phoneNumber = 'Phone number must be 10 digits 123-456-7890';
      } else {
        // Format the phone number with dashes for display
        const formattedPhoneNumber = strippedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        this.setState({ phoneNumber: formattedPhoneNumber });
      }
    }

    // Email validation
    if (!email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address';
    }

    // Home Address
    if (!homeAddress) {
      errors.homeAddress = 'Home address is required';
    } else if (!/^[0-9a-zA-Z\s]+$/.test(homeAddress)) {
      errors.homeAddress = 'Invalid home address';
    }
    
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors, requestSent:false});
    } else {
      // Form is valid, perform further actions or submit
      this.setState({ errors: errors, inProgress:true, requestSent:true }, () => {
        this.props.requestMagazine(
          this.props.parentPage,
          this.state.name,
          this.state.phoneNumber,
          this.state.email,
          this.state.homeAddress,
          this.callback
          )});
    }
}


  callback = (data) => {
    this.setState({inProgress:false, requestSent:true})
  }


  togglerequestMagazine = () => {
      this.setState({
        requestMagazine: !this.state.requestMagazine
      })    
  }


  getSendEmailTile = () => {
    return (
      <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "95%",
                width: { md: "100%"},
                overflowY:"auto"       
              }}
            >
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            overflowX:"hidden",
            background: "rgba(255,255,255,.75)",
            border:"0px solid #333333"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height:"100%",
                  width: { md: "100%", xs: "100%" },
                  padding: "0px",
                }}
              >

                <Grid
                  item
                  sx={{
                    padding: "20px 5px 5px 5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"15%",
                    },

                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                      width: "100%" ,
                      backgroundColor: this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                    }}                    
                    InputProps={{
                      style: {
                        background:this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    label="Your Name"
                    variant="filled"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.name)}
                    helperText={this.state.errors.name}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "20px 5px 5px 5px",
                    fontWeight: "normal",
                    height:{
                      xs:"12%",
                      sm:"12%",
                      md:"12%",
                      lg:"12%",
                    }
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                      width: "100%",
                       backgroundColor: this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                    InputProps={{
                      style: {
                        background:this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    id="outlined-basic"
                    label="Phone Number"
                    variant="filled"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.phoneNumber)}
                    helperText={this.state.errors.phoneNumber}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"12%",
                      sm:"12%",
                      md:"12%",
                      lg:"12%",
                    },

                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       backgroundColor: this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                         }}
                    InputProps={{
                      style: {
                        background:this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                       },
                    }}                      
                    id="outlined-basic"
                    label="Email Address"
                    variant="filled"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.email)}
                    helperText={this.state.errors.email}
                  />
                </Grid>
                
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"12%",
                      sm:"12%",
                      md:"12%",
                      lg:"12%",
                    },

                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       backgroundColor: this.state.errors.homeAddress ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                         }}
                    InputProps={{
                      style: {
                        background:this.state.errors.homeAddress ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                       },
                    }}                      
                    id="outlined-basic"
                    label="Home Address (e.g. 123 Main St, Baldwinsville)"
                    variant="filled"
                    name="homeAddress"
                    value={this.state.homeAddress}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.homeAddress)}
                    helperText={this.state.errors.homeAddress}
                  />
                </Grid>

                  {/*
                <Grid
                  item
                  sx={{
                    paddingLeft: "0px",
                    paddingTop: "5px",
                    fontWeight: "normal",
                    borderTop: "1px solid #AAAAAA",
                    textAlign:"left",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                  xs={12}
                >
                  <div>
                    Hi Donna,<br />
                    <blockquote>
                      My name is <b>Roger Nestler</b>, I'm intested in a showing for<br/>
                      <br/>
                      <b>1234 Main Street Lane, Baldwinsville NY 13027</b><br/>
                      <br/>
                      Please contact me at:<br/>
                      <b>315-247-3314</b> or <b>roger.nestler@gmail.com.</b>
                      <br />
                      <br />I Have the below days and times availabe.
                      <br/>
                      <b>Monday</b> at <b>11:00AM</b>, <b>6:00PM</b><br/>
                      <b>Saturday</b> at <b>1:00PM</b>, <b>2:00PM</b>, <b>3:00PM</b>

                    </blockquote>
                    Thanks
                    <br />
                    <br />
                  </div>
                </Grid>
                */}
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                  xs={12}
                >
                    {this.state.inProgress ? 
                      <Button
                      sx={{
                        textTransform: "none",
                        padding: "7px",
                        background:this.props.theme.bannerColor,
                        color:this.props.theme.bannerTextColor,
                        border:"1px solid " + this.props.theme.buttonColor,
                        width: "100%",
                        height: "100%",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        borderBottomLeftRadius: "20px",
                          }}
                      size="small"
                      variant="outlined"
                    >
                        <CircularProgress disableShrink size={20} color="primary"/>
                    </Button>
                    :
                  <Button
                    onClick={this.sendContactCard}
                    variant={this.props.theme.buttonVariant}
                  sx={{
                    '&:hover': {
                      border:"1px solid " + this.props.theme.buttonTextColor,
                      background:this.props.theme.bannerColor
                    },
        
                    marginRight: "10px",
                    textTransform: "none",
                    textAlign: "center",
                    fontSize:{
                      xs:".75rem",
                      sm:".75rem",
                      md:".75rem",
                      lg:"1rem",
                      xl:"1rem"
                    },
                    background:this.props.theme.bannerColor,
                    color:this.props.theme.bannerTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    textTransform: "none",
                    width: "100%",
                    height: "40px",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    borderBottomLeftRadius: "20px",
                  }}
                  size="small"
                  >
                    Click To Send
                  </Button>
                  }
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop:"10px"
                  }}
                  xs={12}
                >
                    {this.state.requestSent
                      ? <Alert sx={"padding:2px 5px 2px 5px"} severity="success">Thank you, I'll be in touch soon to confirm your copy is sent to your address<br/> &nbsp;Please feel free to reach out to me directly to ask about your copy, at <b>315.723.1077</b> or <b>DonnaWills@HowardHanna.com</b></Alert>
                      : <Alert sx={"padding:2px 5px 2px 5px"} severity="info">I'll contact you using your email address or phone number to confirm your address and to set up a mailing date.<br/> &nbsp;Please feel free to reach out to me directly to request your copy, at <b>315.723.1077</b> or <b>DonnaWills@HowardHanna.com</b>.</Alert>}

                </Grid>
              </Grid>
          </Grid>
      </Grid>
    );
  };



  nextSlide = (id) => {
    this.pageFlip.pageFlip().flipNext();
  }

  previousSlide = (id) => {

    this.pageFlip.pageFlip().flipPrev();
    return;
  }

  renderMagazine = () => {
    return (
      
      <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: 
        {
          md:"90%",
          lg:"100%"
        },
        width: { 
          md: "90%",
          lg: "100%"
        },
        overflowX:"hidden",       
        overflowY:"hidden",
      }}
    >
      <HTMLFlipBook
      width={300}
      height={400}
      maxShadowOpacity={.5}
      showCover={false}
      mobileScrollSupport={true}
      
      ref={(component) => (this.pageFlip = component)}
    >

      <div></div>
      <div><img alt="image" style={{border: "0px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-01.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "0px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-02.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "0px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-03.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "0px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-04.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-05.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-06.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-07.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-08.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-09.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-10.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-11.png"} width="300px" height="400px" /></div>
      <div><img alt="image" style={{border: "1px solid #cccccc", boxShadow: "5px 5px 5px 5px #888888"}} src={"magazine/spring-summer-page-12.png"} width="300px" height="400px" /></div>
      <div></div>

    </HTMLFlipBook>
      </Grid>
      
    );
  };

  /////////////////////////////////////////////////////////////

  
  getImageToDisplay = () => {
    if(this.props.magazine){
      return(this.renderMagazine());
    }else{
      return null
    }
  };


  

  render() {
    const {children } = this.props;

    const FIRST_IMAGE = {
      imageUrl: 'background.png'
    };
    const SECOND_IMAGE = {
      imageUrl: 'background2.png'
    };


    return (
      
        <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background:"rgba(55,55,55,0)",
                height: {
                  xs:"40dvh",
                  md:"75dvh",
                  lg:"70dvh"
                },
                padding: "0px",  
                overflowY:"hidden"             
              }}
            >

          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              color:this.props.theme.bannerTextColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "0px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              overflowY:"hidden",             
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "20%",
                sm: "20%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={6}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "10px",  
                fontSize:{
                  xs:".8rem",
                  sm:".8rem",
                  md:"1.25rem",
                  lg:"1.25rem",
                  xl:"1.25rem",
                }             
              }}
            >
              {this.props.magazine ? this.props.magazine.title : null}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              color:this.props.theme.bannerTextColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "0px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "20%",
                sm: "20%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={6}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                overflowY:"hidden",             
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingRight: "5px",
              }}
            >
             
                  <Button
                  onClick={() => this.togglerequestMagazine()}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                      '&:hover': {
                        border:"1px solid " + this.props.theme.buttonTextColor,
                        background:this.props.theme.buttonHoverColor
                      },      

                    marginRight: "5px",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "20px",
                    textTransform: "none",
                    textAlign: "center",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize: {
                      xs: ".7rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem"
                    }                  
                  }}
                >
                  { this.state.requestMagazine &&  this.state.requestSent ? 'Close' : 
                       this.state.requestMagazine ? 'Cancel Request' : 'Request Your Copy'}
                </Button>
               
            </Grid>
          </Grid>                    
            <Grid
              item
              sx={{
                background:"rgba(255,255,255,0)",
                width: { md: "100%", xs: "100%" },
                overflowY : "auto",            
                
                padding: this.state.requestMagazine ? "0px" : "0px",
                  height: {
                    xs: "80%",
                    sm: "80%",
                    md: "88%",
                    lg: "88%",
                    xl: "88%",    
                },
                border:"0px solid black"
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {
              this.state.requestMagazine ?
                <Fade in={true} timeout={1000}>
                {this.getSendEmailTile()}
                </Fade>
              :
              this.props.promoimage ?
              <div style={{paddingTop:"20px"}}>
              <center>
                <Image src={this.props.promoimage} fit="contain" duration={325} />
                </center>
                </div>
              :
              <CommonTile
                uid={0}
                animationType={null}
                animationTime={null}
                tagBottomRightIcon={<ArrowForwardIcon sx={{fontWeight:"bold"}}/>}
                tagBottomRightTxt={null}
                tagBottomRightPadding={"0px 20px 0px 20px"}
                tagBottomRightTxtColor={this.props.theme.bannerTextColor}
                tagBottomRightTxtSize={["2rem", "2rem", "2rem"]}
                tagBottomRightPaddingRight={"0%"}
                tagBottomRightPaddingBottom={"5%"}
                tagBottomRightTxtType={"bold"}
                tagBottomRightTxtAlign={"center"}
                tagBottomRightBgColor={this.props.theme.bannerColor}
                tagBottomRightBorder={"1px solid #AAAAAA"}
                tagBottomRightBorderRadius={["50px","50px","50px","50px"]}
                tagBottomRightCallback={this.nextSlide}

                tagBottomLeftIcon={<ArrowBackIcon />}
                tagBottomLeftTxt={null}
                tagBottomLeftPadding={"0px 20px 0px 20px"}
                tagBottomLeftTxtColor={this.props.theme.bannerTextColor}
                tagBottomLeftTxtSize={["2rem", "2rem", "2rem"]}
                tagBottomLeftPaddingLeft={"0%"}
                tagBottomLeftPaddingBottom={"5%"}
                tagBottomLeftTxtType={"bold"}
                tagBottomLeftTxtAlign={"center"}
                tagBottomLeftBgColor={this.props.theme.bannerColor}
                tagBottomLeftBorder={"1px solid #AAAAAA"}
                tagBottomLeftBorderRadius={["50px","50px","50px","50px"]}
                tagBottomLeftCallback={this.previousSlide}

                tagTopRightIcon={null}
                tagTopRightTxt={null}
                tagTopRightPadding={"2px 5px 2px 5px"}
                tagTopRightTxtColor={this.props.theme.bannerTextColor}
                tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                tagTopRightPaddingRight={"2%"}
                tagTopRightPaddingTop={"2%"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={this.props.theme.bannerColor}
                tagTopRightBorder={"0px solid #333333"}
                tagTopRightBorderRadius={["10px","10px","10px","10px"]}
                tagTopRightCallback={this.previousSlide}


                topPadding={"0px 0px 0px 0px"}
                topWidget={null}
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={this.getImageToDisplay()}
                topCallback={null}
                topTxtColor={"white"}
                topTxtSize={["12px", "12px", "32px"]}
                topTxtType={"bold"}
                topTxtAlign={"center"}
                topVerticalAlign={"flex-start"}
                topHorizontalAlign={"center"}
                topBgColor={"rgbs(255,255,255,0)"}
                topHeight={["400px", "100%", "100%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["12px", "12px", "16px"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"5px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["16px", "16px", "16px"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"rgba(255,255,255,0)"}
                mdlBorder={"0px solid black"}
                mdlHeight={["0px", "0%", "0%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={null}
                btmTxtColor={"#333333"}
                btmTxtSize={["16px", "16px", "16px"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["0px", "0%", "0%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"0px solid black"}
                shadow={0}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,0)"}
              />
              }
            </Grid>    
        </Grid>
    );
  }
}

export default MagazineComponent;
