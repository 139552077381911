import React, { Component } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NeighborhoodIcon from '@mui/icons-material/Map';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import BedroomIcon from '@mui/icons-material/Bed';
import BathroomIcon from '@mui/icons-material/Bathtub';


class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl1: null,
      anchorEl2: null,
      anchorEl3: null,
      anchorEl4: null
    };
  }

  handleDropdown1Open = (event) => {
    this.setState({ anchorEl1: event.currentTarget });
  };

  handleDropdown1Close = () => {
    this.setState({ anchorEl1: null });
  };

  handleDropdown2Open = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleDropdown2Close = () => {
    this.setState({ anchorEl2: null });
  };

  handleDropdown3Open = (event) => {
    this.setState({ anchorEl3: event.currentTarget });
  };

  handleDropdown3Close = () => {
    this.setState({ anchorEl3: null });
  };

  handleDropdown4Open = (event) => {
    this.setState({ anchorEl4: event.currentTarget });
  };

  handleDropdown4Close = () => {
    this.setState({ anchorEl4: null });
  };

  render() {
    const { anchorEl1, anchorEl2, anchorEl3, anchorEl4 } = this.state;
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <HomeIcon />
          </IconButton>
          <Typography variant="body1" style={{ marginRight: 20 }}>
            555-555-5555
          </Typography>
          <Typography variant="body1" style={{ marginRight: 20 }}>
            
          </Typography>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            
          </Typography>
          <div>
            <IconButton
              aria-controls={anchorEl1 ? 'dropdown1' : undefined}
              aria-haspopup="true"
              onClick={this.handleDropdown1Open}
              color="inherit"
            >
                <NeighborhoodIcon />
            </IconButton>
            <Menu
              id="dropdown1"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={this.handleDropdown1Close}
            >
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 1
              </MenuItem>
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 2
              </MenuItem>
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 3
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Button
              aria-controls={anchorEl1 ? 'dropdown1' : undefined}
              aria-haspopup="true"
              onClick={this.handleDropdown1Open}
              color="inherit"
            >
              <SquareFootIcon />
            </Button>
            <Menu
              id="dropdown1"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={this.handleDropdown1Close}
            >
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 1
              </MenuItem>
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 2
              </MenuItem>
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 3
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Button
              aria-controls={anchorEl1 ? 'dropdown1' : undefined}
              aria-haspopup="true"
              onClick={this.handleDropdown1Open}
              color="inherit"
            >
              <BedroomIcon />
            </Button>
            <Menu
              id="dropdown1"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={this.handleDropdown1Close}
            >
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 1
              </MenuItem>
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 2
              </MenuItem>
              <MenuItem onClick={this.handleDropdown1Close}>
                Option 3
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Button
              aria-controls={anchorEl2 ? 'dropdown2' : undefined}
              aria-haspopup="true"
              onClick={this.handleDropdown2Open}
              color="inherit"
            >
              <BathroomIcon />
            </Button>
            <Menu
              id="dropdown2"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={this.handleDropdown2Close}
            >
              <MenuItem onClick={this.handleDropdown2Close}>
                Option A
              </MenuItem>
              <MenuItem onClick={this.handleDropdown2Close}>
                Option B
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        </AppBar>
    )} 
}
export default SearchBar;