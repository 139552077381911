import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";

import AlertDialog from "./AlertDialog";
import _ from "lodash";


class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hhalertDialogOpen: false,
      hudalertDialogOpen: false
    }
  }

  closeAlertDialog = () => {

    this.setState({
      hhalertDialogOpen: false,
      hudalertDialogOpen: false
    })

  }

  gotoHowardHanna = () => {
    this.setState({hhalertDialogOpen:true})
  }

  gotoHowardHannaWebsite = () => {
    window.location.href = 'http://howardhanna.com';
  }


  gotoHud = () => {
    this.setState({hudalertDialogOpen:true})
  }

  gotoHudWebsite = () => {
    window.location.href = 'https://www.justice.gov/crt/fair-housing-act-1';
  }


  render() {
     return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          float:"right",
          height: "100%",
          width: {
            xs:"100%",
            sm:"100%",
            md:"100%",
            lg:"100%",
            xl:"100%"
          },

          paddingRight:"10px"
        }}
      >

          <Grid
              item
              sx={{
                float:"right",
                padding:"0px",
                width: "100%",
                border:"0px solid black"
              }}
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
            >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                border:"0px solid black",
                float:"right",
                
                width: {
                  xs:"100%",
                  sm:"100%",
                  md:"100%",
                  lg:"100%",
                  xl:"100%"
                },

                paddingRight:"0px",
                paddingLeft:"0px",
                paddingBottom:"5px"
              }}
            >
                <Box
                  component="img"
                  src="howardhanna/fair-housing.png"
                  alt="Fair Housing"
                  sx={{
                    border:'0px solid black',
                    height: {
                      xs:"25px",
                      sm:"15px",
                      md:"25px",
                      lg:"20px",
                      xl:"30px",
                    },
                    objectFit: 'contain',
                    paddingRight:{
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                      lg:"10px",
                      xl:"10px"
                    },
                    paddingLeft:{
                      xs:"0px",
                      sm:"0px",
                      md:"10px",
                      lg:"10px",
                      xl:"10px"
                    },
                    cursor: "pointer",
                    float:"left"

                  }}
                  onClick = {this.gotoHud}
                />

                 

                </Grid>                  
           </Grid>                  


            <Grid
            item
            sx={{
              paddingTop:"0px",
              width: "100%",
              fontSize:{
                xs:".5rem",
                sm:".5rem",
                md:".75rem",
                lg:".75rem",
              },
              float:"right"
            }}
            xs={9}
            sm={9}
            md={10}
            lg={10}
            xl={10}
        >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                paddingBottom:"10px",
                border:"0px solid black",
                float:"right",
                height: "100%",
                color:this.props.theme.bannerColor,
                fontSize:{
                  xs:".4rem",
                  sm:".4rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",
                },
                width: {
                  xs:"100%",
                  sm:"100%",
                  md:"100%",
                  lg:"100%",
                  xl:"100%"
                },
              }}
            >

&#169; 2024 Donna Wills Lic Real Estate Salesperson (315) 723-1077 DonnaWills@HowardHanna.com | Howard Hanna Real Estate Services (315) 487-0040
             </Grid>
           </Grid>
              <Grid
              item
              sx={{
                float:"right",
                padding:"0px",
                width: "100%",
                border:"0px solid black"
              }}
              xs={2}
              sm={2}
              md={1}
              lg={1}
              xl={1}
            >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                border:"0px solid black",
                float:"right",
                
                width: {
                  xs:"100%",
                  sm:"100%",
                  md:"100%",
                  lg:"100%",
                  xl:"100%"
                },

                paddingRight:"0px",
                paddingLeft:"0px"
              }}
            >
                <Box
                  component="img"
                  src="howardhanna/hh-services.png"
                  alt="Howard Hanna"
                  sx={{
                    height: {
                      xs:"30px",
                      sm:"30px",
                      md:"40px",
                      lg:"45px",
                      xl:"50px",
                    },
                    objectFit: 'contain',
                    paddingRight:{
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                      lg:"10px",
                      xl:"10px"
                    },
                    paddingLeft:{
                      xs:"0px",
                      sm:"0px",
                      md:"10px",
                      lg:"10px",
                      xl:"10px"
                    },
                    cursor: "pointer",
                    float:"right"

                  }}
                  onClick = {this.gotoHowardHanna}
                />
                </Grid>                  
        </Grid>
        <AlertDialog theme={this.props.theme} open={this.state.hhalertDialogOpen}  yesButtonLabel="Yes" yesButtonAction={this.gotoHowardHannaWebsite} noButtonLabel="No" noButtonAction={this.closeAlertDialog} closeAlertDialog={this.closeAlertDialog} title="Visit HowardHanna.com" message="Would you like to be redirected to the Howard Hanna webstie?"/>
        <AlertDialog theme={this.props.theme} open={this.state.hudalertDialogOpen}  yesButtonLabel="Yes" yesButtonAction={this.gotoHudWebsite} noButtonLabel="No" noButtonAction={this.closeAlertDialog} closeAlertDialog={this.closeAlertDialog} title="Visit HUD.gov" message="Would you like to be redirected to HUD Fair Housing website?"/>

      </Grid>
      )

  }
}

export default Footer;
