import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";

import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import _ from "lodash";
import Image from 'mui-image'
import StarIcon from '@mui/icons-material/Star';

class About extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",
      testimonialTiles: [
        {
          id: "1",
          image: "hhsocialmedia3.PNG",
          name: "We've Got Your Listing Covered",
          tag: null,
          summary:
            "This webpage requires data that you entered earlier in order to be properly displayed. You can send this data again.",
          download: true,
        },
        {
          id: "1",
          image: "numberone-transp.png",
          tag: null,
          name: "We're the best is CNY",
          summary:
            "This webpage requires data that you entered earlier in order to be properly displayed. You can send this data again.",
          download: true,
        },
        {
          id: "1",
          image: "hh-sign.png",
          tag: null,
          name: "Contact Your Realtor",
          summary:
          "When you choose to work with us, you can be confident that you're getting the best of the best. Whether you looking to buy or sell we'v got you covered and I'll be iwth ou everystep of the way.   Give me a call or email so we can discuss your goals and how I can help",
          download: true,
        },
      ],      
    }
  }


  toggleAbout = () => {
    this.setState({
      About: !this.state.About,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };


  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }
  

  getContactTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#F0F0F0",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={"hh-sign.png"}
            topPictureAlign={"20% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["1rem", "1rem", "1rem"]}
            topTxtType={"plain"}
            topTxtAlign={"center"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["400px", "100%", "100%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["1rem", "1rem", "1.2rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"#f0f0f0"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0px", "0px"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={"Contact Donna"}
            btmTxtColor={"black"}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#FFFFFF",
          height: "100%",
          width: "100%",
          borderBottom: "0px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            padding: "0px",
            border:"0px solid red"
            
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                height: {
                  xl: "10%",
                  lg: "10%",
                  md: "10%",
                  sm: "10%",
                },
                fontWeight: "bold",
                paddingTop: "20px",
                fontSize: "2rem",
                border:"0px solid blue",
                color:this.props.theme.textColor
              }}
              xs={12}
            >
              I'm With You Every Step Of The Way
            </Grid>    
            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "50%",
                  md: "0%",
                },
                border:"0px solid black",
                textAlign:"right"
              }}
              xs={12}
              sm={12}
              md={0}
              lg={0}
              >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundImage: 'url("profile-full-white-lowres.jpg")',
                        backgroundPosition: "50% 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        borderRadius:{
                          xs:"50px",
                          sm:"50px",
                          md:"0px",
                          lg:"0px",
                          xl:"0px"
                        },
                        width: "100%",
                        height: {
                          xs:"300px",
                          sm:"300px",
                          md:"0%",
                          lg:"0%",
                          xl:"0%"
                        },
                        padding: "0px",
                      }}
                    >
                  </Grid> 
            </Grid>        
            <Grid
              item
              sx={{
                textAlign:"center",
                height: {
                  sm: "50%",                              
                },
              }}
              xs={12}
              sm={12}
              md={9}
              lg={10}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  textAlign:"justify",
                  fontWeight: "bold",
                  width: "100%",
                  height: "100%",
                  padding: {
                    xs:"10px 10px 10px 10px",
                    md:"10px 10px 10px 40px"
                  },
                  color:this.props.theme.textColor

                }}
              >
                  <br />
                      I am committed to providing you 
                      with personalized attention and expert advice to help you achieve 
                      your real estate goals. With a deep understanding of the local 
                      market, I will work to ensure that your journey is as smooth and 
                      stress-free as possible. Whether you are a first-time buyer or an 
                      experienced seller, I am here to support you every step of the 
                      way.
                  <br/><br/>    
                      If you're interested in learning more about how I can assist you with your real 
                      estate needs, please don't hesitate to reach out. I look forward to speaking with 
                      you and answering any questions you may have. 
                
              </Grid>
            </Grid>

            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "50%",
                  md: "50%",
                },
                border:"0px solid black",
                textAlign:"center"
              }}
              xs={0}
              sm={0}
              md={3}
              lg={2}
              >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  backgroundImage: 'url("profilepics/profile-2a.png")',
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              >
              </Grid>
            </Grid>
            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "40%",
                  md: "40%",
                },
                border:"0px solid black",
                textAlign:"right"
              }}
              xs={12}
              >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  padding: "0px",
                }}
              >
                {tileBuilder}
              </Grid>
            </Grid>
          </Grid>
        </Grid>


      </Grid>
    );
  };


  getTestimonialTiles = () => {
    return (
      <React.Fragment>
        {this.state.testimonialTiles.map((tile, index) => {
          return(<Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              sm: "100%",
            },
            background: "#FFFFFF",
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >

          <CommonTile
            uid={0}
            animationType={null}
            animationContainer = {null}
            animationTime={null}
            tagTopRightIcon={ null }
            tagTopRightTxt={null}
            tagTopRightPadding={"5px"}
            tagTopRightTxtColor={"black"}
            tagTopRightTxtSize={["1.5rem", "1.5rem", "1rem"]}
            tagTopRightPaddingRight={"4px"}
            tagTopRightPaddingTop={"4px"}
            tagTopRightTxtType={"bold"}
            tagTopRightTxtAlign={"center"}
            tagTopRightBgColor={"rgba(255,255,255,.5)"}
            tagTopRightBorder={"0px solid black"}
            tagTopRightBorderRadius={"5px"}
            tagTopRightCallback={null}

            tagBottomRightIcon={null}
            tagBottomRightTxt={null}
            tagBottomRightPadding={"5px"}
            tagBottomRightTxtColor={"black"}
            tagBottomRightTxtSize={["1.5rem", "1.5rem", "1rem"]}
            tagBottomRightPaddingRight={"5px"}
            tagBottomRightPaddingBottom={"5px"}
            tagBottomRightTxtType={"bold"}
            tagBottomRightTxtAlign={"center"}
            tagBottomRightBgColor={"rgba(255,255,255,.5)"}
            tagBottomRightBorder={"0px solid black"}
            tagBottomRightBorderRadius={"5px"}
            tagBottomRightCallback={null}

            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 20px 5px 20px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={["1rem", "1rem", "1rem"]}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={"#73be73"}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}

            tagBottomLeftIcon={null}
            tagBottomLeftTxt={null}
            tagBottomLeftPadding={"10px"}
            tagBottomLeftTxtColor={"#333333"}
            tagBottomLeftTxtSize={["1rem", "1rem", "1rem"]}
            tagBottomLeftPaddingLeft={"5px"}
            tagBottomLeftPaddingBottom={"5px"}
            tagBottomLeftTxtType={"bold"}
            tagBottomLeftTxtAlign={"center"}
            tagBottomLeftBgColor={"rgba(255,255,255,.9)"}
            tagBottomLeftBorder={"0px solid black"}
            tagBottomLeftBorderRadius={"5px"}
            tagBottomLeftCallback={null}

            topCallback={null}
            topPadding={"0px"}
            topPicture={null}
            topPictureAlign={"center"}
            topPictureSize={"contain"}
            topTxt={"Awesome"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.5rem", "1.5rem",  "1.5rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topTxtLineHeight={"1.25"}
            topVerticalAlign={"center"}
            topHorizontalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "20%", "20%"]}
            topGraphType={null}
            topGraphData={null}

            mdlTagIcon={null}
            mdlTagTxt={null}
            mdlTagPadding={"0px"}
            mdlTagTxtColor={"black"}
            mdlTagTxtSize={["1.5rem", "1.5rem", "1rem"]}
            mdlTagPaddingRight={"4px"}
            mdlTagPaddingTop={"2px"}
            mdlTagTxtType={"bold"}
            mdlTagTxtAlign={"center"}
            mdlTagBgColor={null}
            mdlTagBorder={"1px solid red"}
            mdlTagBorderRadius={"0px"}       
                     
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={"center"}
            mdlPictureSize={"contain"}
            mdlTxtLineHeight={1}
            mdlTxt={
              <div
              style={{
                border: "0px solid black",
                width: "100%",
                lineSpacing: "1.2",
                padding: "5px",
                border:"0px solid red",
                background: "rgba(255,525,255,0)",
              }}
              >What a fantasic realtor!  Donna was great, my situation was so stressfull and she put me at ease and I look forward to  working with her again.  I highly recommend her to anyone in the market buying or selling!
              <br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;Randy S.
              </div>

            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", "1rem", "1rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"left"}
            mdlHorizontalAlign={"flex-start"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,0)"}
            mdlBorder={"1px solid #A0A0A0"}
            mdlHeight={["200px", "80%", "80%"]}
            mdlGraphType={null}
            mdlGraphData={null}

            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={"center"}
            btmPictureSize={"contain"}    
            btmTxt={ null }
            btmWidget={null}
            btmTxtColor={"#333333"}
            btmTxtSize={[".75rem", ".75rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={null}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}

            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid #AAAAAA"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,0)"}
          />


          </Grid>)
          })}
          </React.Fragment>
        );
      };


  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showAbout}
          onClose={this.props.toggleAbout}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"          
          PaperProps={{
            sx: {
              height: {
                xs: "90%",
                sm: "90%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              marginLeft: "auto",
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#F0F0F0",
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "0px solid #333333",
        }}
      >

      <Grid
            item
            sx={{
              background:this.props.theme.bannerColor,
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize:{
                xs:".75rem",
                sm:".75rem",
                md:".75rem",
                lg:"1rem",
                xl:"1rem"
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={5}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
               
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "10px",  
                fontSize:{
                  xs:".75rem",
                  sm:".75rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem"
                },
                color:this.props.theme.bannerTextColor

              }}             
           
            >
              Donna Wills
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={7}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleAbout}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "78%",
                  lg: "78%",
                  md: "78%",
                  sm: "78%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "#F0F0F0",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  background: "#F0F0F0",
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.aboutSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top Aboutage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getTestimonialTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
        <Grid 
              item
              sx={{
                background: "#FFFFFF",
                padding: "5px",
                height: {
                  sm: "10%",
                  md: "10%",
                },
                border:"0px solid black",
                textAlign:"right"
              }}
              xs={12}
              >
                <Footer theme={this.props.theme} />
            </Grid>

      </Grid>
      </Drawer>
    );
  }
}

export default About;
