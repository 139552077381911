import React from 'react';
import { FormControl, InputLabel, Select, Checkbox, ListItemText, MenuItem } from '@mui/material';



class WeekdaySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDay: null,
      selectedTimes: [],
    };
  }

  handleDayChange = (event) => {
    this.setState({ selectedDay: event.target.value }, () => this.props.setDay(event.target.value));
  };

  handleTimeChange = (event) => {
    this.setState({ selectedTimes: event.target.value }, () => this.props.setTimes(event.target.value));
  };

  render() {
    const { selectedDay } = this.state;
    const { selectedTimes } = this.state;

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const timesOfDay = ['9:00 AM','10:00 AM','11:00 AM','12:00 AM','1:00 PM','2:00 PM','3:00 PM','4:00 PM','5:00 PM','6:00 PM','7:00 PM','8:00 PM'];

    return (
      <React.Fragment>
      <FormControl sx={{
        width:{
          xs:"48%", 
          sm:"48%", 
          md:"48%", 
          lg:"48%", 
          xl:"48%" 
        },
        padding:"10px 5px 2px 5px"}}>

        <InputLabel id="weekday-select-label"        
        >Day</InputLabel>
        <Select
          size="small"
          
          labelId="weekday-select-label"
          id="weekday-select"
          value={selectedDay}
          onChange={this.handleDayChange}
          sx={{
            borderRadius:"0px",
            backgroundColor: 'rgb(232, 240, 254)',
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
        }}          
        InputProps={{
          style: {
            background:'rgb(232, 240, 254)',
          },
        }}                      
        >
          {daysOfWeek.map((day) => (
            <MenuItem key={day} value={day}>{day}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{width:"4%" }}></FormControl>

      <FormControl sx={{
        width:{
          xs:"48%", 
          sm:"48%", 
          md:"48%", 
          lg:"48%", 
          xl:"48%" 
        },
        padding:"10px 5px 2px 5px"}}>
        <InputLabel id="time-select-label">Times</InputLabel>
        <Select
          size="small"
          labelId="times-select-label"
          id="times-select"
          
          multiple
          value={selectedTimes}
          onChange={this.handleTimeChange}
          renderValue={(selected) => selected.join(', ')}
          sx={{
            borderRadius:"0px",
            backgroundColor: 'rgb(232, 240, 254)',
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
        }}    

        >
          {timesOfDay.map((time) => (
            <MenuItem key={time} value={time}>
              <Checkbox checked={selectedTimes.indexOf(time) > -1} />
              <ListItemText primary={time} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </React.Fragment>

    );
  }
}

export default WeekdaySelect;
