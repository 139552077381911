import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class MyServicesBlog extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      selectedEntry:1,
      pictureHeight: "100%",
      pictureWidth: "60%",
      MyServicesBlogTiles: [
        {
          id: "1",
          image: "downloads/selling.png",
          title: "Buyers Agent vs Sellers Agent",
          fullSize: true,
          footer:"Download : ",
          blog: "A Seller's Agent and a Buyer's Agent play distinct yet complementary roles in the real estate industry, \
          catering to the needs of different parties in a property transaction. \
          <br><br>\
          A <b>Seller's Agent</b>, also known as a listing agent, \
          represents homeowners looking to sell their properties. They are experts in market analysis, pricing strategies, \
          and property marketing. Sellers can rely on them to accurately assess their home's value, create eye-catching listings, \
          and negotiate with potential buyers to secure the best possible deal. Seller's agents work diligently to maximize the \
          seller's profit and guide them through the entire selling process. \
          <br/><br/>\
          On the other hand, a <b>Buyer's Agent</b> exclusively represents home buyers. They help clients find suitable properties, evaluate\
          them against their criteria, and negotiate on their behalf. Buyer's agents have in-depth knowledge of the local real estate\
          market and can provide valuable insights into neighborhoods, pricing trends, and property condition. Their primary objective\
          is to protect the buyer's interests and ensure they make an informed purchase.\
          <br/><br/>\
          In summary, while both types of agents share a commitment to their clients, their roles differ significantly. Seller's agents\
          focus on marketing and selling properties, whereas buyer's agents concentrate on helping buyers navigate the purchasing process.\
          Each serves as a vital resource for their respective clients, ensuring a smooth and successful real estate transaction.,\
          <br/><br/>\
          I provide specific services for both my buyers and my sellers.  If your thinking about entering the real estate market have a look\
          at all my services (download below).  When your ready to start your journey call me to learn how I can help.",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
          ]
        },
        {
          id: "2",
          image: "downloads/selling.png",
          title: "Finding An Agent - Should I Interview Agents",
          fullSize: true,
          footer:"Download : ",
          blog: "Absolutely Yes! Interviews are essential and a great real estate agent will posses a unique blend of qualities and skills that set them apart in a competitive industry. \
          Here are the key attributes that make a realtor exceptional:\
          <br/>\
          <br/>1. <b>Expertise</b>: A great agent has deep knowledge of the local real estate market. Ask your prospective agent about the current local market.\
          <br/>2. <b>Communication</b>: Effective communication is paramount. Great agents listen - make sure you feel heard by your agent before signing with them!\
          <br/>3. <b>Negotiation Skills</b>: Negotiation is a critical aspect of real estate transactions. Present a scenario to your prospective agent and gauge their response.\
          <br/>4. <b>Integrity</b>: Honesty and integrity are non-negotiable. A great agent prioritizes their client's best interests.\
          <br/>5. <b>Responsiveness</b>: Timely responses to calls, emails, and inquiries are vital. Great agents are accessible and readily available for your needs.\
          <br/>6. <b>Problem-Solving</b>: Real estate transactions can be complex, and challenges can arise. Exceptional agents are adept at finding solutions.\
          <br/>7. <b>Marketing Savvy</b>: For sellers, effective marketing strategies are key to selling a property. Ask your prospective agent how they'll market your home.\
          <br/>8. <b>Patience and Empathy</b>: Real estate transactions can be emotional and stressful. Great agents exhibit patience, empathy, and a calming presence.\
          <br/>9. <b>Adaptability</b>: The real estate landscape is constantly evolving. Ask your prospective agent about a time when they had to adapt to save a sale.\
          <br/>10. <b>Client-Centric Approach</b>: A client's needs and goals are at the forefront. Great agents provide great services to each individual client.\
          <br/><br/>\
          A great real estate agent combines these skills, and a client-focused approach to deliver outstanding \
          service. They go above and beyond and they're ready to go the extra mile to make their clients' real estate experience smooth, successful, and rewarding.\
          <br><br/>\
          As you talk to agents keep the above key attributes in mind and think about how they'll work with you from a personality perspective and how they'll \
          work with you from a financial perspective.\
          <br><br/>\
          Most importantly, always interview your realtor keeping in mind they are going to be handling one of the most significant transaction you can make.\
          <br><br/>",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
            {
              downloadLink: "selling",
              downloadName: "For Seller",              
            },
            {
              downloadLink: "buying",
              downloadName: "For Buyers",              
            },
          ]
        },
        {
          id: "3",
          image: "downloads/selling.png",
          title: "Micron & CNY : Pros & Cons",
          fullSize: true,
          footer:"Download : ",
          blog: "The potential arrival of a Micron chip fabrication facility in our county brings both opportunities and challenges.  The real estate market is going to be\
          exciting over the next few years as the Micron manufacturing facility begins constructions and new hiring begins.  In the coming years there's going to be speculation\
          about home and land prices.  When and where is it best to buy or sell? What will be the right price for land and for housing?  As I'v evaluated the market I'v noticed\
          things that are going to positively effect our real estate market and I'v noticed some things that I think we'll need to keep an eye on.   As your real estate agent I will\
          share my knowledge with you and will be here every step of the way to help you navigate the opportunities and challenges that come with this significant change.\
          <br><br>\
          Here are some of the advantages I have at the tope of my list :\
          <br>\
          <br>1. <b>Economic Boost</b>: The presence of a major technology company can stimulate the local economy, creating jobs and attracting talent to the area.\
          <br>2. <b>Increased Property Values</b>: Property values in proximity to such facilities often experience growth due to increased demand from employees and related businesses.\
          <br>3. <b>Infrastructure Development</b>: Micron's investment may lead to improved infrastructure and amenities in the area, enhancing the overall quality of life.\
          <br>4. <b>Business Opportunities</b>: This presents opportunities for new businesses or real estate ventures catering to the workforce and industry needs.\
          <br><br>\
          Here are some possible down sides I also have my eye on :\
          <br>\
          <br>1. <b>Traffic and Congestion</b>: Increased population and industrial activity can lead to local traffic congestion and related challenges.\
          <br>2. <b>Environmental Impact</b>: Chip fabrication facilities may have environmental implications that need to be addressed, potentially affecting local quality of life.\
          <br>3. <b>Housing Demand</b>: While property values may rise, increased housing demand could lead to rising costs, affecting overall affordability for some residents.\
          <br>4. <b>Community Changes</b>: The influx of new residents and industries can lead to shifts in the local community's dynamics and character.\
          <br><br>\
          Please don't hesitate to reach out to me for a personalized consultation, an in depth discussion and a comprehensive understanding of how this development may impact\
          your real estate goals.  Together, we'll make informed decisions and seize upon the advantages while managing any challenges that arise.",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
          ]
        },

        {
          id: "4",
          image: "downloads/selling.png",
          title: "My Homes Best First Impresssion (How To)",
          fullSize: true,
          footer:"Download : ",
          blog: "Making a memorable first impression on potential buyers is crucial when selling a home, and as an experienced\
          realtor, my expertise in marketing, valuation, and staging can be instrumental in achieving this goal. Here are key \
          steps that sellers should take to create an outstanding first impression:\
          <br/>\
          <br/>1. <b>Curb Appeal</b>: Enhance the exterior of the property.\
          <br/>2. <b>Deep Cleaning</b>: Present a spotless interior by thoroughly cleaning every room.\
          <br/>3. <b>De-clutter and Depersonalize</b>: Remove personal items and excess clutter to allow potential buyers to envision themselves in the home.\
          <br/>4. <b>Neutralize Decor</b>: Paint over bold or personalized wall colors with neutral tones that appeal to a broader range of buyers.\
          <br/>5. <b>Maximize Lighting</b>: Ensure every room is well-lit, both naturally and artificially.\
          <br/>6. <b>Minor Repairs</b>: Address visible issues like leaky faucets, chipped paint, or loose doorknobs.\
          <br/>7. <b>Professional Staging</b>: Invest in professional staging to showcase the property's potential and create an inviting atmosphere.\
          <br/>8. <b>Effective Advertising</b>: Leverage your agent's skill set to market your home and create a compelling listing with exceptional photography.\
          <br/><br/>\
          By following these steps and utilizing my experience in marketing, valuation, and staging, you can make a remarkable first impression on\
          potential buyers, increasing the likelihood of a successful sale at an optimal price. I'll work closely with you to ensure your property\
          stands out in the competitive market and leaves a lasting positive impression on prospective purchasers.",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
            {
              downloadLink: "selling",
              downloadName: "For Sellers",              
            }
          ]
        },

            {
          id: "5",
          image: "downloads/selling.png",
          title: "What Is Dual Agency",
          fullSize: true,
          footer:"Download : ",
          blog: "Dual agency in real estate occurs when a single agent or brokerage represents both the buyer and the seller in a transaction. This arrangement has its advantages\
          and disadvantages, and when handled by a trustworthy agent, it can be an important and viable option.\
          <br><br>\
          <b>Benefits of Dual Agency:</b>\
          <br>\
          <br>1. <b>Simplified Communication</b>: Having one agent manage both sides of the transaction can streamline communication and reduce the risk of misunderstandings.\
          <br>2. <b>Complete Access</b>: A dual agent can provide comprehensive information about the property, facilitating a more informed decision-making process.\
          <br>3. <b>Potential Cost Savings</b>: In some cases, dual agency can lead to reduced commissions for both parties, potentially saving money.\
          <br><br>\
          <b>Some Things To Be Aware Of:</b>\
          <br>\
          <br>1. <b>Conflict of Interest</b>: The primary concern is the potential for conflicts of interest. A dual agent must balance the interests of both parties fairly.\
          <br>2. <b>Limited Advocacy</b>: Each party may receive less advocacy and negotiation support than they would from a dedicated buyer's or seller's agent.\
          <br>3. <b>Transparency</b>: Dual agents must be exceptionally transparent to maintain trust, as both parties need to be aware of any information that could affect their decisions.\
          <br><br>\
          In summary, while dual agency can simplify the process and potentially save costs, it comes with a heightened need for trust and transparency. With a trustworthy agent\
          who can impartially manage both sides of the transaction and ensure a fair deal, dual agency can be a great option for both buyer and seller.",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
          ]
        },

        {
          id: "6",
          image: "downloads/selling.png",
          title: "I'm Selling My Home - What Will Happen",
          fullSize: true,
          footer:"Download : ",
          blog: "When selling a home, it's essential to know what to expect to ensure a successful and smooth process. Here's a list of things you should anticipate:\
          <br>\
          <br>1. <b>Property Valuation</b>: Expect to work closely with me, your realtor, to determine the right listing price based on a detailed market analysis and your property's condition.\
          <br>2. <b>Home Preparation</b>: Be ready to make necessary repairs, de-clutter, and stage the home to present it in the best possible light.\
          <br>3. <b>Showings</b>: You'll have prospective buyers visiting your home. Prepare for occasional disruptions and be sure to maintain the property's pristine appearance.\
          <br>4. <b>Market Response</b>: Understand that the time it takes to secure a buyer can vary. The market dynamics and property condition are factors affecting this.\
          <br>5. <b>Negotiations</b>: Be ready for potential negotiations regarding the sale price and any requested repairs or concessions.\
          <br>6. <b>Inspections and Appraisals</b>: Buyers may conduct inspections, and an appraisal may be required. Prepare for possible repair negotiations for any issues uncovered.\
          <br>7. <b>Closing Process</b>: The closing involves signing documents, and your involvement is necessary. After the contract for sale is signed, final closing my take 60-90 days.\
          <br>8. <b>Moving Plans</b>: Prepare for the logistics of moving, whether you're hiring professional movers or handling it yourself.\
          <br>9. <b>Settlement Statement</b>: Review the Closing Disclosure or HUD-1 statement to understand the final costs and proceeds from the sale.\
          <br>10. <b>Property Transfer</b>: After closing, you'll transfer ownership to the new buyer and wrap up any final details.\
          <br><br>\
          It's a lot to do, but dont worry, throughout this journey I will be here to provide guidance, answer questions, and ensure a successful sale. \
          <br><br>\
          Your satisfaction and confidence in the selling process will be my top priorities. \
          <br><br>",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
            {
              downloadLink: "selling",
              downloadName: "For Sellers",              
            }
          ]
        },

        {
          id: "7",
          image: "downloads/selling.png",
          title: "I'm Buying A Home - What Will Happen",
          fullSize: true,
          footer:"Download : ",
          blog: "When buying a home, it's essential to know what to expect to make the process smoother and more manageable. Here's a list of things you should anticipate:\
          <br>\
          <br>1. <b>Financial Preparation</b>: Expect to gather financial documents, like pay stubs, tax returns, and bank statements, for the mortgage application process.\
          <br>2. <b>Property Search</b>: The search for the perfect home can take time, so be patient. It may require multiple viewings before finding the right fit.\
          <br>3. <b>Mortgage Approval</b>: After applying for a mortgage, there's a waiting period for approval. Expect some back-and-forth with the lender.\
          <br>4. <b>Home Inspection</b>: A professional home inspection is essential to uncover any issues with the property. Prepare for possible repair negotiations.\
          <br>5. <b>Closing Costs</b>: Be aware of the various closing costs, such as fees for inspections, appraisals, title insurance, and budget for them accordingly.\
          <br>6. <b>Escrow and Closing</b>: The closing process involves signing numerous documents and transferring funds. Expect this to happen between 30 to 90 days after purchase.\
          <br>7. <b>Moving Logistics</b>: Arrange for movers or plan a DIY move, and be sure to account for the time you'll need for packing and unpacking.\
          <br>8. <b>Home ownership Costs</b>: Beyond the purchase price, budget for ongoing expenses like property taxes, homeowners insurance, utilities, and maintenance.\
          <br>9. <b>Settlement Statement</b>: Review the Closing Disclosure or HUD-1 statement to understand the final costs and credits associated with the transaction.\
          <br>10. <b>Property Ownership</b>: Enjoy the excitement of home ownership and the process of settling into your new space.\
          <br></br>\
          Throughout this journey, as your realtor, I will be with you every step of the way, providing guidance, answering questions, and ensuring that the process is as\
          smooth and stress-free as possible.\
          <br></br>",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
            {
              downloadLink: "selling",
              downloadName: "For Buyers",              
            }
          ]
        },

        {
          id: "8",
          image: "downloads/selling.png",
          title: "Can You Tell Me My Homes Value",
          fullSize: true,
          footer:"Download : ",
          blog: "Yes I can provide you with a valuation of your home or of a home your interested in buying.  When it comes to providing you with an accurate home valuation and local market evaluation\
          services my experience and tool sets are here to help you. Here's why you can trust me to guide you through this essential aspect of your real estate journey:\
          <br><br>\
          <b>Accurate Valuation</b>: With access to cutting-edge tools and an in-depth understanding of local market dynamics, I have the expertise to accurately assess the\
          value of your home. I'll provide you with a precise estimate that takes into account factors like location, property condition, recent sales, and market trends,\
          ensuring you have a realistic and competitive listing price.\
          <br><br>\
          <b>Honest Assessment</b>: Integrity is at the core of my real estate practice. I'll always provide you with an honest and transparent evaluation of your property's worth.\
          Whether the news is great or challenges exist, you can count on me to give you a truthful assessment, allowing you to make informed decisions.\
          <br><br>\
          <b>Friendly Expertise</b>: Beyond the numbers, I bring a friendly and approachable demeanor to our interactions. I understand that real estate decisions can be stressful,\
          and I'm here to support you every step of the way. You can rely on me to answer your questions, address your concerns, and provide guidance with a smile.\
          <br><br>\
          <b>Market Insight</b>: I stay continuously updated on local market conditions and trends, giving you a competitive edge. My market evaluations are not just numbers;\
          they are insights into the dynamic real estate landscape, helping you position your property effectively in the market.\
          <br><br>\
          <b>Personalized Service</b>: Your unique situation and goals matter to me. I'll tailor my services to meet your specific needs, ensuring that your home valuation and\
          market evaluation are aligned with your objectives, whether you're buying, selling, or investing.\
          <br><br>\
          I'm dedicated to providing you with the most reliable and insightful home valuation and market evaluation services, ensuring your real estate decisions are based\
          on trustworthy information and personalized guidance.\
          <br><br>\
          Your satisfaction in an informed home valuation are important to me.",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
            {
              downloadLink: "selling",
              downloadName: "For Sellers",              
            }
          ]
        },

        {
          id: "9",
          image: "downloads/selling.png",
          title: "What's A Contract Contingency (Should I Worry?)",
          fullSize: true,
          footer:"Download : ",
          blog: "Dont Worry! As an experienced realtor I'm here to guide you through the intricate world of real estate, and one crucial aspect you should be aware of is\
          the concept of a contract contingency. Think of a contract contingency as a protective measure that offers flexibility and peace of mind to both home buyers and sellers.\
          <br><br>\
          Lets look at a couple contingency examples:\
          <br><br>\
          For buyers, one type of contingency is the <b>inspection contingency</b>. With this provision, you as the buyer have the opportunity to enlist professionals to thoroughly\
          inspect the property for potential issues, like structural concerns or hidden defects. If any significant problems emerge during the inspection, I'll assist you in\
          negotiating repairs, a price reduction, or even opting out of the deal without any financial repercussions.\
          <br><br>\
          On the seller's side, we might consider a <b>home sale contingency</b> if as a seller you need to find a home before moving out of your current home.\
          This contingency ensures that you have a place to go before your current home sale is closed. \
          <br><br>\
          Contingencies can provide valuable safeguards that I, as your realtor, will help you navigate. It's important to also remember that contingencies come with specific\
          time frames, and failing to meet these deadlines can affect the contract's status. Rest assured, I'll work closely with you throughout the process to ensure any contingencies\
          are managed effectively, leading to a smooth and successful transaction.\
          <br><br>\
          Your peace of mind is my priority\
          <br><br>",
          downloads: [
            {
              downloadLink: "services",
              downloadName: "My Services",              
            },
            {
              downloadLink: "selling",
              downloadName: "For Sellers",              
            },
            {
              downloadLink: "buying",
              downloadName: "For Buyers",              
            }
          ]
        },

        
      ],      
    }
  }


  toggleMyServicesBlog = () => {
    this.setState({
      MyServicesBlog: !this.state.MyServicesBlog,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };

  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "I'll Be With You Every Step Of The Way",
      summary:
      "I will work to ensure that your journey is as smooth and stress-free as possible. Whether you are a first-time buyer or an experienced home owner, I am here to support you every step of the way.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"My Most Requested Insights"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1rem", "1rem", "1rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={
              <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", border:"0px solid black", padding:"0px"}} >
                    <Grid item xs={12}>
                     <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                     {this.state.MyServicesBlogTiles.map((blog, index) => (
                      <Grid item 
                        onClick={() => {this.setState({selectedEntry: blog.id })}}
                        sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              border: "1px solid #DDDDDD", 
                              },
                              border: this.state.selectedEntry === blog.id ? "1px solid #DDDDDD" :"1px solid #FFFFFF", 
                              background: this.state.selectedEntry === blog.id ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", 
                              fontSize:".85rem",
                              fontWeight:"bold", 
                              padding:"5px 5px 5px 5px"}} xs={12}>
                                  <Grid container justifyContent="center" alignItems="center" sx={{width:"100%", height:"100%", padding:"0px"}} >                                
                                    {blog.title}
                                  </Grid>
                                
                                </Grid>
                        ) )}    
                </Grid>
                </Grid>
                </Grid>
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", "1rem", "1rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["400px", "90%", "90%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".85rem", ".85rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
            </Grid>
      </React.Fragment>
    );
  };

  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
                <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "0px",
            fontSize: "2rem",
            borderBottom: "0px solid #333333",
          }}
          xs={12}
        >
          </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              
              height: "100%",
              width: "100%",
            }}
          >
            {this.getContactTile()}
            {tileBuilder}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };


  getMyServicesBlogTiles = () => {
    let tile = this.state.MyServicesBlogTiles[this.state.selectedEntry-1]
    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={12}
            >
               <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={null}
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"5px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"#F0F0F0"}
                tagTopLeftBorder={"1px solid #888888"}
                tagTopLeftBorderRadius={"0px"}
                tagTopLeftCallback={null}                                

                topPadding={"0px 0px 0px 10px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={tile.title}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topHorizontalAlign={"fles-start"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={tile.fullSize ? ["50px", "10%", "10%"] : ["50px", "10%", "10%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={
                  <div dangerouslySetInnerHTML={{ __html: tile.blog }} />
                  }
                mdlOverflow={"auto"}
                mdlTxtColor={this.props.theme.textColor}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"normal"}
                mdlTxtAlign={"left"}
                mdlTxtLineHeight={"1.5"}
                mdlHorizontalAlign={"flex-start"}
                mdlVerticalAlign={"flex-start"}
                mdlBgColor={tile.fullSize ? "white" : "#F0F0F0"}
                mdlBorder={tile.fullSize ?  "0px solid black" : "1px solid black"}
                mdlHeight={["350px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"2px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={
                  <React.Fragment>
                    <br/>
                 </React.Fragment>

                }
                btmTxtColor={this.props.theme.bannerTextColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"right"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-end"}
                btmVerticalAlign={"center"}
                btmBgColor={this.props.theme.bannerColor}
                btmBorder={tile.fullSize ? "0px solid black" : "1px solid black"}
                btmHeight={tile.fullSize ? ["50px", "10%", "10%"] : ["150px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              </Grid>
      </React.Fragment>
    );
  };


  downloadResources = (filename) => {
    this.setState({inProgress:true}, () => this.props.getInformationDownload("MyServices", filename, this.callback))
  }

  callback = (response) => {

    if(response.data.message){
      const base64String = response.data.message;

      // Convert base64 string to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a download link and trigger click
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = response.data.filename + '.jpg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    }

    this.setState({inProgress:false})

  }
  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showBlog}
          onClose={this.props.toggleBlog}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "78%",
                sm: "77%",
                md: "73%",
                lg: "74%",
                xl: "74%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex,
              borderTop:"2px solid " + this.props.theme.bannerColor,

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.MyServicesBlogSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top MyServicesBlogage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getMyServicesBlogTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default MyServicesBlog;
