import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import WeekdaySelect from "./WeekdaySelect";
import CommonTile from "./CommonTile";
import Image from 'mui-image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-before-after-slider-component/dist/build.css';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

class SlideShowDialog extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      slideIndex: 0,
      requestShowing: false,
      inProgress: false,
      name: '',
      phoneNumber: '',
      email: '',
      errors: {
        name: '',
        phoneNumber: '',
        email: '',
      },
      firstDay: null,
      firstTimes: [],
      secondDay: null,
      secondTimes:[],
      requestSent:false,
      favorites: [],
    }
  }



  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    const errors = this.state.errors;

    errors[name] = ''

    this.setState({ [name]: newValue, errors: errors, requestSent:false});

  };  

  sendContactCard = (event) => {

    event.preventDefault();

    const { name, phoneNumber, email } = this.state;
    const errors = {};

    if (!name) {
      errors.name = 'Name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name must be alphanumeric';
    }

   // Phone number validation
    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else {
      let strippedPhoneNumber = phoneNumber.replace(/-/g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\./g, ''); // Remove dashes from the input
      strippedPhoneNumber = strippedPhoneNumber.replace(/\s/g, ''); // Remove dashes from the input

      if (!/^\d{10}$/.test(strippedPhoneNumber)) {
        errors.phoneNumber = 'Phone number must be 10 digits 123-456-7890';
      } else {
        // Format the phone number with dashes for display
        const formattedPhoneNumber = strippedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        this.setState({ phoneNumber: formattedPhoneNumber });
      }
    }

    // Email validation
    if (!email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors, requestSent:false});
    } else {
      // Form is valid, perform further actions or submit
      this.setState({ errors: errors, inProgress:true }, () => {
        this.props.requestShowing(
          this.props.parentPage,
          this.state.name,
          this.state.phoneNumber,
          this.state.email,
          this.props.listing ? this.props.listing.ListingId : "",
          this.props.listing ? this.props.listing.UnparsedAddress : "",
          this.props.listing ? this.props.listing.ListPrice : "",
          this.props.listing ? this.props.listing.LivingArea + " sf | " + this.props.listing.BedroomsTotal + " bd | " + this.props.listing.BathroomsFull + "." + this.props.listing.BathroomsHalf + " ba": "",
          this.state.firstDay,
          this.state.firstTimes,
          this.state.secondDay,
          this.state.secondTimes,
          this.callback
          )});
    }
}


  callback = (data) => {
    this.setState({inProgress:false, requestSent:true})
  }

  setFirstDay = (firstDay) => {

    this.setState({
      firstDay: firstDay
    })
  }

  setFirstTimes = (firstTimes) => {

    this.setState({
      firstTimes: firstTimes
    })
  }

  setSecondDay = (secondDay) => {

    this.setState({
      secondDay: secondDay
    })
  }

  setSecondTimes = (secondTimes) => {

    this.setState({
      secondTimes: secondTimes
    })
  }

  toggleRequestShowing = () => {

      this.setState({
        requestShowing: !this.state.requestShowing
      })    
  }

  nextSlide = (id) => {
    if(this.props.photos && this.props.photos.length > 0 ){
      if(this.state.slideIndex+1 < (this.props.photos ? this.props.photos.length:0)){
        this.setState({
          slideIndex: this.state.slideIndex+1
        })
      }else{
        this.setState({
          slideIndex: 0
        })
      }
    }
  }

  previousSlide = (id) => {
    if((this.props.photos ? this.props.photos.length:0) > 0){
      if(this.state.slideIndex > 0){
        this.setState({
          slideIndex: this.state.slideIndex-1
        })
      }else{
        this.setState({
          slideIndex: (this.props.photos ? this.props.photos.length:0)
        })
      }
    }
  }

  getImageToDisplay = () => {

    if(this.props.listing && this.props.photos){
      if(this.state.slideIndex < this.props.photos.length){
          return(
            <React.Fragment>
              <Image src={this.props.photos[this.state.slideIndex]["MediaURL"]} bgColor="rgba(255,255,255,0)" fit="fit" duration={200}/>              
            </React.Fragment> 
          )          
      }else{
          return null
      }
    }else{
      return null
    }
  }


  getSendEmailTile = () => {
    
    return (
      <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%"},
                overflowY:"auto"       
              }}
            >
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            overflowX:"hidden",
            background: "#FFFFFF",
            border:"0px solid #333333"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height:"100%",
                  width: { md: "100%", xs: "100%" },
                  padding: "0px",
                }}
              >
                <Grid
                  item
                  sx={{
                    height:"200px",
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"center",
                    fontSize:"1.5rem"
                    
                  }}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height:"200px",
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    border:"1px solid #AAAAAA",

                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1.25rem",
                      xl:"1.25rem",
                    }
                  }}
                >
                  <Image src={this.props.listing && this.props.listing.Media ? this.props.listing.Media[0]["MediaURL"] : null} bgColor="rgba(255,255,255,0)" fit="fit" duration={200}/>              
                </Grid>
                </Grid>


                <Grid
                  item
                  sx={{
                    height:"200px",
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"center",
                    fontSize:"1.25rem"
                    
                  }}
                  xs={7}
                  sm={7}
                  md={5}
                  lg={5}
                  xl={5}
                >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height:"200px",
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    borderBottom:"1px solid #AAAAAA",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                >
                  Thank You For Reaching out!<br/> Please tell me how best to connect with you <br/> and your availability for a showing.<br/>
                </Grid>
                </Grid>

                <Grid                
                  item
                  sx={{
                    height:"200px",
                    paddingTop: "0px",
                    fontWeight: "normal",
                    textAlign:"right",
                    borderBottom:"1px solid #AAAAAA",
                    borderRight:"0px solid #AAAAAA",
                    }}
                  xs={5}
                  sm={5}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Box
                    component="img"
                    src="profilepics/profile-full-white-lowres.jpg.png"
                    alt="Image description"
                    sx={{
                      height: "200px",
                      objectFit: 'contain',
                    }}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    padding: "20px 5px 5px 5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"15%",
                    }
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                      width: "100%" ,
                      backgroundColor: this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                    }}                    
                    InputProps={{
                      style: {
                        background:this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    label="Your Name"
                    variant="filled"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.name)}
                    helperText={this.state.errors.name}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "20px 5px 5px 5px",
                    fontWeight: "normal",
                    height:{
                      xs:"12%",
                      sm:"12%",
                      md:"12%",
                      lg:"12%",
                    }
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                      width: "100%",
                       backgroundColor: this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                    InputProps={{
                      style: {
                        background:this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    id="outlined-basic"
                    label="Phone Number"
                    variant="filled"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.phoneNumber)}
                    helperText={this.state.errors.phoneNumber}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"12%",
                      sm:"12%",
                      md:"12%",
                      lg:"12%",
                    }
                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       backgroundColor: this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                    InputProps={{
                      style: {
                        background:this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    id="outlined-basic"
                    label="Email Address"
                    variant="filled"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.email)}
                    helperText={this.state.errors.email}
                  />
                </Grid>
                
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    
                  }}
                  xs={12}
                >
                  <WeekdaySelect
                  setDay={this.setFirstDay}
                  setTimes={this.setFirstTimes}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    
                  }}
                  xs={12}
                >
                  <WeekdaySelect
                  setDay={this.setSecondDay}
                  setTimes={this.setSecondTimes}
                  />
                </Grid>

                  {/*
                <Grid
                  item
                  sx={{
                    paddingLeft: "0px",
                    paddingTop: "5px",
                    fontWeight: "normal",
                    borderTop: "1px solid #AAAAAA",
                    textAlign:"left",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                  xs={12}
                >
                  <div>
                    Hi Donna,<br />
                    <blockquote>
                      My name is <b>Roger Nestler</b>, I'm intested in a showing for<br/>
                      <br/>
                      <b>1234 Main Street Lane, Baldwinsville NY 13027</b><br/>
                      <br/>
                      Please contact me at:<br/>
                      <b>315-247-3314</b> or <b>roger.nestler@gmail.com.</b>
                      <br />
                      <br />I Have the below days and times availabe.
                      <br/>
                      <b>Monday</b> at <b>11:00AM</b>, <b>6:00PM</b><br/>
                      <b>Saturday</b> at <b>1:00PM</b>, <b>2:00PM</b>, <b>3:00PM</b>

                    </blockquote>
                    Thanks
                    <br />
                    <br />
                  </div>
                </Grid>
                */}
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                  xs={12}
                >
                    {this.state.inProgress ? 
                      <Button
                      variant={this.props.theme.buttonVariant}
                      sx={{
                        textTransform: "none",
                        background:this.props.theme.bannerColor,
                        color:this.props.theme.bannerTextColor,
                        border:"1px solid " + this.props.theme.buttonColor,
                        textTransform: "none",
                        width: "100%",
                        height: "40px",
                        borderTopLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderBottomLeftRadius: "25px",
                      }}
                      size="small"
                     
                    >
                        <CircularProgress disableShrink size={20} color="primary"/>
                    </Button>
                    :
                  <Button
                    onClick={this.sendContactCard}
                    variant={this.props.theme.buttonVariant}
                  sx={{
                    '&:hover': {
                      border:"1px solid " + this.props.theme.buttonTextColor,
                      background:this.props.theme.bannerColor
                    },
        
                    marginRight: "10px",
                    textTransform: "none",
                    textAlign: "center",
                    fontSize:{
                      xs:".75rem",
                      sm:".75rem",
                      md:".75rem",
                      lg:"1rem",
                      xl:"1rem"
                    },
                    background:this.props.theme.bannerColor,
                    color:this.props.theme.bannerTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    textTransform: "none",
                    width: "100%",
                    height: "40px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderBottomLeftRadius: "25px",
                  }}
                  size="small"
                  >
                    Click To Send
                  </Button>
                  }
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop:"10px"
                  }}
                  xs={12}
                >

                    {this.state.requestSent
                      ? <Alert sx={"padding:2px 5px 2px 5px"} severity="success">Thank you, I'll contact you to confirm your showing - Donna</Alert>
                      : <Alert sx={"padding:2px 5px 2px 5px"} severity="info">Providing time slots will help me schedule your tour times quicker.</Alert>}

                </Grid>
              </Grid>
          </Grid>
          
      </Grid>
    );
  };




  render() {
    const {open, onClose, children } = this.props;

    const FIRST_IMAGE = {
      imageUrl: 'background.png'
    };
    const SECOND_IMAGE = {
      imageUrl: 'background2.png'
    };


    return (
      <Dialog open={open} onClose={onClose} 
        
        sx={{
          height:{
            xs:'100dvh',
            xs:'100dvh',
            md:'95dvh'
          },
          overflowY:"hidden", '& .MuiDialog-paper': {
            width: {
              xs:'100vw',
              sm:'100vw',
              md:'80vw',
              lg:'60vw',
              xl:'60vw',
            },
            maxWidth: 'unset',
          }  }}      
        >
        <DialogContent  sx={{maxHeight:'85dvh', minHeight:'85dvh',padding:"0px" , overflowY:"hidden",             
 background:"rgba(255,55,55,0)"}}> 
         
        <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background:"rgba(55,55,55,0)",
                height: "85dvh",
                padding: "0px",  
                overflowY:"hidden"             
              }}
            >

          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              color:this.props.theme.bannerTextColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "0px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              overflowY:"hidden",             
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={1}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "0%" },
                paddingLeft: "10px",  
                fontSize:{
                  xs:"0rem",
                  sm:"0rem",
                  md:".8rem",
                  lg:".8rem",
                  xl:".8rem",
                }             
              }}
            >
              {this.props.listing && this.props.listing.ListPrice ? "$" + this.props.listing.ListPrice.toLocaleString() : this.props.listing && this.props.listing.Narrative1 ? this.props.listing.Narrative1 :"" }
              <br />
              {this.props.listing && this.props.listing.UnparsedAddress ?  this.props.listing.UnparsedAddress + ", " + this.props.listing.PostalCity : this.props.listing &&  this.props.listing.Narrative2 ? this.props.listing.Narrative2 :""}
              <br />
              {this.props.listing && this.props.listing.LivingArea ? this.props.listing.LivingArea.toLocaleString() + " sf | " + this.props.listing.BedroomsTotal + " bd | " + this.props.listing.BathroomsFull + "." + this.props.listing.BathroomsHalf + " ba" : this.props.listing && this.props.listing.Narrative3 ? this.props.listing.Narrative3 : ""}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              color:this.props.theme.bannerTextColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "0px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={11}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                overflowY:"hidden",             
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingRight: "5px",
              }}
            >
              {
                this.props.listing && this.state.requestShowing ?
                <React.Fragment>  
                  <Button
                  onClick={() => this.toggleRequestShowing()}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                      '&:hover': {
                        border:"1px solid " + this.props.theme.buttonTextColor,
                        background:this.props.theme.buttonHoverColor
                      },       
                    marginRight: "5px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "px",
                    borderBottomLeftRadius: "25px",
                    textTransform: "none",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize: {
                      xs: ".75rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem"
                    }                  
                  }}
                >
                Cancel Request
                </Button>
              </React.Fragment>
                : this.props.listing && this.props.requestShowing ?

                <React.Fragment>  
                  <Button
                  onClick={() => this.toggleRequestShowing()}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                      '&:hover': {
                        border:"1px solid " + this.props.theme.buttonTextColor,
                        background:this.props.theme.buttonHoverColor
                      },       
                    marginRight: "5px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "25px",
                    textTransform: "none",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize: {
                      xs: ".75rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem"
                    }                  
                  }}
                >
                  Request A Showing
                </Button>
                </React.Fragment>
                : null
                } 

                { this.props.requestShowing ?
                  <React.Fragment>
                  <Button
                    onClick={this.props.onClose}
                    variant={this.props.theme.buttonVariant}
                    sx={{
                        '&:hover': {
                          border:"1px solid " + this.props.theme.buttonTextColor,
                          background:this.props.theme.buttonHoverColor
                        },       
                      marginRight: "5px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                      borderBottomLeftRadius: "0px",
                      textTransform: "none",
                      textAlign: "center",
                      color:this.props.theme.buttonTextColor,
                      border:"1px solid " + this.props.theme.buttonColor,
                      fontSize: {
                        xs: ".75rem",
                        sm: "1rem",
                        md: "1rem",
                        lg: "1rem",
                        xl: "1rem"
                      }                  
                    }}
                  >
                    Close
                  </Button>
                </React.Fragment>
                :
                <React.Fragment>
                <Button
                  onClick={this.props.onClose}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                      '&:hover': {
                        border:"1px solid " + this.props.theme.buttonTextColor,
                        background:this.props.theme.buttonHoverColor
                      },       
                    marginRight: "5px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderBottomLeftRadius: "25px",
                    textTransform: "none",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize: {
                      xs: ".75rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem"
                    }                  
                  }}
                >
                  Close
                </Button>
              </React.Fragment>
              
                }
            </Grid>
          </Grid>                    
            <Grid
              item
              sx={{
                background:"rgba(255,255,255,0)",
                width: { md: "100%", xs: "100%" },
                overflowY : "auto",            
                
                padding: this.state.requestShowing ? "0px" : "10px",
                height: {
                  xs: "88%",
                },
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {
              this.state.requestShowing ?
                this.getSendEmailTile()
              :
              <React.Fragment>

              <CommonTile
                uid={0}
                animationType={null}
                animationTime={null}
                tagBottomRightIcon={this.props.doNext ? <ArrowForwardIcon sx={{fontWeight:"bold"}}/> : null}
                tagBottomRightTxt={null}
                tagBottomRightPadding={"0px 20px 0px 20px"}
                tagBottomRightTxtColor={this.props.theme.bannerTextColor}
                tagBottomRightTxtSize={["2rem", "2rem", "2rem"]}
                tagBottomRightPaddingRight={"1%"}
                tagBottomRightPaddingBottom={"5%"}
                tagBottomRightTxtType={"bold"}
                tagBottomRightTxtAlign={"center"}
                tagBottomRightBgColor={this.props.theme.bannerColor}
                tagBottomRightBorder={"1px solid #F5F5F5"}
                tagBottomRightBorderRadius={["50px","50px","50px","50px"]}
                tagBottomRightCallback={this.nextSlide}

                tagBottomLeftIcon={this.props.doPrevious ? <ArrowBackIcon sx={{fontWeight:"bold"}}/> : null}
                tagBottomLeftTxt={null}
                tagBottomLeftPadding={"0px 20px 0px 20px"}
                tagBottomLeftTxtColor={this.props.theme.bannerTextColor}
                tagBottomLeftTxtSize={["2rem", "2rem", "2rem"]}
                tagBottomLeftPaddingLeft={"1%"}
                tagBottomLeftPaddingBottom={"5%"}
                tagBottomLeftTxtType={"bold"}
                tagBottomLeftTxtAlign={"center"}
                tagBottomLeftBgColor={this.props.theme.bannerColor}
                tagBottomLeftBorder={"1px solid #F5F5F5"}
                tagBottomLeftBorderRadius={["50px","50px","50px","50px"]}
                tagBottomLeftCallback={this.previousSlide}

                tagTopRightIcon={null}
                tagTopRightTxt={this.props.doCounter && this.props.listing && this.props.listing.Media && this.props.listing.Media.length > 0 ? " " + (this.state.slideIndex+1) + " of " + (this.props.listing.PhotosCount) + " " : null}
                tagTopRightPadding={"2px 5px 2px 5px"}
                tagTopRightTxtColor={this.props.theme.bannerTextColor}
                tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                tagTopRightPaddingRight={"2%"}
                tagTopRightPaddingTop={"2%"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={this.props.theme.bannerColor}
                tagTopRightBorder={"0px solid #333333"}
                tagTopRightBorderRadius={["10px","10px","10px","10px"]}
                tagTopRightCallback={null}


                topPadding={"0px"}
                topWidget={null}
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={this.getImageToDisplay()}
                topCallback={null}
                topTxtColor={"white"}
                topTxtSize={["12px", "12px", "32px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgbs(255,255,255,0.5)"}
                topHeight={["400px", "100%", "100%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["12px", "12px", "16px"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"5px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["16px", "16px", "16px"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#f0f0f0"}
                mdlBorder={"0px solid black"}
                mdlHeight={["0px", "0%", "0%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={null}
                btmTxtColor={"#333333"}
                btmTxtSize={["16px", "16px", "16px"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["0px", "0%", "0%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"0px solid black"}
                shadow={0}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              <br/>
                  {this.props.photos && this.props.photos.map((photo, index) => {
                    return (
                      <div>
                      <center>
                      <Image src={photo.MediaURL} bgColor="rgba(255,255,255,0)" sx={{borderTop:"10px solid " + this.props.theme.bannerColor}} fit="contain"/>                                    
                      </center>
                      
                      </div>
                    );
                  })}
                </React.Fragment>
  
            }
            </Grid>    
        </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default SlideShowDialog;
