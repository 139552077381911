import React, { Component } from 'react';
import { Button, MenuItem, Select, FormControl, Checkbox, InputLabel, Grid, ListItemText } from '@mui/material';

class PropertyFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedLocations:[],
        selectedStyles: [],
        bedrooms: [],
        bathrooms: [],
        priceRange: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedTowns !== this.props.selectedTowns) {
      this.setState({
        selectedLocations: this.props.selectedTowns
      })
    }
}

  findDifferingItem = (array1, array2) => {

    const mergedArray = array1.concat(array2);

    const differingItems = mergedArray.filter(item => {
      // Keep items that are not found in both arrays
      return !(array1.includes(item) && array2.includes(item));
    });

    if (differingItems.length === 1) {
      return differingItems[0];
    } else {
      // Handle the case where there is no differing item or more than one differing item
      return null;
    }
  }

  handleLocationsChange = (event) => {
    // what has been added or removed...
    let item = this.findDifferingItem(this.state.selectedLocations, event.target.value)
    this.setState({ selectedLocations: event.target.value }, () => this.props.updateSelectedTowns(item) )
  };

  handleStylesChange = (event) => {
    // what has been added or removed...
    this.setState({ selectedStyles: event.target.value })
  };

  handleSelectChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {

    const { selectedLocations, selectedStyles } = this.state;

    // TODO
    // Phoenix, Fulton, Van Buren
    const locations = [
      "Baldwinsville",
      "Brewerton",
      "Camillus",
      "Cicero",
      "Clay",
      "DeWitt",
      "Eldbridge",
      "Fabius",
      "Fayetteville",
      "Jamesville",
      "Lafayette",
      "Liverpool",
      "Manlius",
      "Minoa",
      "Onondaga",
      "Pompey",
      "Skaneateles",
      "Spafford",
      "Syracuse",
      "Tully"];

    const styles = [
      "Two Story",
      "Ranch",
      "Condo",
      "Townhouse"
    ]  
    return (
      <Grid container spacing={0} sx={{width:"100%", padding:"10px 0px 0px 0px"}}>
        <Grid item xs={12} sm={3} sx={{
          width:"100%",
          paddingTop:{
            xs:"10px", 
            sm:"2px", 
            md:"0px", 
            lg:"5px", 
            xl:"5px"
          }

          }}>

          <FormControl sx={{
            width:{
              xs:"100%", 
              sm:"100%", 
              md:"100%", 
              lg:"100%", 
              xl:"100%" 
            },
            color:this.props.theme.bannerTextColor,
            padding:"5px 0px 0px 0px"}}>

            <InputLabel
              sx={{
                '&.Mui-focused': {
                  color: this.props.theme.bannerColor,
                },
                color:this.props.theme.bannerColor
              }}            
              id="location-select-label">Location</InputLabel>
            <Select
              size="small"
              labelId="location-select-label"
              id="location-select"
              multiple          
              sx={{
                color:this.props.theme.bannerColor,
                fontSize:{
                  xs:"1rem", 
                  sm:"1rem", 
                  md:"1rem", 
                  lg:"1rem", 
                  xl:"1rem"
                },
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "15px",

                
            }}
              value={selectedLocations}
              onChange={this.handleLocationsChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {locations.map((location) => (
                <MenuItem key={location} value={location}>
                  <Checkbox checked={selectedLocations.indexOf(location) > -1} />
                  <ListItemText primary={location} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} sx={{
          width:"100%",
          paddingTop:{
            xs:"10px", 
            sm:"2px", 
            md:"0px", 
            lg:"5px", 
            xl:"5px"
          }
          }}>
        <FormControl sx={{
                width:{
                xs:"100%", 
                },
                color:this.props.theme.bannerColor,
                padding:"5px 0px 0px 0px"}}>
            <InputLabel
            sx={{
                '&.Mui-focused': {
                  color: this.props.theme.bannerColor,
                },
                color:this.props.theme.bannerColor
              }}  
            >Style</InputLabel>
            <Select
              size="small"
              name="style"
              multiple    
              sx={{ borderRadius: '0',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border:"1px solid " + this.props.theme.bannerColor
              }            
                }}
                value={selectedStyles}
                onChange={this.handleStylesChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {styles.map((style) => (
                  <MenuItem key={style} value={style}>
                    <Checkbox checked={selectedStyles.indexOf(style) > -1} />
                    <ListItemText primary={style} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {/*
        <Grid item xs={12} sm={3} sx={{width:"100%"}}>
        <FormControl sx={{
                width:{
                xs:"100%", 
                },
                color:this.props.theme.bannerColor,
                padding:"5px 0px 0px 0px"}}>
            <InputLabel
            sx={{
                '&.Mui-focused': {
                  color: this.props.theme.bannerColor,
                },
                color:this.props.theme.bannerColor
              }}  
            >Bedrooms</InputLabel>
            <Select
              size="small"
              value={this.state.bedrooms}
              onChange={this.handleSelectChange}
              name="bedrooms"
              sx={{ borderRadius: '0',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border:"1px solid " + this.props.theme.bannerColor
              } }}
            >
              <MenuItem value={1}>1-2</MenuItem>
              <MenuItem value={2}>2-3</MenuItem>
              <MenuItem value={3}>3-4</MenuItem>
              <MenuItem value={4}>4+</MenuItem>
            </Select>
          </FormControl>
        </Grid>
            */}
            {/*      
        <Grid item xs={12} sm={3} sx={{width:"100%"}}>
        <FormControl sx={{
                width:{
                xs:"100%", 
                },
                color:this.props.theme.bannerColor,
                padding:"5px 0px 0px 0px"}}>
            <InputLabel
            sx={{
                '&.Mui-focused': {
                  color: this.props.theme.bannerColor,
                },
                color:this.props.theme.bannerColor
              }}  
            >Bathrooms</InputLabel>
            <Select
              size="small"
              value={this.state.bathrooms}
              onChange={this.handleSelectChange}
              name="bathrooms"
              sx={{ borderRadius: '0',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border:"1px solid " + this.props.theme.bannerColor
              } }}
            >
              <MenuItem value={1}>1-2</MenuItem>
              <MenuItem value={2}>2-3</MenuItem>
              <MenuItem value={3}>3-4</MenuItem>
              <MenuItem value={4}>4+</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        */}
        <Grid item xs={12} sm={3} sx={{
          width:"100%",
          paddingTop:{
            xs:"10px", 
            sm:"2px", 
            md:"0px", 
            lg:"5px", 
            xl:"5px"
          }
          }}>
        <FormControl sx={{
                width:{
                xs:"100%", 
                },
                color:this.props.theme.bannerColor,
                padding:"5px 0px 0px 0px"}}>
            <InputLabel
            sx={{
                '&.Mui-focused': {
                  color: this.props.theme.bannerColor,
                },
                color:this.props.theme.bannerColor
              }}  
            >Price Range</InputLabel>
            <Select
              size="small"
              value={this.state.priceRange}
              onChange={this.handleSelectChange}
              name="priceRange"
              sx={{ borderRadius: '0',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border:"1px solid " + this.props.theme.bannerColor
              }, }}
            >
              <MenuItem value="100-200">{"$100K - $200K"}</MenuItem>
              <MenuItem value="200-300">{"$200K - $300K"}</MenuItem>
              <MenuItem value="300 - 500">{"$300K - $500K"}</MenuItem>
              <MenuItem value="500 - 700">{"$500K - $700K"}</MenuItem>
              <MenuItem value="700">{"$700K+"}</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} sx={{
          width:"100%",
          paddingTop:{
            xs:"10px", 
            sm:"2px", 
            md:"5px", 
            lg:"10px", 
            xl:"10px"
          }
          }}>
            <Button
                
                onClick={this.sendContactCard}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid #AAAAAA",
                    background:this.props.theme.bannerColor,      
                  },
                  textTransform: "none",
                  width:{
                    xs:"100%",
                    sm:"100%",
                    md:"100%",
                    lg:"100%",
                    xl:"100%",
                  }, 
                  height: "100%",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  color:this.props.theme.bannerTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,      
                  background:this.props.theme.bannerColor,      
                  padding:{
                    xs:"10px",
                    md:"5px",
                    lg:"0px"  
                  },
                  fontWeight:"normal"
                
                }}
                size="small"
              >
                Find Homes Like These
              </Button>
        </Grid>

      </Grid>
    );
  }
}

export default PropertyFilter;
