import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CommonTile from "./CommonTile";
import "./MapMarker.css";
import ReactDOM from 'react-dom';
import Image from 'mui-image'
import PlayIcon from '@mui/icons-material/PlayCircle';
import Button from "@mui/material/Button";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];
    this.map = null;
    this.pan_zoom_inprogress = false
  }

  componentDidMount() {
      this.initMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locations !== this.props.locations || prevProps.favorites !== this.props.favorites) {
      this.clearMarkers();
      this.addMarkers();
      this.getMapExtents()
    }else if(prevProps.gotoHome !== this.props.gotoHome){
      if(this.map){
        this.pan_zoom_inprogress = true
        var newLatlng = new window.google.maps.LatLng(this.props.gotoHome.lat, this.props.gotoHome.lng);
        this.zoomOutOfLocation(this.map, newLatlng)
        //this.map.panTo(newLatlng);
        //this.map.setZoom(18);
        this.pan_zoom_inprogress = false
      }
    }
  }

  getMapExtents() {

    if(this.map){
      var bounds = this.map.getBounds();
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      var minLatitude = sw.lat();
      var minLongitude = sw.lng();
      var maxLatitude = ne.lat();
      var maxLongitude = ne.lng();
      
      
    }
  }


  zoomOutOfLocation = (map, location) => {
    const currentZoom = map.getZoom();
    const targetZoomOut = 15;
    const zoomStep = .1;
    let zoomLevel = currentZoom;
  
    // Zoom out to targetZoomOut
    const zoomOutInterval = setInterval(() => {
      if (zoomLevel <= targetZoomOut) {
        clearInterval(zoomOutInterval);
        this.panToNewLocation(map, location);
      } else {
        zoomLevel -= zoomStep;
        map.setZoom(zoomLevel);
        window.google.maps.event.trigger(map, 'resize');
      }
    }, 50);
  };
  
  panToNewLocation = (map, location) => {
    
    if (!map) {
      return;
    }
    const newLatlng = location;
    const latStep = (newLatlng.lat() - map.getCenter().lat()) / 10;
    const lngStep = (newLatlng.lng() - map.getCenter().lng()) / 10;
    
    let i = 0;
    const interval = setInterval(() => {
      if (i < 10) {
        const newLat = map.getCenter().lat() + latStep;
        const newLng = map.getCenter().lng() + lngStep;
        const newLatlng = new window.google.maps.LatLng(newLat, newLng);
        map.panTo(newLatlng);
        window.google.maps.event.trigger(map, 'resize');
        i++;
      } else {
        clearInterval(interval);
        this.zoomToNewLocation(map, location, 18)
      }
    }, 50);
  };

  zoomToNewLocation = (map, location, targetZoom) => {

    // Pan to the new location
    const targetLocation = location;
  
    const currentZoom = map.getZoom();
    const zoomStep = .1
    let zoomLevel = currentZoom;
  
    // Zoom in to targetZoom
    const zoomInInterval = setInterval(() => {
      if (zoomLevel >= targetZoom) {
        clearInterval(zoomInInterval);
      } else {
        zoomLevel += zoomStep;
        map.setZoom(zoomLevel);
        window.google.maps.event.trigger(map, 'resize');
      }
    }, 50);
  };


  initMap() {
    //const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const apiKey = "AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8";
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&libraries=places`;
    script.defer = true;
    script.async = true;
    script.onerror = () => {
      console.error("Failed to load Google Maps API");
    };

    window.initMap = () => {

      // remove points of interest
      this.map = new window.google.maps.Map(this.mapRef.current, {
        center: {
          "lat": 43.0515,
          "lng": -76.1474},
        zoom: 10,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
      });

      this.addMarkers();


      this.map.addListener('center_changed', () => {
        var bounds = this.map.getBounds();
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        var minLatitude = sw.lat();
        var minLongitude = sw.lng();
        var maxLatitude = ne.lat();
        var maxLongitude = ne.lng();

        this.props.setMapExtents([minLatitude, minLongitude, maxLatitude, maxLongitude])

        // requerey the MLS server... makes sure we are not panning/zooming due to a click on a home.
        // debouce as needed... 


      });
      //this.map.addListener('center_changed', this.getMapExtents);

    };

    document.head.appendChild(script);
  }

  
  displayListingReport = (listingid) => {
    this.props.displayListingReport(listingid)
  }
  
 
  addMarkers() {

    const locations = this.props.locations;

    
    let contentString = '<div>' +
    '<p><strong>Address:</strong> 123 Main St.</p>' +
    '<p><strong>Square Footage:</strong> 1,000 sq. ft.</p>' +
    '</div>';

      locations.forEach((location) => {

        var infoWindow = new window.google.maps.InfoWindow();

        infoWindow.setOptions({
          maxWidth: "250",
          boxStyle: {
            // CSS properties for the info window
            width: "250px", // Set width if desired
            margin: "20px" // Set margins as needed
        }
        });
        const container = document.createElement('div');


        let tile = location
        // Render your React component into the container element
        ReactDOM.render(

          <CommonTile
                  uid={tile.ListingId}
                  animationType={null}
                  animationTime={null}
                  tagTopRightIcon={ 
                    this.props.favorites.some(obj => obj.ListingId === tile.ListingId) ? 
                    <React.Fragment>
                      <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="contain" height={30} width={50} duration={200}/>              
                      </React.Fragment>              
                      :
                      <React.Fragment>
                      <Image src={"logos/loveit-gray.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="contain" height={30} width={50} duration={200}/>              
                      </React.Fragment>                           
                  }
                  tagTopRightTxt={null}
                  tagTopRightPadding={"2px 2px 2px 2px"}
                  tagTopRightTxtColor={"black"}
                  tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                  tagTopRightPaddingRight={"2px"}
                  tagTopRightPaddingTop={"2px"}
                  tagTopRightTxtType={"bold"}
                  tagTopRightTxtAlign={"center"}
                  tagTopRightBgColor={"rgba(255,255,255,0)"}
                  tagTopRightBorder={"0px solid white"}
                  tagTopRightBorderRadius={["0px","0px","0px","0px"]}
                  tagTopRightCallback={null}
                  tagBottomRightIcon={
                  tile.ListAgentMlsId === "WILLSDO" ? <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={15} width={50} duration={200}/> : null
            }
            tagBottomRightTxt={
              null
            }
                  tagBottomRightPadding={"5px 10px 5px 10px"}
                  tagBottomRightTxtColor={"black"}
                  tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
                  tagBottomRightPaddingRight={"0px"}
                  tagBottomRightPaddingBottom={"0px"}
                  tagBottomRightTxtType={"bold"}
                  tagBottomRightTxtAlign={"center"}
                  tagBottomRightBgColor={"white"}
                  tagBottomRightBorder={"0px solid black"}
                  tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
                  tagBottomRightCallback={null}
                  tagTopLeftIcon={null}
                  tagTopLeftTxt={
                    tile.status
                  }
                  tagTopLeftPadding={"5px 10px 5px 10px"}
                  tagTopLeftTxtColor={tile.status === "Available" ? this.props.theme.bannerTextColor : "black"}
                  tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                  tagTopLeftPaddingLeft={"0px"}
                  tagTopLeftPaddingTop={"5px"}
                  tagTopLeftTxtType={"bold"}
                  tagTopLeftTxtAlign={"center"}
                  tagTopLeftBgColor={tile.status === "Available" ? "rgba(12,101,58,.95)" : "rgba(255, 165, 0,.95)"}
                  tagTopLeftBorder={"0px solid black"}
                  tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}

                  tagBottomLeftIcon={null}
                  tagBottomLeftTxt={tile.videos && tile.videos.length > 0 ?                 
                    <Button variant="filled" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".75rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
                      Video
                    </Button>                
                    : null
                  }
                  tagBottomLeftPadding={"0px"}
                  tagBottomLeftTxtColor={this.props.theme.bannerColor}
                  tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
                  tagBottomLeftPaddingLeft={"0px"}
                  tagBottomLeftPaddingBottom={"5px"}
                  tagBottomLeftTxtType={"bold"}
                  tagBottomLeftTxtAlign={"center"}
                  tagBottomLeftBgColor={"rgba(255,255,255,1)"}
                  tagBottomLeftBorder={"0px solid black"}
                  tagBottomLeftBorderRadius={["0px", "10px", "10px", "0px"]}
                  tagBottomLeftCallback={this.props.displayListingReport}

                  topCallback={this.props.displayListingReport}
                  topPadding={"0px"}
                  topPicture={null}
                  topPictureAlign={"center"}
                  topPictureSize={"contain"}
                  topTxt={null}
                  topTxtColor={"white"}
                  topTxtSize={["1.5rem", ".75rem", ".75rem"]}
                  topTxtType={"bold"}
                  topTxtAlign={"left"}
                  topVerticalAlign={"flex-end"}
                  topHorizontalAlign={"flex-start"}
                  topBgColor={"rgba(255,255,255,0)"}
                  topHeight={["120px", "120px", tile.ListAgentMlsId === "WILLSDO" ? "175px" : "120px"]}
                  topGraphType={null}
                  topGraphData={null}
                  topWidget={
                    tile.Media && tile.Media.length > 0 ?
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundImage: 'url(' + tile.Media[0]["MediaURL"] + ')',
                          backgroundPosition: "50% 50%",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
  
                          height: {
                            xs: "100%",
                          },
                          width: {
                            xs: "100%",
                          },
                          
                          borderTop:{
                            xs:"1px solid #AAAAAA",
                            sm: "0px solid #333333"
                          },
                        }}
                      >  

                      </Grid>
                    :
                    null
                  }
                  mdlTagIcon={null}
                  mdlTagTxt={null}
                  mdlTagPadding={"0px"}
                  mdlTagTxtColor={"black"}
                  mdlTagTxtSize={["1.5rem", "1.25rem", "1.25rem"]}
                  mdlTagPaddingRight={"4px"}
                  mdlTagPaddingTop={"2px"}
                  mdlTagTxtType={"bold"}
                  mdlTagTxtAlign={"center"}
                  mdlTagBgColor={"rgba(255,255,255,0)"}
                  mdlTagBorder={"0px solid black"}
                  mdlTagBorderRadius={"5px"}                
                  mdlPadding={"0px"}
                  mdlPicture={null}
                  mdlPictureAlign={null}
                  mdlTxtLineHeight={1.5}
                  mdlTxt={null}
                  mdlWidget={
                    
                    <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      background: "rgba(255,255,255,0)",
                      height:"100%",
                      width:"100%",     
                      border: "0px solid #AAAAAA",
                    }}
                  >
                      <Grid
                        item
                        sx={{
                          background: "rgba(255,255,255,0)",
                          overflowX:"hidden",
                          textAlign:"left",
                          fontSize: {
                            xs:"1rem",
                            sm:"1rem",
                            md:"1rem",
                            lg:"1rem",
                            xl:"1rem",
                          },
                          fontWeight:"normal",
                        }}
                        xs={10}
                      >

                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            background: "rgba(255,255,255,0)",
                            height:"100%",
                            width:"100%",     
                            border: "0px solid #AAAAAA",
                          }}
                        >
                          <Grid
                            item
                            sx={{
                              overflowX:"hidden",
                              textAlign:"left",
                              fontSize: {
                                xs:".75rem",
                                sm:".7rem",
                                md:".7rem",
                                lg:".7rem",
                                xl:".7rem"
                              },
                              fontWeight:"normal",
                            }}
                            xs={12}
                          >
                              <Box sx={{
                                fontSize:{
                                xs:".8rem",
                                sm: "1rem",
                                md:"1rem",
                                lg:"1rem",
                                xl:"1rem",
                                },
                                paddingLeft: {
                                  xs:"0px",
                                  sm:"0px",
                                  md:tile.ListAgentMlsId === "WILLSDO" ? "0px" : "0px",
                                  lg:"0px",
                                  xl:"0px",
                                },
    
                                fontWeight:"bold", display:"inline"}} >
                            {"$" + tile.ListPrice ? tile.ListPrice.toLocaleString() : "--"}
                            </Box>
                              &nbsp;|&nbsp;
                              {tile.LivingArea ? tile.LivingArea.toLocaleString() : "--"} sf &nbsp;|&nbsp;
                              {tile.BedroomsTotal} bd&nbsp;|&nbsp;
                              {tile.BathroomsFull}.{tile.BathroomsHalf > 0 ? "5":"0"} ba
                          </Grid>                           

                          <Grid
                            item
                            sx={{
                              overflowX:"hidden",
                              overflowY:"hidden",
                              textAlign:"left",
                              paddingLeft: {
                                xs:"0px",
                                sm:"0px",
                                md:tile.ListAgentMlsId === "WILLSDO" ? "0px" : "0px",
                                lg:"0px",
                                xl:"0px",
                              },
                              fontSize: {
                                xs:".7rem",
                                sm: "8rem",
                                md:".6rem",
                                lg:".7rem",
                                xl:".7rem",
                              },
                              lineHeight:"1"
                            }}
                            xs={12}
                          >
                          {tile.UnparsedAddress + ", " + tile.PostalCity}
                        </Grid>                 
                      </Grid>                 
                      </Grid>                 

                      <Grid
                        item
                        sx={{
                          overflowX:"hidden",
                          textAlign:"left",
                          padding:"0px",
                          fontSize: {
                            xs:"1rem",
                            sm:"1rem",
                            md:".8rem",
                            lg:".8rem",
                            xl:".8rem",
                          },
                          fontWeight:"normal",
                          height:"100%",
                          width:"100%",     
                        }}
                        xs={2}
                      >

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="right"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                          paddingBottom:
                          {
                            xl:"0px",
                          }

                        }}
                      >
                        {tile.ListAgentMlsId === "WILLSDO"?
                        <img alt="image" src="profilepics/profile-rounded.png" height="55px"/>
                          :null}
                      </Grid>

                      </Grid>
                  </Grid>            
                  
                    }
                  mdlTxtColor={"black"}
                  mdlTxtSize={["1rem", "1rem", "1rem"]}
                  mdlTxtType={"normal"}
                  mdlTxtAlign={"center"}
                  mdlHorizontalAlign={"center"}
                  mdlVerticalAlign={"flex-end"}
                  mdlBgColor={tile.ListAgentMlsId === "WILLSDO" ? "white" : "#FFFFFF" }
                  mdlBorder={tile.ListAgentMlsId === "WILLSDO" ? "1px solid white" : "0px solid black"}
                  mdlHeight={["55px", tile.ListAgentMlsId === "WILLSDO" ? "65px" : "50px", tile.ListAgentMlsId === "WILLSDO" ? "65px" : "50px"]}
                  mdlGraphType={null}
                  mdlGraphData={null}                
                  btmPadding={"0px 0px 2px 0px"}
                  btmPicture={null}
                  btmPictureAlign={null}
                  btmTxt={ 
                    <center>
                      <div style={{fontSize:".4rem"}}>
                      <img alt="image" src="howardhanna/idx-logo-sm.png" height="10px"/>  MLS:  {tile.ListingId} - Listing By {tile.ListOfficeName}
                      </div>
                    </center>      
                  }
                  btmTxtColor={"#333333"}
                  btmTxtSize={[".75rem", ".75rem", ".75rem"]}
                  btmTxtType={"normal"}
                  btmTxtAlign={"left"}
                  btmHorizontalAlign={"center"}
                  btmVerticalAlign={"center"}
                  btmBgColor={tile.ListAgentMlsId === "WILLSDO" ? "white" : "#FFFFFF" }
                  btmBorder={"0px solid black"}
                  btmHeight={["20px", tile.ListAgentMlsId === "WILLSDO" ? "15px" : "15px", tile.ListAgentMlsId === "WILLSDO" ? "15px" : "15px"]}
                  btmGraphType={null}
                  btmGraphData={null}
                  border={tile.ListAgentMlsId === "WILLSDO" ? "0px solid #AAAAAA" : "0px solid #AAAAAA" }
                  shadow={0}
                  downloads={""}
                  linksTo={""}
                  growTime={4000}
                  padding={"0px"}
                  borderRadius={null}
                  bgcolor={"rgba(255,255,255,1)"}
                />
          , container);
        
        // Set the content of the InfoWindow to be the container element
        infoWindow.setContent(container);      

        

        if(this.props.favorites && this.props.favorites.some(obj => obj.ListingId === location.ListingId)){

            let customIcon = new window.google.maps.MarkerImage(
              'logos/loveit-selected.png', // path to your custom icon image file
              null, // size of the icon (null = use natural size)
              null, // origin of the icon (null = use default)
              null, // anchor point of the icon (null = use default)
              new window.google.maps.Size(60, 40) // scaled size of the icon  
            );

            if(location.agent === "Donna Wills"){
              customIcon = new window.google.maps.MarkerImage(
                'logos/loveit-selected-donnawills.png', // path to your custom icon image file
                null, // size of the icon (null = use natural size)
                null, // origin of the icon (null = use default)
                null, // anchor point of the icon (null = use default)
                new window.google.maps.Size(60, 40) // scaled size of the icon
              );
            }

            const marker = new window.google.maps.Marker({
              position: {
                "lat": location.Latitude,
                "lng": location.Longitude
              },
              map: this.map,
              icon: customIcon,
              label: {
                text: "$" + parseInt(location.ListPrice/1000) +"k",
              
                color: '#FFF',
                fontSize: location.agent === "Donna Wills" ? '12.5px' : '12.5px',
                fontWeight: location.agent === "Donna Wills" ? 'normal' : 'normal',
                fontFamily: 'custom-label',
                paddingTop:location.agent === "Donna Wills" ? '10px' : '0px',
                origin: location.agent === "Donna Wills" ? new window.google.maps.Point(0, 55) : new window.google.maps.Point(0, 20),
              },
            });

            marker.addListener('click', function() {
              infoWindow.open(this.map, marker);
            });

            this.markers.push(marker);

        }else{
          
          let customIcon = new window.google.maps.MarkerImage(
            'logos/loveit-available.png', // path to your custom icon image file
            null, // size of the icon (null = use natural size)
            null, // origin of the icon (null = use default)
            null, // anchor point of the icon (null = use default)
            new window.google.maps.Size(40, 30) // scaled size of the icon
          );

          
          if(location.agent === "Donna Wills"){
            customIcon = new window.google.maps.MarkerImage(
              'logos/loveit-available-donnawills.png', // path to your custom icon image file
              null, // size of the icon (null = use natural size)
              null, // origin of the icon (null = use default)
              null, // anchor point of the icon (null = use default)
              new window.google.maps.Size(60, 40) // scaled size of the icon
            );
          }
          

          const marker = new window.google.maps.Marker({
            position: {
              "lat": location.Latitude,
              "lng": location.Longitude
            },
            map: this.map,
            icon: customIcon,
            label: {
              text: parseInt(location.ListPrice/1000) < 1000 ? "$"+parseInt(location.ListPrice/1000) +"k" :  "$"+(location.ListPrice/1000000).toFixed(2) +"M",
              color: '#F5F5F5',
              fontSize: location.agent === "Donna Wills" ? '12.5px' : '10px',
              fontWeight: location.agent === "Donna Wills" ? 'normal' : 'normal',
              paddingTop:location.agent === "Donna Wills" ? "18px" : "10px",
              fontFamily: 'custom-label',   
              fontWeight: 'normal',
              origin: new window.google.maps.Point(0, 32)
          
            },
          });

          marker.addListener('click', function() {
            infoWindow.open(this.map, marker);
          });

          this.markers.push(marker);

        }
        


      });
  
  }

  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });

    this.markers = [];
  }

  render() {
    return (
    
      <Box ref={this.mapRef} sx={{ width: "100%", height:{xs: "70vh", md :"100%"} }}>

        
      </Box>
      
      
      )




  }
}
export default MapComponent;

/*
{this.map ?
  <MapMarker
    position={{ lat: this.props.locations[0].position.lat, lng: this.props.locations[0].position.lng }}
    map={this.map}
    title="Marker 1"
    icon="https://maps.google.com/mapfiles/kml/shapes/info-i_maps.png"
    width={32}
    height={32}
  />
  :null}
  */
