import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { Fade } from '@mui/material';
import Image from 'mui-image';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

/*

web logo

listing covered
best in cny
let us guide you

calculat mortagea
explor eservices

comperables
home value report

buyers guide
sellers guide

search listings
home value index
see whats nearby
download listing reprot
save your favorites
download favorites reprot

schedule a showing

featured listing
featured listing
featured listing
featured listing

*/

class SlideShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
      activeIndex: 0,
      showControls: false,
      images: [
        {
          "image": "welcome.png",
          "footer1": "With You Every Step Of The Way"
        },
        {
          "image": "services-all.png",
          "footer1": "My Comprehensive Set Of Services"
        },
        {
          "image": "services-home-valuation.png",
          "footer1": "I Know The Local Market"
        },
        {
          "image": "service-mls-marketing.png",
          "footer1": "I've Got Your Listing Covered"
        },
        {
          "image": "services-staging-phtography.png",
          "footer1": "I Can Do A Pop Of Decor & Full Staging"
        },
        {
          "image": "services-open-house.png",
          "footer1": "I'll Make Your Open House Amazing"
        },
        {
          "image": "services-contracts.png",
          "footer1": "My Priority Is A Best Price Strategy"
        },
        {
          "image": "services-closing.png",
          "footer1": "From Start To Finish We'll Do It Together."
        },
        {
          "image": "services-full-report.png",
          "footer1": "Interested In A Presentation For Your Home",
          "footer2": "Call Or Text Me Today",
        },
        {
          "image": "featured-listing.png",
          "footer1": "Get All My Services &",
          "footer2": "Your Listing Featured Here",
        },
      ],
      images: [
        {
          "header":"Walking Trails",
          "image": "general/gray-house-street.png",
        },
        {
          "header":"Oberon Pool",
          "image": "general/featured-listing2.png",
        },
        {
          "header":"Kari Horniday Park",
          "image": "general/gray-house-street.png",
        },
        {
          "header":"Kayak & Boat Launch",
          "image": "general/featured-listing2.png",
        },
        {
          "header":"Aspen House",
          "image": "general/gray-house-street.png",
        },
        {
          "header":"Drakes Landing Park",
          "image": "general/featured-listing2.png",
        },
        {
          "header":"Lake Oberon",
          "image": "general/gray-house-street.png",
        },
        {
          "header":"Storage",
          "image": "general/featured-listing2.png",
        },
        {
          "header":"Summer Camp",
          "image": "general/gray-house-street.png",
        },
        {
          "header":"Founders Day",
          "image": "general/featured-listing2.png",
        },
        {
          "header":"Mothers Day Grage Sale",
          "image": "general/gray-house-street.png",
        },
      ],
    };
  }

  componentDidMount() {
      this.timer = setInterval(this.setNextSlide, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setNextSlide = () => {
    const { activeIndex, images } = this.state;
    if(this.state.playing){
      this.setState({
        activeIndex: (activeIndex + 1) % images.length,
      });
    }
  };


  nextSlide = () => {
    const { activeIndex, images } = this.state;
    this.setState({
      activeIndex: (activeIndex + 1) % images.length,
    });
  };

  prevSlide = () => {
    const { activeIndex, images } = this.state;
    this.setState({
      activeIndex: activeIndex === 0 ? images.length -1 : (activeIndex - 1) % images.length,
    });
  };

  stopSlide = () => {
    this.setState({
      playing: false,
    });
  };

  playSlide = () => {
    this.setState({
      playing: true,
    });
  };


  render() {
    const { activeIndex, images } = this.state;

    return (
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
            width: {
              xs: "100%",
            },
            position: "relative",
            border: "0px solid #333333",
          }}
        >
        {images.map((image, index) => (
          <Fade
            key={index}
            in={activeIndex === index}
            timeout={2000}
            unmountOnExit={this.state.playing ? true : false}
          >
             <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: {
                    xs: "100%",
                  },
                  width: {
                    xs: "100%",
                  },
                  position: "absolute",
                  bottom:"0",
                  border: "0px solid red",
                }}
              >
             <Grid
              item
              xs={12}
              sx={{
                height: {
                  xl: "10%",
                  lg: "10%",
                  md: "10%",
                  sm: "10%",
                },
                border: image["header"] ? "0px solid #555555" : "0px solid #555555",
                fontWeight: "bold",
                padding: "5px",
                background: image["header"] ? "rgba(240,240,240,0)" : "rgba(240,240,240,0)",
                borderRadius:"20px",
                lineSpacing:"1",
                fontSize: {
                  xs:".85rem",
                  sm:".85rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                }            
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: {
                      xs: "100%",
                    },
                    width: {
                      xs: "100%",
                    },
                    border: "0px solid red",
                  }}
                >
                  {image["header"]}                
                </Grid>
              </Grid>                
            <Grid
              item
              xs={12}
              sx={{
                height: {
                  xl: (!image["footer1"] && !image["footer2"]) ? "80%" : image["footer2"] ? "65%" : "70%",
                  lg: (!image["footer1"] && !image["footer2"]) ? "80%" : image["footer2"] ? "65%" : "70%",
                  md: (!image["footer1"] && !image["footer2"]) ? "80%" : image["footer2"] ? "65%" : "70%",
                  sm: (!image["footer1"] && !image["footer2"]) ? "75%" : image["footer2"] ? "60%" : "70%",
                  xs: (!image["footer1"] && !image["footer2"]) ? "70%" : image["footer2"] ? "60%" : "70%",
                },
                border: "0px solid blue",
              }}
              >
                <Image src={image["image"]} fit="contain" duration={500} />
                {/*<img alt="image" src={image["image"]} alt="Slide" style={{ width: '80%', height: '80%'}} />*/}
                </Grid> 
             { image["footer1"] || image["footer2"] ?                 
             <Grid
              item
              xs={12}
              sx={{
                height: {
                  xl: image["footer2"] ? "15%" : "10%",
                  lg: image["footer2"] ? "15%" : "10%",
                  md: image["footer2"] ? "15%" : "10%",
                  sm: image["footer2"] ? "15%" : "10%",
                },
                border: "1px solid #555555",
                fontWeight: "bold",
                padding: image["footer2"] ? "2px" : "10px",
                background:"rgba(240,240,240,1)",
                borderRadius:"20px",
                lineHeight:"1.25",
                fontSize: {
                  xs:".85rem",
                  sm:".85rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                }                    
              }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: {
                      xs: "100%",
                    },
                    width: {
                      xs: "100%",
                    },
                    border: "0px solid red",
                  }}
                >
                  {image["footer1"]}                
                  {image["footer2"] ? <br/> : null}
                  {image["footer2"] ? image["footer2"] : null}
              </Grid>

            </Grid>                
              :null}

            {this.state.showControls ?
            <React.Fragment>
            <Grid
                  item
                  xs={5}
                  sx={{
                    height: {
                      xl: "10%",
                      lg: "10%",
                      md: "10%",
                      sm: "10%",
                      xs: "10%",
                    },
                    border: "0px solid green",
                    fontWeight: "bold",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"15px",
                      lg:"10px",
                      xl:"10px"
                    },
                    background:"rgba(240,240,240,0)",
                    fontSize: {
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }                    
                      }}
                  onClick = {() => { this.prevSlide()}}
                  >
                     Prev
              </Grid>                


                <Grid
                  item
                  xs={2}
                  sx={{
                    height: {
                      xl: "10%",
                      lg: "10%",
                      md: "10%",
                      sm: "10%",
                      xs: "10%",
                    },
                    border: "0px solid green",
                    fontWeight: "bold",
                    paddingBottom: "10px",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                      lg:"10px",
                      xl:"10px"
                    },
                    background:"rgba(240,240,240,0)",
                  }}
                  >

                    {this.state.playing ?
                      <PauseIcon onClick = {() => { this.stopSlide()}} sx={{fontSize:
                        {
                          xs:"24px",
                          sm:"24px",
                          md:"32px",
                          lg:"32px",
                          xl:"32px"
                        }
                      }}/>
                      :
                      <PlayIcon  onClick = {() => { this.playSlide()}} sx={{fontSize:
                        {
                        xs:"24px",
                        sm:"24px",
                        md:"32px",
                        lg:"32px",
                        xl:"32px"
                        }
                        }}/>
                    }
              </Grid>                

              
              <Grid
                  item
                  xs={5}
                  sx={{
                    height: {
                      xl: "10%",
                      lg: "10%",
                      md: "10%",
                      sm: "10%",
                      xs: "10%",
                    },
                    border: "0px solid green",
                    fontWeight: "bold",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"15px",
                      lg:"10px",
                      xl:"10px"
                    },
                    background:"rgba(240,240,240,0)",
                    fontSize:{
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem"
                    }
                }}
                  onClick = {() => { this.nextSlide()}}
                  >
                     Next
              </Grid>    
              </React.Fragment>            
              :null}
             </Grid>
         </Fade>
          ))}


       </Grid>
    );
  }
}

export default SlideShow;
