import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Contact from './Contact'
import Slider from '@mui/material/Slider';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Image from 'mui-image'
import HelpIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import AlertDialog from './AlertDialog'
import Footer from './Footer'

import _ from "lodash";


class RadissonAmmenities extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      mortgagePayment: 0,
      selectedFinancing: 250000,
      selectedPercentage: 5.5,
      selectedTerm:15,
      showContact: false,
      pictureHeight: "100%",
      pictureWidth: "60%",

      alertDialogOpen:false,      

      propertyTaxes: 500,
      pmi: 100,
      insurance: 100,
      monthlyPayment: 100,

      inProgress:false,

      selectedEntry:  1,
      pictureHeight: "100%",
      pictureWidth: "60%",
      communities:[
        "Walking Trails",
        "Operon Pool",
        "Kari Horniday Park",
        "Kayak & Boat Loaunch",
        "Aspen House",
        "Drakes Landing Park",
        "Lake Oberon",
        "Storage",
        "Summer Camp",
        "Founders Day",
        "Mothers Day Sale",
      ],
      //"Special Events (MOthers Day Garagae Sales, Symporya, Christmas ligths conttes, pumpkin carving, halloween)",

      MyServicesCommunitiesTiles: [
        {
          id: "1",
          image: "towns/onondaga-baldwinsville-lysander-shadow.png",
          name: "Walking Trails",
          fullSize: true,
          summary:
            "A charming village/town setting situated along the banks of the Seneca River. The village is known for its picturesque setting and small-town atmosphere. With a rich history dating back to the early 19th century.  Baldwinsville offers a blend of historic architecture, scenic waterfront views, and a vibrant community.",
          download: "baldwinsville-lysander",
        },
        {
          id: "1",
          image: "towns/onondaga-clay-shadow.png",
          name: "Oberon Pool",
          fullSize: true,
          summary:
            "Clay's blend of suburban charm, recreational opportunities, and access to urban amenities makes it an attractive place for families, professionals, and individuals looking for a well-rounded community experience. Whether enjoying the local parks, shopping at the mall, or participating in community events, residents of Clay find a welcoming and vibrant environment to call home.",
          download: "clay",
        },
        {
          id: "1",
          image: "towns/onondaga-cicero-shadow.png",
          name: "Kari Horniday Park",
          fullSize: true,
          summary:
            "One of Cicero's notable attractions is Oneida Lake, a large freshwater lake located to the north of the town. Boating, fishing, and recreational activities on the lake are popular among residents and visitors alike. Additionally, the town features parks and green spaces, including William Park, providing opportunities for outdoor recreation and community gatherings.",
          download: "cicero",
        },
        {
          id: "1",
          image: "towns/onondaga-camilus-shadow.png",
          name: "Kayal & Boat Launch",
          fullSize: true,
          summary:
            "Camillus, New York, is a picturesque town located in Onondaga County, southwest of Syracuse. Camillus is characterized by a mix of residential neighborhoods, parks, and commercial areas. The town is home to the Camillus Erie Canal Park, which showcases the historical significance of the Erie Canal in the development of the region. Visitors can explore the canal's towpath, locks, and the Nine Mile Creek Aqueduct, providing a glimpse into the town's heritage.",
          download: "camilus",
        },
        {
          id: "1",
          image: "towns/onondaga-dewitt-shadow.png",
          name: "Aspen House",
          fullSize: true,
          summary:
            "DeWitt is home to several parks and green spaces, providing residents with ample opportunities for outdoor activities and community gatherings. One of the notable parks is the Butternut Creek Recreation and Nature Trail, offering scenic trails and natural beauty. The town's commitment to preserving green spaces contributes to a high quality of life for its residents.",
          download: "dewitt",
        },
        {
          id: "1",
          image: "towns/onondaga-eldbridge-shadow.png",
          name: "Drakes Landing Park",
          fullSize: true,
          summary:
            "The town is known for its agricultural heritage, and the surrounding countryside features farms and open spaces. Elbridge is home to the Jordan-Elbridge Central School District, which serves the educational needs of local students. The community's commitment to education is evident in the engagement and support for school events and activities.",
          download: "eldbridge",
        },
        {
          id: "1",
          image: "towns/onondaga-fabius-shadow.png",
          name: "Fishing Spots",
          fullSize: true,
          summary:
            "Fabius maintains a balance between its rural character and access to modern amenities. While the town offers a peaceful retreat from urban life, it is conveniently located near major transportation routes, allowing for easy access to neighboring communities. Residents can also enjoy local businesses, community events, and a sense of camaraderie that defines small-town living.",
          download: "fabius",
        },
        {
          id: "1",
          image: "towns/onondaga-fayetteville-manlius-shadow.png",
          name: "Storage",
          fullSize: true,
          summary:
          "The town is bordered by green spaces, including parks and nature reserves. Green Lakes State Park, with its two glacial lakes and scenic trails, is a popular destination for outdoor activities such as hiking, swimming, and picnicking. The town's commitment to preserving green spaces enhances the quality of life for residents and contributes to the overall appeal of Fayetteville-Manlius.",

          download: "fayetteville-manlius",
        },
        {
          id: "1",
          image: "towns/onondaga-geddes-shadow.png",
          name: "Summer Camp",
          fullSize: true,
          summary:
            "Geddes is home to the New York State Fairgrounds, a prominent venue hosting the annual New York State Fair. The fair, held in late summer, attracts visitors from across the state with its diverse array of entertainment, agricultural displays, and cultural exhibits. The fairgrounds also host other events throughout the year, contributing to Geddes' cultural vibrancy.",          download: true,
           download: "geddes",
          },
        {
          id: "1",
          image: "towns/onondaga-marcellus-shadow.png",
          name: "Founders Day",
          fullSize: true,
          summary:
            "Nature enthusiasts in Marcellus can explore the nearby Highland Forest, a vast expanse of woodlands offering hiking trails, cross-country skiing, and opportunities for birdwatching. The town also features Marcellus Park, providing recreational amenities such as sports fields, playgrounds, and a swimming pool.",
          download: "marcellus",
        },
        {
          id: "1",
          image: "towns/onondaga-marcellus-shadow.png",
          name: "Mothers Day Sale",
          fullSize: true,
          summary:
            "Nature enthusiasts in Marcellus can explore the nearby Highland Forest, a vast expanse of woodlands offering hiking trails, cross-country skiing, and opportunities for birdwatching. The town also features Marcellus Park, providing recreational amenities such as sports fields, playgrounds, and a swimming pool.",
          download: "marcellus",
        },
      ],      
    }

  }
  
  closeAlertDialog = () => {

    this.setState({
      alertDialogOpen: false
    })

  }

  getCommunityListTile = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["1rem", "1rem", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"Ammenities"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1rem", "1rem", "1rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={
              <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
              <Grid item xs={12}>
              <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 1 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 1 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 1 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[0]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 2 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 2 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 2 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[1]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 3 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 3 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 3 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[2]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 4 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 4 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 4 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[3]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 5 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 5 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 5 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[4]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 6 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 6 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 6 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[5]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 7 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 7 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 7 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[6]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 8 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 8 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 8 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[7]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 9 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 9 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 9 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[8]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 10 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 10 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 10 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[9]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 11 })}}
                  sx={{
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 11 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 10 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{widht:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[10]}</Grid></Grid>


              </Grid>
              </Grid>


          </Grid>
        
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", ".8rem", ".8rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["250px", "90%", "90%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              null           }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }


  getCommunitySummaryTile = (selectedtile) => {

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={null}
                tagTxtColor={"black"}
                tagTxtSize={["1rem", "1rem", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={"contain"}
                topTxt={this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].name }
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={["50px", "10%", "10%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"10px"}
                mdlPicture={this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].image}
                mdlPictureAlign={"50% 50%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", ".75rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"rgba(255,255,255,0)"}
                mdlBorder={"0px solid black"}
                mdlHeight={["250px", "50%", "50%"]}
                mdlGraphType={"line"}
                mdlGraphColors={["red","blue","gold","green"]}
                mdlGraphData={null}
                mdlGraphLegend={false}
                mldGraphGrid={false}                
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={
                  null
                  }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["15px", ".75rem", ".85rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "40%", "40%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };

  
  getCommunityInfoTile = () => {
    return (
      <Grid
      item
      sx={{
        width: "100%",
        padding: {
          xs:"10px",
          sm:"10px",
          md:"10px",
          lg:"20px",
          xl:"20px"
        },
        height: {
          xl: "100%",
          lg: "100%",
          md: "100%",
          sm: "100%",
        },
        
      }}
      xl={4}
      lg={4}
      md={4}
      sm={6}
      xs={12}
    >
               <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={"Download Map"
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"5px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"1px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={"0px"}
                tagTopLeftCallback={this.getInformationDownload}                                

                topProgress={this.state.inProgress}
                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["50px", "10%", "10%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={"downloads/radisson-guide-tile.png"}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={ "#FFFFFF"}
                mdlBorder={"0px solid black"}
                mdlHeight={["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={"Call Me For An Indepth Discusson"}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"center"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              </Grid>
    );
  };


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "10px",
            fontSize: "2rem",
          }}
          xs={12}
        >
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {this.getCommunityListTile()}
            {this.getCommunitySummaryTile()}
            {this.getCommunityInfoTile()}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme} />
        </Grid>
      </Grid>
    );
  };


  downloadInformation = () => {
    this.setState({ inProgress: true }, () => {
      this.props.getInformationDownload(
        this.props.parentPage,
        "radissonmap",
        this.infomrationCallback,
        )});
  }


  informationCallback = (response) => {

    if(response.data.message){
      const base64String = response.data.message;

      // Convert base64 string to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a download link and trigger click
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    this.setState({inProgress:false})

  }


  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showFinancing}
          onClose={this.props.toggleFinancing}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "86%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px", 
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem"
                },
                color:this.props.theme.bannerTextColor
                      
              }}
            >
              &nbsp;&nbsp;Radisson's Ammenities
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleFinancing}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.financingSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top HomeValueage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      <AlertDialog theme={this.props.theme} open={this.state.alertDialogOpen} yesButtonLabel="Ok" yesButtonAction={this.closeAlertDialog} title={this.state.alertDialogTitle} message={this.state.alertDialogMessage} />
      </Drawer>
    );
  }
}

export default RadissonAmmenities;
