import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ImageClickCoordinates from "./ImageClickCoordinates";
import FunctionalComponent from "./GetScreenSize";
import PropertyFilter from "./PropertyFilter";

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import React, { Component } from "react";
import Footer from "./Footer";
import ListingReport from "./ListingReport";
import RequestShowing from "./RequestShowing";
import HowardHanna from './HowardHanna';
import MyServices from './MyServices';
import About from './About';
import RadissonAmmenities from './RadissonAmmenities';
import RadissonHoa from './RadissonHoa';
import Cookies from './Cookies';
import HomeValue from './HomeValue';
import SlideShow from './SlideShow';
import SearchListings from './SearchListings';
import RadissonSearchListings from './RadissonSearchListings';
import RadissonFeaturedListings from './RadissonFeaturedListings';
import BuyingAHome from './BuyingAHome';
import SellingAHome from './SellingAHome';
import SpotlightListings from './SpotlightListings';
import FavoriteListings from './FavoriteListings';
import FeaturedListings from './FeaturedListings';
import MainMenu from './MainMenu';
import Contact from './Contact';
import Image from 'mui-image'
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import DwrServices from "./services/DwrServices";
import MenuIcon from '@mui/icons-material/Menu';
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import Button from "@mui/material/Button";

import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, Select, ListItemText, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';



class RadissonResource extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();

    //this.restServices = new DwrServices('http://192.168.1.17:5050');    

    this.markers = [];
    this.listingContainerRef = React.createRef();
    this.state = {
      selectedTowns: [],
      parentPage: null,
      doIntro: false,
      appSize: "large",
      howardzindex:1000,
      myserviceszindex:1000,
      financingzindex:1000,
      homevaluezindex:1000,
      searchlistingszindex:1000,
      small:{
        mapWidth:7,
        tileHeight: "40%",        
        fontSize:".8rem",
        fontSmSize:".5rem",
        fontLgSize:"1rem",
        topHeight: "70%",
        mdlHeight: "20%",
        btmHeight: "10%"
      },
      large:{
        mapWidth:6,
        tileHeight: "60%",        
        fontSize:"1rem",
        fontSmSize:".75rem",
        fontLgSize:"1.2rem",
        topHeight: "70%",
        mdlHeight: "20%",
        btmHeight: "10%"
      },
      mapWidth: 7,
      tileHeight: "40%",      
      showListingReport: false,
      showRequestShowing: false,
      showMyServices: false,
      showHowardHanna: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showContact: false,
      showAbout: false,
      showMainMenu: false,
      showCookies: false,
      featuredListings: false,
      gotoHome: null,
      favorites: [], 
      
      selectedSearchItems:['Single Family', 'Four Bedroom','North', 'West', '$200k - $400k']
    };

    /*
    this.timer1 = setTimeout(() => {
      this.setIntroDone();
      this.setState({showCookies:false})

    }, 5000);

    this.timer2 = setTimeout(() => {
      this.setEaseOut();
      //this.scrollTo100px()
    }, 4000);
    */
  }

  scrollTo100px = () => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth', // Use 'auto' for immediate scroll, 'smooth' for smooth scroll
    });  };


  componentDidMount() {
    const uuid = uuidv4();
    let userdata = {
      "parentpage":"SessionPage",
      "sessionid": uuid,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/requests/startsession', userdata, this.callback  )
  }


  /////////////////////////////
  //
  // Services
  //
  /////////////////////////////


  getRequestConnect = (parentPage, name, phone, email, valuation, buying, selling, investment, commercial, building, relocation, land, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "valuation": valuation,
      "buying": buying,
      "selling": selling,
      "investment": investment,
      "commercial": commercial,
      "building":building,
      "relocatin":relocation
    }

    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/requests/connect', userdata, callback )
  }

  getValuationAppointment = (parentPage, name, phone, email, address, relocating, upordownsizing, buildorbuy, locations, timeframe, message, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "address": address,
      "messsage": message,
      "relocating": relocating,
      "upordownsizing": upordownsizing,
      "buildorbuy": buildorbuy,
      "preferredlocations": locations,
      "timeframe": timeframe
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/requests/valuation', userdata, callback  )
  }

  getRequestShowing = (parentPage, name, phone, email, mslid, address, firstDay, firstTimes, secondDay, secondTimes, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "mlsid": mslid,
      "address": address,
      "firstDay": firstDay,
      "firstTimes": firstTimes,
      "secondDay": secondDay,
      "secondTimes": secondTimes,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/requests/showing', userdata, callback  )
  }

  getQueryReport = (parentPage, locations, styles, price, livingarea, bedrooms, bathrooms, musthaves, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "locations": locations,
      "styles":styles,
      "price": price,
      "livingarea":livingarea,
      "bedrooms":bedrooms,
      "bathrooms":bathrooms,
      "musthaves":musthaves,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/reports/query', userdata, callback  )
  }

  getHomeValueReport = (parentPage, streetNumber, streetName, townName, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "number": streetNumber,
      "street": streetName,
      "town": townName,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/reports/homevalue', userdata, callback  )
  }

  getHomeValueDownload = (parentPage, streetNumber, streetName, townName, taxId, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "number": streetNumber,
      "street": streetName,
      "town": townName,
      "taxid": taxId
    }
    DwrServices.image('http://192.168.1.17:5050/api/v1/propertyparser/downloads/homevalue', userdata, callback  )
  }

  getFinanceDownload = (parentPage, principle, rate, term, pmi, taxes, insurance, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "principle": principle,
      "term": term,
      "rate": rate,
      "taxes":taxes,
      "pmi": pmi,
      "insurance": insurance
    }
    DwrServices.image('http://192.168.1.17:5050/api/v1/propertyparser/downloads/finance', userdata, callback)
  }

  getFavoritesDownload = (parentPage, mlsids, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "mslids": mlsids,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/downloads/favorites', userdata, callback  )
  }

  getListingReport = (parentPage, mlsid, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "mslid": mlsid,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/reports/listing', userdata, callback  )
  }

  getListingDownload = (parentPage, mlsid, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "mslid": mlsid,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/downloads/listing', userdata, callback  )
  }

  getFeaturedListings = (parentPage, featuredType, featuredsubType, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "featuredtype": featuredType,
      "featuredsubtype": featuredsubType,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/reports/featured', userdata, callback  )
  }

  getCommunityReport = (parentPage, community, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "community": community,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/reports/community', userdata, callback  )
  }

  getCommunityDownload = (parentPage, community, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "community": community,
    }
    DwrServices.image('http://192.168.1.17:5050/api/v1/propertyparser/downloads/community', userdata, callback  )
  }


  getInformationDownload = (parentPage, infotype, callback) => {
    let userdata = {
      "parentpage": parentPage,
      "information": infotype,
    }
    DwrServices.post('http://192.168.1.17:5050/api/v1/propertyparser/downloads/information', userdata, callback  )
  }


  callback = (data) => {

    this.setState({
      sessionid: data.sessionid
    })
  }


  downloadResource = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }

  setIntroDone = () => {
    this.setState({
      doIntro: false,
    });
  };

  setEaseOut = () => {
    this.setState({
      easeout: true,
    });
  };

  componentWillUnmount() {
    //clearTimeout(this.timer1);
    //clearTimeout(this.timer2);
  }

  toggleListingReport = () => {
    this.setState({
      showListingReport: !this.state.showListingReport,
    });
  };

  showListingReport = (listingid) => {
    this.setState({
      showListingReport: !this.state.showListingReport,
      listingId: listingid
    });
  }

  toggleRequestShowing = () => {
    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
    });
  };

  displayRequestShowing = (listings) => {
    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
      listingRequest: listings
    });
  }


  /*
  showRequestShowingOfFavorites = (id) => {

    let listings =  this.state.searchResults.map((listing, index) => {
        if(this.props.favorites.some(obj => obj.mls === tile.mls) 
){
          return listing
        }else{
          return null
        }
    }).filter((element) => element !== null);


    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
      listingRequest: listings
    });
  }
 */
  
  markAsFavoriteold = (uid) => {
    const newArray = _.cloneDeep(this.state.favorites)
    if (newArray.includes(uid)){
      const filteredArray = newArray.filter((value) => value !== uid); // remove the value from the array
      this.setState({
        favorites: filteredArray
      })
    }
    else{
      newArray.push(uid)
      this.setState({
        favorites: newArray,
      })
    }
  }

  markAsFavorite = (listing) => {
    const newArray = _.cloneDeep(this.state.favorites)
    if (newArray.some(obj => obj.mls === listing.mls)){
      const filteredArray = newArray.filter((value) => value.mls !== listing.mls); // remove the value from the array
      this.setState({
        favorites: filteredArray
      })
    }
    else{
      newArray.push( _.cloneDeep(listing))
      this.setState({
        favorites: newArray,
      })
    }
  }


  /*
  * Howard Hanna
  */
  toggleCookies = () => {
    this.setState({
      showCookies: !this.state.showCookies,
    });
  };


  /*
  * Howard Hanna
  */
  toggleHowardHanna = () => {
    this.setState({
      showHowardHanna: !this.state.showHowardHanna,
    });
  };

  showHowardHanna = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: !this.state.showHowardHanna,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Howard Hanna
  */
  toggleMyServices = () => {
    this.setState({
      showMyServices: !this.state.showMyServices,
    });
  };

  showMyServices = () => {
    this.setState({
      parentPage:"MainMenu",
      showMyServices: !this.state.showMyServices,
      showHowardHanna: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Financing
  */
  toggleFinancing = () => {
    this.setState({
      showFinancing: !this.state.showFinancing,
    });
  };

  showFinancing = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showMyServices: false,
      showFinancing: !this.state.showFinancing,
      showHomeValue: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Home Value
  */
  toggleHomeValue = () => {
    this.setState({
      showHomeValue: !this.state.showHomeValue,
    });
  };

  showHomeValue = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: !this.state.showHomeValue,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  
  /*
  * SpotlightListings
  */
  toggleSpotlightListings = () => {
    this.setState({
      showSpotlightListings: !this.state.showSpotlightListings,
    });
  };

  showSpotlightListings = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: !this.state.showSpotlightListings,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * FavoriteListings
  */
  toggleFavoriteListings = () => {
    this.setState({
      showFavoriteListings: !this.state.showFavoriteListings,
    });
  };

  showFavoriteListings = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showFavoriteListings: !this.state.showFavoriteListings,
      showSpotlightListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }



  /*
  * SearchListings
  */
  toggleSearchListings = () => {
    this.setState({
      showSearchListings: !this.state.showSearchListings,
    });
  };

  showSearchListings = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: !this.state.showSearchListings,
      showBuyingAHome: false,
      showSellingAHome: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Buying A Home
  */
    toggleBuyingAHome = () => {
      this.setState({
        showBuyingAHome: !this.state.showBuyingAHome,
      });
    };
  
    showBuyingAHome = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: !this.state.showBuyingAHome,
        showSellingAHome: false,
        showMainMenu: false,
        showFeaturedListings: false,
        showAbout: false,
      });
    }

  /*
  * Selling A Home
  */
  toggleSellingAHome = () => {
    this.setState({
      showSellingAHome: !this.state.showSellingAHome,
    });
  };

  showSellingAHome = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: !this.state.showSellingAHome,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Featured Listings
  */
    toggleFeaturedListings = () => {
      this.setState({
        showFeaturedListings: !this.state.showFeaturedListings,
      });
    };
  
    showFeaturedListings = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showMainMenu: false,
        showFeaturedListings:!this.state.showFeaturedListings,
        showAbout: false,
      });
    }
  
  
    /*
  * About
  */
    toggleAbout = () => {
      this.setState({
        showAbout: !this.state.showAbout,
      });
    };
  
    showAbout = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showMainMenu: false,
        showFeaturedListings:false,
        showAbout: false,
      });
    }
  

  /*
  * mainmenu
  */
    toggleMainMenu = () => {
      this.setState({
        showMainMenu: !this.state.showMainMenu,
      });
    };
  
    showMainMenu = () => {
      this.setState({
        showMainMenu: !this.state.showMainMenu,
      });
    }
  

  /*
  * Contact
  */
  toggleContact = () => {
    this.setState({
      showContact: !this.state.showContact,
      parentPage: null
    });
  };

  showContact = (parentPage) => {
    this.setState({
      showContact: !this.state.showContact,
      parentPage: parentPage
    });
  }

  

  /*
  * Atoogel App Size
  */
  toggleAppSize = () => {
    if(this.state.appSize === "small"){
      this.setState({
        appSize:"large"
      })
    }else{
      this.setState({
        appSize:"small"
      })
    }
  }


  handleSearchItemChange = (event) => {
    this.setState({ selectedSearchItems: event.target.value });
  };


  imageClickCallback = (location) => {


      if(location === "North"){
        let north = []
        north = !this.state.selectedTowns.includes("Baldwinsville") ? [...north, "Baldwinsville"] : north
        north = !this.state.selectedTowns.includes("Brewerton") ? [...north, "Brewerton"] : north
        north = !this.state.selectedTowns.includes("Clay") ? [...north, "Clay"] : north
        north = !this.state.selectedTowns.includes("Liverpool") ? [...north, "Liverpool"] : north
        north = !this.state.selectedTowns.includes("Cicero") ? [...north, "Cicero"] : north
        this.setState(prevState => ({
          selectedTowns: [...prevState.selectedTowns, ...north],
        }));
      }else if(location === "West"){
          let west = []
          west = !this.state.selectedTowns.includes("Eldbridge") ? [...west, "Eldbridge"] : west
          west = !this.state.selectedTowns.includes("Camillus") ? [...west, "Camillus"] : west
          west = !this.state.selectedTowns.includes("Onopndaga") ? [...west, "Onondaga"] : west
          west = !this.state.selectedTowns.includes("Skaneateles") ? [...west, "Skaneateles"] : west
          this.setState(prevState => ({
            selectedTowns: [...prevState.selectedTowns, ...west],
          }));
        
      }else if(location === "South"){
        let south = []
        south = !this.state.selectedTowns.includes("Spafford") ? [...south, "Spafford"] : south
        south = !this.state.selectedTowns.includes("LaFayette") ? [...south, "Lafayette"] : south
        south = !this.state.selectedTowns.includes("Tully") ? [...south, "Tully"] : south
        south = !this.state.selectedTowns.includes("Fabius") ? [...south, "Fabius"] : south
        south = !this.state.selectedTowns.includes("Pompey") ? [...south, "Pompey"] : south
        this.setState(prevState => ({
          selectedTowns: [...prevState.selectedTowns, ...south],
        }));
      
      }else if(location === "East"){
        let east = []
        east = !this.state.selectedTowns.includes("Minoa") ? [...east, "Minoa"] : east
        east = !this.state.selectedTowns.includes("Manlius") ? [...east, "Manlius"] : east
        east = !this.state.selectedTowns.includes("Fayetteville") ? [...east, "Fayetteville"] : east
        east = !this.state.selectedTowns.includes("Jamesville") ? [...east, "Jamesville"] : east
        east = !this.state.selectedTowns.includes("Dewitt") ? [...east, "Dewitt"] : east
        this.setState(prevState => ({
          selectedTowns: [...prevState.selectedTowns, ...east],
        }));
      }else if(!this.state.selectedTowns.includes(location)){
          this.setState(prevState => ({
            selectedTowns: [...prevState.selectedTowns, location],
          }));
      }else{
          let new_array = this.state.selectedTowns.filter(item => item !== location);
          this.setState(prevState => ({
            selectedTowns: new_array
          }));
      }

  }

  getTeaserTile = () => {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const { selectedSearchItems } = this.state;

    const searchItems = ['Condo', 'Townhouse','Ranch', 'Two Story', 'One Bedroom', 'Two Bedroom', 'Three Bedroom','Four Bedroom','North', 'South', 'East', 'West', 'Syracuse (City)', 'Under $200k', '$200k - $400k', '$400k - $600k', '$600k - $800k', ' Over $800k'];

    const bedrooms = [
      {
        value: 0,
        label: '1',
      },
      {
        value: 10,
        label: '2',
      },
      {
        value: 20,
        label: '3',
      },
      {
        value: 30,
        label: '4',
      },
      {
        value: 40,
        label: '5',
      },
      {
        value: 50,
        label: '6',
      },
      {
        value: 60,
        label: '7',
      },
      {
        value: 70,
        label: '8',
      },
      {
        value: 80,
        label: '9',
      },
      {
        value: 90,
        label: '10',
      },
      {
        value: 100,
        label: '10+',
      },
    ];

    const price = [
      {
        value: 0,
        label: '$100K',
      },
      {
        value: 10,
        label: '$200K',
      },
      {
        value: 20,
        label: '$300K',
      },
      {
        value: 30,
        label: '$400K',
      },
      {
        value: 40,
        label: '$500K',
      },
      {
        value: 50,
        label: '$600K',
      },
      {
        value: 60,
        label: '$700K',
      },
      {
        value: 70,
        label: '$800K',
      },
      {
        value: 80,
        label: '$900K',
      },
      {
        value: 90,
        label: '$1M',
      },
      {
        value: 100,
        label: '$1M+',
      },
    ];

    return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding:"0px",
            border:"0px solid red",
            width: {
              xs: "100%",
            },
            height: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
          }}
          >
          <Grow in={true} timeout={6000}>

          <Grid
            item
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              border:"0px solid blue",
              height: {
                xs: "65%",
                sm: "65%",
                md: "95%",
                lg: "95%",
                xl: "95%",
              },
              
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              
            padding:{
              xs: "5px 5px 0px 5px",
              sm: "5px 5px 0px 5px",
              md: "5px",
              lg: "10px",
              xl: "10px",
            },
            fontSize:{
              xs: "0px",
              sm: "0px",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            }

            }}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
           >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "5px 0px 5px 0px",
                    md: "5px 0px 0px 10px",
                    lg: "5px 0px 0px 20px",
                    xl: "5px 0px 0px 20px"
                  },
                  border:"0px solid red",
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                }}
                >
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      height: {
                        xl: "90%",
                        lg: "90%",
                        md: "95%",
                        sm: "95%",
                        xs: "95%",
                      },
                      border:{
                        xs:"1px solid black",
                        sm:"1px solid black",
                        md:"2px solid #333333",
                        lg:"2px solid #333333",
                        xl:"2px solid #333333"
                      },
                        fontWeight: "normal",
                        padding: {
                          xs: "10px 10px 5px 10px",
                          sm: "10px 10px 5px 10px",
                          md: "0px 5px 5px 5px",
                          lg: "20px 20px 0px 20px",
                          xl: "10px 10px 0px 10px"
                        },
                        backgroundColor:"rgba(255,255,255,.85)",
                        borderRadius:"5px"
                          
                    }}
                    >

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{
                          border:"0px solid red",
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                          },
                          height: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                          },
                        }}
                        >

                       <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            fontSize:{
                              xs:"1rem",
                              md:"1.25rem",
                              lg:"1.75rem"
                            },
                            height:{
                              xs:"90%",
                              md:"90%",
                              lg:"90%"
                            },                            
                            color:this.props.theme.bannerColor,
                            border: "0px solid rgba(205,205,205,.85)",
                            paddingBottom:"10px"
                          }}
                          >

                              <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                
                                paddingTop:{
                                  xl: "5px",
                                  lg: "5px",
                                  md: "0px",
                                  sm: "10px",
                                },
                                width: {
                                  xl: "100%",
                                  lg: "100%",
                                  md: "100%",
                                  sm: "100%",
                                },
                                height: {
                                  xl: "100%",
                                  lg: "100%",
                                  md: "100%",
                                  sm: "100%",
                                },
                              }}
                              >
                                  <SlideShow />
                              </Grid>
                          </Grid>


                          <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            fontSize:{
                              xs:"1rem",
                              md:"1.5rem",
                              lg:"1.75rem"
                            },
                            height:{
                              xs:"10%",
                              sm:"10%",
                              md:"10%",
                              lg:"10%",
                              xl:"10%",
                            },
                            width:"100%",                   
                            color:this.props.theme.bannerColor,
                            borderBottom: "0px solid rgba(205,205,205,.85)",
                            paddingBottom:"5px",
                          }}>

                        <Grid container
                        justifyContent="flex-end"
                        alignItems="flex-end" 
                        sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
                              <Button
                
                                onClick={this.sendContactCard}
                                variant={this.props.theme.buttonVariant}
                                sx={{
                                  '&:hover': {
                                    border:"1px solid #AAAAAA",
                                    background:this.props.theme.bannerColor,      
                                  },
                                  fontSize:"1.5rem",
                                  textTransform: "none",
                                  width:{
                                    xs:"100%",
                                    sm:"100%",
                                    md:"100%",
                                    lg:"100%",
                                    xl:"100%",
                                  }, 
                                  height: "85%%",
                                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                                  color:this.props.theme.bannerTextColor,
                                  border:"1px solid " + this.props.theme.buttonColor,      
                                  background:this.props.theme.bannerColor,      
                                
                                }}
                                size="small"
                              >
                                Find Radisson Homes
                              </Button>
                          </Grid>
                          </Grid>







                       </Grid>
                       
                    </Grid>


                </Grid>
            </Grid>
         </Grow>     
         <Grow in={true} timeout={6000}>
          <Grid
            item
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              border: "0px solid yellow",
              height: {
                xs: "28%",
                sm: "30%",
                md: "95%",
                lg: "95%",
                xl: "95%",
              },
                            
              padding:{
                xs: "5px 5px 0px 5px",
                sm: "5px 5px 0px 5px",
                md: "5px",
                lg: "10px",
                xl: "10px",
              }
            }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "5px 0px 5px 0px",
                    md: "5px 0px 0px 10px",
                    lg: "5px 0px 0px 20px",
                    xl: "5px 0px 0px 20px"
                  },
                  border:"0px solid red",
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                }}
                >
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      height: {
                        xl: "90%",
                        lg: "90%",
                        md: "95%",
                        sm: "95%",
                        xs: "95%",
                      },
                      border:{
                        xs:"1px solid black",
                        sm:"1px solid black",
                        md:"2px solid #333333",
                        lg:"2px solid #333333",
                        xl:"2px solid #333333"
                      },
                        fontWeight: "normal",
                        padding: {
                          xs: "0px 0px 0px 0px",
                          sm: "10px 10px 5px 10px",
                          md: "0px 0px 0px 5px",
                          lg: "20px 20px 0px 20px",
                          xl: "0px 0px 0px 0px"
                        },
                        backgroundColor:"rgba(255,255,255,.85)",
                        borderRadius:"5px"
                          
                    }}
                    >
                        {this.props.screenSize !== "xs"?
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            width: {
                              xs: "100%",
                            },
                            height: {
                              xs: "100%",
                            },
                            padding:"0px",
                            border:"0px solid black",
                            borderRadius:"0px"
                          }}
                          >
                              <Grid
                                item
                                sx={{
                                  padding: {
                                    xs:"5px",
                                    sm:"0px",
                                    md:"0px",
                                    lg:"0px",
                                    xl:"0px",
                                  },    
                                  textAlign: "center",
                                  fontSize: {
                                    xs: "1.75rem",
                                    sm: "1.75rem",
                                    md: "2.5rem",
                                    lg: "2.5rem",
                                    xl: "2.5rem",
                                  },
                                height: {
                                    xs: "20%",
                                    sm: "20%",
                                    md: "10%",
                                    lg: "10%",
                                    xl: "10%",
                                  },
                                  border:"0px solid black",
                                  color: this.props.theme.textColor
                                }}
                                xs={8}
                                md={12}

                              >
                                  Donna Wills
                              </Grid> 

                                <Grid
                                  item
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"0px",
                                      lg:"0px",
                                      xl:"0px",
                                    },    
                                    textAlign: "right",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: "1rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "10%",
                                      lg: "10%",
                                      xl: "10%",
                                    },
                                    color: this.props.theme.textColor
                                  }}
                                  xs={5}
                                  md={5}
                                  onClick = { () => this.showMyServices()}

                                >
                                    About Me
                                </Grid> 
                                <Grid
                                  item
                                  sx={{
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"0px",
                                      lg:"0px",
                                      xl:"0px",
                                    },    
                                    textAlign: "center",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: "1rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "10%",
                                      lg: "10%",
                                      xl: "10%",
                                    },
                                    color: this.props.theme.textColor
                                  }}
                                  xs={1}
                                  md={1}
                                >
                                   |
                                </Grid> 
                                <Grid
                                  item
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"0px",
                                      lg:"0px",
                                      xl:"0px",
                                    },    
                                    textAlign: "left",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: "1rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "10%",
                                      lg: "10%",
                                      xl: "10%",
                                    },
                                    color: this.props.theme.textColor
                                  }}
                                  xs={5}
                                  md={5}
                                    onClick={() => this.showContact("Homepage")}

                                >
                                   Contact Me
                                </Grid> 

                                <Grid
                                  item
                                  sx={{
                                    padding: "0px",
                                    textAlign: "center",
                                    fontSize: {
                                      xs: "0rem",
                                      sm: "0rem",
                                      md: "2.5rem",
                                      lg: "3rem",
                                      xl: "3rem",
                                    },
                                  height: {
                                      xs: "0px",
                                      sm: "0px",
                                      md: "55%",
                                      lg: "55%",
                                      xl: "55%",
                                    },

                                  }}
                                  xs={8}
                                  md={12}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        backgroundImage: 'url("profilepics/profile-round-lowres.png")',
                                        backgroundPosition: "50% 100%",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        borderRadius:{
                                          xs:"0px",
                                          sm:"0px",
                                          md:"50px",
                                          lg:"50px",
                                          xl:"50px"
                                        },
                                        width: "100%",
                                        height: {
                                          xs:"0px",
                                          sm:"0px",
                                          md:"100%",
                                          lg:"100%",
                                          xl:"100%"
                                        },
                                        padding: "0px",
                                      }}
                                    >
                                  </Grid> 
                                </Grid> 

                                <Grid
                                    item
                                    sx={{
                                      padding: "0px",
                                      textAlign: "center",
                                      fontSize: "1rem",
                                      height: {
                                        xs: "70%",
                                        sm: "70%",
                                        md: "25%",
                                        lg: "25%",
                                        xl: "25%",
                                      },
                                    }}
                                    xs={8}
                                    md={12}
                                 >
                                      <Grid
                                          container
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          sx={{
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                            padding: {
                                              xs:"10px",
                                              sm:"30px",
                                              md:"10px",
                                              lg:"10px",
                                              xl:"10px",
                                            },    
                                            fontWeight: "bold",
                                            border:"0px solid red",
                                            lineHeight: {
                                              xs: "1.5",
                                              sm: "1.5",
                                              md: "1.5",
                                              lg: "1.5",
                                              xl: "1.5",
                                            },
                                            fontSize: {
                                              xs: ".8rem",
                                              sm: ".8rem",
                                              md: "1rem",
                                              lg: "1.2rem",
                                              xl: "1.2rem",
                                            },
                                            color:this.props.theme.textColor
                                          }}
                                        >
                                          Licensed Real Estate Salesperson
                                          <br />
                                          DonnaWills@HowardHanna.com
                                          <br />
                                          315.723.1077
                                        </Grid>
                                </Grid>

                      </Grid>
                      :
                         <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                width: {
                                  xs: "100%",
                                },
                                height: {
                                  xs: "100%",
                                },
                                padding:"0px",
                                border:"0px solid black",
                                borderRadius:"0px"
                              }}
                              >
                                
                                <Grid
                                    item
                                    sx={{
                                      padding: "0px",
                                      textAlign: "center",
                                      fontSize: "1rem",
                                      height: "100%",
                                    }}
                                    xs={8}
                                 >
                                     <Grid
                                          container
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          sx={{
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                            padding: {
                                              xs:"0px",
                                            },    
                                            fontWeight: "bold",
                                            border:"0px solid red",
                                            lineHeight: {
                                              xs: "1",
                                            },
                                            fontSize: {
                                              xs: "1.5rem",
                                            },
                                            color:this.props.theme.textColor
                                          }}
                                        >
                                            <Grid
                                              item
                                              sx={{
                                                padding: "5px",
                                                textAlign: "center",
                                                fontSize: "2rem",
                                                height: "35%",
                                                border:"0px solid red",
                                              }}
                                              xs={12}
                                          >

                                            Donna Wills
                                          </Grid>

                                          <Grid
                                              item
                                              sx={{
                                                "&:hover": {
                                                  cursor: "pointer",
                                                },
                                                padding: "0px",
                                                textAlign: "center",
                                                fontSize: "1rem",
                                                height: "10%",
                                                border:"0px solid red",
                                                textAlign:"right"
                                              }}
                                              xs={5}
                                              onClick={() => this.showMyServices()}
                                          >

                                            About Me
                                          </Grid>
                                          <Grid
                                              item
                                              sx={{
                                                padding: "0px",
                                                textAlign: "center",
                                                fontSize: "1rem",
                                                height: "10%",
                                                border:"0px solid red",
                                                textAlign:"center"
                                              }}
                                              xs={2}
                                          >
                                            |
                                          </Grid>

                                          <Grid
                                              item
                                              sx={{
                                                "&:hover": {
                                                  cursor: "pointer",
                                                },
                                                padding: "0px",
                                                textAlign: "center",
                                                fontSize: "1rem",
                                                height: "10%",
                                                border:"0px solid red",
                                                textAlign:"left"
                                              }}
                                              onClick={() => this.showContact("Homepage")}
                                              xs={5}
                                          >

                                            Contact Me
                                          </Grid>

                                          <Grid
                                              item
                                              sx={{
                                                padding: "0px",
                                                textAlign: "center",
                                                fontSize: ".8rem",
                                                height: "55%",
                                                border:"0px solid red",
                                                lineHeight: "1.5"
                                              }}
                                              xs={12}
                                          >
                                          <br />
                                          Licensed Real Estate Salesperson
                                          <br />
                                          DonnaWills@HowardHanna.com
                                          <br />
                                          315.723.1077
                                        </Grid>
                                  </Grid>
                                </Grid>
                              <Grid
                                item
                                sx={{
                                  border:"0px solid red",
                                  padding: "0px",
                                  textAlign: "center",
                                  height: {
                                    xs: "100%",
                                    
                                  },
                                  backgroundImage: 'url("profilepics/profile-full.png")',
                                  backgroundPosition: "100% 100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                                xs={4}
                                sm={4}
                                md={0}
                                lg={0}
                                xl={0}
                              >
                              </Grid>
                      </Grid>

                      }

                </Grid>

                </Grid>
              </Grid>
            </Grow>


            <Grid
                  item
                  sx={{
                    background:this.props.theme.headerColor,
                    color:this.props.theme.headerTextColor,

                    border:"0px solid red",
                    padding: "0px",
                    textAlign: "center",
                    height: {
                      xs: "7%",
                      sm: "5%",
                      md: "5%",
                      lg: "5%",
                      xl: "5%",
                    },
                  }}
                  xs={12}
                >
                  <Footer theme={this.props.theme} />
              </Grid>
          </Grid>
    
    );
  };


  render() {
        return(
        <Fade in={true} timeout={2000}>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,0)",
          height:"100%",
          width:"100%",     
          borderTop: "0px solid gray"
        }}
      >
      <Grid
          item
          sx={{
            
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "15%",
              xs: "15%",
            },
            textAlign:"center",
            background: this.props.theme.headerColor,
            padding: {
              xs: "0px",
              sm:"0px"
            },
            fontSize: "16px",
            borderBottom: {
              xs:"0px solid #AAAAAA",
              sm:"0px solid #AAAAAA",
              md:"0px solid #AAAAAA",
              lg:"0px solid #AAAAAA",
              xl:"0px solid #AAAAAA"
            },
            borderTop: {
              xs:"1px solid #888888",
              sm:"1px solid #888888",
              md:"1px solid #AAAAAA",
              lg:"1px solid #AAAAAA",
              xl:"1px solid #AAAAAA"
            }
          }}
          xs={12}
        >

          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            height:"100%",
            width:"100%",     
            border: "0px solid #333333"
            
        }}
          >
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showMyServices ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                color: this.props.theme.headerTextColor,
                fontSize:{
                  xs:"0",
                  sm:"0",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",
                },

                fontWeight: "bold",
                
          }}
              onClick = { () => this.showMyServices()}
              xs={2}
              sm={2}
              md={1}
            >
                My<br/>Services
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showHomeValue ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                fontSize:{
                    xs:"0",
                    sm:"0",
                    md:".75rem",
                    lg:".75rem",
                    xl:".75rem",

                  },
                  fontWeight: "bold",
                  color: this.props.theme.headerTextColor

              }}
              onClick = { () => this.showHomeValue()}
              xs={0}
              sm={0}
              md={1}
            >
              Your Home's<br/>Value
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showFinancing ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                fontSize:{
                  xs:"0",
                  sm:"0",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                },
                fontWeight: "bold",
                color: this.props.theme.headerTextColor
              
            }}
              onClick = { () => this.showFinancing()}
              xs={0}
              sm={0}
              md={1}
            >
                Radisson<br/>
                Ammenities
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showSellingAHome ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                fontSize:{
                    xs:"0",
                    sm:"0",
                    md:".75rem",
                    lg:".75rem",
                    xl:".75rem",

                  },
                  fontWeight: "bold",
                  color: this.props.theme.headerTextColor

              }}
              onClick = { () => this.showSellingAHome()}
              xs={0}
              sm={0}
              md={1}
            >
              Radisson<br/>
              HOA
            </Grid>

            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                textALign:"center",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                padding: "0px",
                display: {
                  xs:'flex',
                  sm:'flex',
                  md:'flex',
                  lg:'flex',
                  xl:'flex',
                } ,    
              }}
              onClick = { () => this.showAbout()}
              xs={7}
              sm={7}
              md={4}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height:"100%",
                  width:"100%", 
                  padding:"10px",
                  border:"0px solid black"    
                    }}
              >
                
                  <Image src="logos/radisson.png" fit="contain" duration={325} />
                </Grid>     
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showSearchListings ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                fontSize:{
                  xs:"0",
                  sm:"0",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                },
                fontWeight: "bold",
                color: this.props.theme.headerTextColor

              }}
              onClick = { () => this.showSearchListings()}
              xs={0}
              sm={0}
              md={1}
            >
                Radisson<br/>Listings
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showFavoriteListings ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                fontSize:{
                  xs:"0",
                  sm:"0",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                },
                fontWeight: "bold",
                color: this.props.theme.headerTextColor

              }}
              xs={0}
              sm={0}
              md={1}
              onClick = { () => this.showFavoriteListings()}
              >
                Favorited<br/>Listings
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign:"center",
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"10px",
                },
                borderTop:
                {
                  xs:"0px solid white",
                  sm: this.state.showFeaturedListings ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                },
                fontSize:{
                  xs:"0",
                  sm:"0",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                },
                fontWeight: "bold",
                color: this.props.theme.headerTextColor

              }}
              onClick = { () => this.showFeaturedListings()}
              xs={0}
              sm={0}
              md={1}
            >
                Featured<br/>Listings
            </Grid>
            <Grid
              item
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                height: {
                  sm: "100%",
                },
                textAlign: {
                  xs:"right",
                  sm:"right",
                  md:"center"
                },
                paddingTop: {
                  xs:"5px",
                  sm:"5px",
                  md:"20px",
                },
                fontSize:{
                  xs:".6rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:"1rem",
                  xl:".75rem",

                },
                color: this.props.theme.headerTextColor,
                border:"0px solid red"
              }}
              onClick = { () => this.showMainMenu()}
              xs={2}
              sm={2}
              md={1}
            >
                <MenuIcon sx={{fontSize:"32px"}}/>
            </Grid>
          </Grid>
        </Grid>

       
        <Grid
          item
          sx={{
            
            height: {
              xl: "90%",
              lg: "90%",
              md: "90%",
              sm: "90%",
              xs: "90%",
            },
            padding: "0px",
            border: "0px solid orange",
            backgroundImage: "url('"+ this.props.theme.background +"')",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          xs={12}
        >
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(255,255,255,0)",
                height: "100%",
                
                border: "0px solid yellow",
                padding:"0px"
              
              }}
            >
             {this.getTeaserTile()}
            </Grid>
        </Grid>
                
        <RequestShowing
          parentPage={this.state.parentPage}
          listings={this.state.listingRequest}
          showRequestShowing={this.state.showRequestShowing}
          toggleRequestShowing={this.toggleRequestShowing}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
        />

        <MyServices
          parentPage={this.state.parentPage}
          showMyServices={this.state.showMyServices}
          toggleMyServices={this.toggleMyServices}
          showContact={this.showContact}
          zindex={this.state.myserviceszindex}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          getInformationDownload={this.getInformationDownload}
          getCommunityDownload={this.getCommunityDownload}
        />

        <RadissonAmmenities
          parentPage={this.state.parentPage}
          showFinancing={this.state.showFinancing}
          toggleFinancing={this.toggleFinancing}
          showContact={this.showContact}
          zindex={this.state.financingzindex}
          theme={this.props.theme}
          getInformationDownload={this.getInformationDownload}
        />

        <HomeValue
          parentPage={this.state.parentPage}
          showHomeValue={this.state.showHomeValue}
          toggleHomeValue={this.toggleHomeValue}
          showContact={this.showContact}
          zindex={this.state.homevaluezindex}
          getHomeValueReport={this.getHomeValueReport}
          getHomeValueDownload={this.getHomeValueDownload}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <About
          showAbout={this.state.showAbout}
          toggleAbout={this.toggleAbout}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />


        <FavoriteListings
          parentPage={this.state.parentPage}
          showFavoriteListings={this.state.showFavoriteListings}
          toggleFavoriteListings={this.toggleFavoriteListings}
          displayRequestShowing={this.displayRequestShowing}
          requestShowing={this.getRequestShowing}
          showListingReport={this.showListingReport}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
        />


        <RadissonSearchListings
          parentPage={this.state.parentPage}
          showSearchListings={this.state.showSearchListings}
          toggleSearchListings={this.toggleSearchListings}
          requestShowing={this.getRequestShowing}
          showContact={this.showContact}
          getSearchResults={this.getQueryReport}
          zindex={this.state.searchlistingszindex}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          displayRequestShowing={this.displayRequestShowing}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
          getInformationDownload={this.getInformationDownload}
        />

        <BuyingAHome
          parentPage={this.state.parentPage}
          showBuyingAHome={this.state.showBuyingAHome}
          toggleBuyingAHome={this.toggleBuyingAHome}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <RadissonHoa
          parentPage={this.state.parentPage}
          showSellingAHome={this.state.showSellingAHome}
          toggleSellingAHome={this.toggleSellingAHome}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          getInformationDownload={this.getInformationDownload}
        />

        <RadissonFeaturedListings
          parentPage={this.state.parentPage}
          showFeaturedListings={this.state.showFeaturedListings}
          requestShowing={this.getRequestShowing}
          getFeaturedListings={this.getFeaturedListings}
          toggleFeaturedListings={this.toggleFeaturedListings}
          displayRequestShowing={this.displayRequestShowing}
          getListingReport={this.getListingReport}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
        />

        <Contact
          parentPage={this.state.parentPage}
          showContact={this.state.showContact}
          toggleContact={this.toggleContact}
          connect={this.getRequestConnect}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          />

        <Cookies
          showCookies={this.state.showCookies}
          toggleCookies={this.toggleCookies}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <MainMenu
          showMainMenu={this.state.showMainMenu}
          toggleMainMenu={this.toggleMainMenu}
          showContact={this.showContact}
          showMyServices = {this.showMyServices}
          showFinancing = {this.showFinancing}
          showHomeValue = {this.showHomeValue}
          showSellingAHome = {this.showSellingAHome}
          showAbout = {this.showAbout}
          showFavoriteListings = {this.showFavoriteListings}
          showSearchListings = {this.showSearchListings}
          showFeaturedListings = {this.showFeaturedListings}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

      </Grid>
      </Fade>
      )
      }
}

export default RadissonResource;
