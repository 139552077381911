import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class AlertDialog extends Component {
    constructor(props) {
      super(props);

      this.state = {
      }
    }

  handleClose = () => {
    this.props.closeAlertDialog()
  }

  render() {
    return (
        <Dialog
            open={this.props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{padding:"0px"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                    height: "100%",
                    width: "100%",
                    background:  this.props.theme.bannerColor,
                    color:       this.props.theme.bannerTextColor,
                    padding:"5px 50px 5px 10px",
                    borderBottom: "1px solid #333333",
                    }}
                >   
                    {this.props.title}
                    </Grid>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                    height: "100%",
                    width: "100%",
                    textAlign:"left",
                    padding:"10px 10px 10px 10px",
                    }}
                >   
                    <div dangerouslySetInnerHTML={{__html:this.props.message}} />
                    </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                {this.props.yesButtonLabel && this.props.yesButtonAction ?
                    <Button  sx={{
                        '&:hover': {
                            background:this.props.theme.bannerColor
                          },
                        background:this.props.theme.bannerColor, color:this.props.theme.bannerTextColor}} onClick={this.props.yesButtonAction} variant="contained">
                        {this.props.yesButtonLabel}
                    </Button>            
                    :
                    null
                }
                {this.props.noButtonLabel && this.props.noButtonAction ?
                    <Button  sx={{
                        '&:hover': {
                            background:this.props.theme.bannerColor
                          },
                        background:this.props.theme.bannerColor, color:this.props.theme.bannerTextColor}} onClick={this.props.noButtonAction} variant="contained">
                        {this.props.noButtonLabel}
                    </Button>            
                    :
                    null
                }
                {this.props.cancelButtonLabel && this.props.cancelButtonAction ?
                    <Button sx={{
                        '&:hover': {
                            background:this.props.theme.bannerColor
                          },
                        background:this.props.theme.bannerColor, color:this.props.theme.bannerTextColor}} onClick={this.props.cancelButtonAction} variant="contained">
                        {this.props.cancelButtonLabel}
                    </Button>            
                    :
                    null
                }
            </DialogActions>
        </Dialog>
    );
    }
}

export default AlertDialog;