import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Image from 'mui-image'
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import AlertDialog from './AlertDialog'
import AddressAutoComplete from "./AddressAutoComplete";
import Footer from './Footer'
import _ from "lodash";


class HomeValue extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      showContact: false,
      pictureHeight: "100%",
      pictureWidth: "60%",

      alertDialogOpen:false,

      inProgress: false,
      generateReport: false,
      streetNumber: '',
      streetName: '',
      townName: '',
      errors: {
        streetNumber: '',
        streetName: '',
        townName: '',
      },

      map_image: null,
      googleMapType: "Goto Street View",
      streetImage: null,
      sataliteImage: null,
      requestSent: false,
      enableSubmit: false,
    }
  }

  
  
  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }

  setAddress = (address => {
    this.setState({address:address})
  })

  sendCompRequest = (event) => {

      event.preventDefault();

      let isValid = true
      

      // parse out the components.
      // 8250 Luchsinger Lane, Baldwinsville, NY, USA
      // Split the string by comma and trim each part
      if(this.state.address){
        const parts = this.state.address.split(',').map(part => part.trim());

        // Extract the number and street
        const [numberStreet, townStateCountry] = parts;
        const [number, street] = numberStreet.split(' ');

        // Extract the town, state, and country
        const [town, state, country] = townStateCountry.split(',').map(part => part.trim());

        if (!number) {
          isValid = false
        } else if (!/^\d+$/.test(number)) {
          isValid = false
        }

        if (!street) {
          isValid = false
        } else if (!/^[a-zA-Z0-9\s]+$/.test(street)) {
          isValid = false
        }

        if (!town) {
          isValid = false
        } else if (!/^[a-zA-Z\s]+$/.test(town)) {
          isValid = false
        }

        if (!isValid) {
          this.setState({requestSent:false });
        } else {
          // Form is valid, perform further actions or submit
          this.setState({inProgress:true }, () => {
          this.props.getHomeValueReport(
            this.props.parentPage,
            number,
            street,
            town,
            this.callback
            )});
        }
      }
  }

  callback = (response) => {
    this.setState({
      inProgress:false, 
      requestSent:true, 
      enableSubmit:false, 
      
      target_address: response.data.target_address,
      target_number: response.data.target_number,
      target_street: response.data.target_street,
      target_town: response.data.target_town,
      target_details: response.data.target_details,
      target_extras: response.data.target_extras,
      target_lastsold: response.data.target_lastsold,
      target_compvalue:response.data.target_compvalue,
      target_taxid: response.data.target_taxid,
      comp1_address: response.data.comp1_address,
      comp1_town: response.data.comp1_town,
      comp1_details: response.data.comp1_details,
      comp1_lastsold: response.data.comp1_lastsold,
      comp2_address: response.data.comp2_address,
      comp2_town: response.data.comp2_town,
      comp2_details: response.data.comp2_details,
      comp2_lastsold: response.data.comp2_lastsold,
      comp3_address: response.data.comp3_address,
      comp3_town: response.data.comp3_town,
      comp3_details: response.data.comp3_details,
      comp3_lastsold: response.data.comp3_lastsold,
      map_image: response.data.map_image,
      streetImage: response.data.house_image,
      sataliteImage: response.data.satalite_image,
      uid:response.data.uuid

      })
  }

  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "0px 0px 0px 0px",
            fontSize: "2rem",
            background:"rgba(255,255,255,0)",
            borderBottom:{
              xs:"0px solid #AAAAAA",
              sm:"0px solid #AAAAAA",
              md:"0px solid #AAAAAA"
            }
          }}
          xs={12}
          sm={12}
          md={9}
        >
           <AddressAutoComplete 
              getPlaces = {this.props.getPlaces}
              theme={this.props.theme}
              setAddress={this.setAddress}
            />
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "0px 0px 0px 0px",
            fontSize: "2rem",
            marginBottom:{
              xs:"0px",
              sm:"0px",
              md:"0px",
              lg:"0px",
            },
            background:"rgba(255,255,255,0)",
            borderBottom:{
              xs:"1px solid #AAAAAA",
              sm:"1px solid #AAAAAA",
              md:"0px solid #AAAAAA",
              },
              borderLeft:{
                xs:"0px solid #AAAAAA",
                sm:"0px solid #AAAAAA",
                md:"0px solid #AAAAAA",
                }
            }}
          xs={12}
          sm={12}
          md={3}
          >
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{          
          padding:"1px",
          borderBottom:"0px solid #AAAAAA",
          background: this.props.theme.bannerColor,
          width: "100%",
        }}
      >
                  <Button
                      onClick={this.state.address && !this.state.inProgress ? this.sendCompRequest : null}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border:"1px solid " + this.props.theme.buttonTextColor,
                          background:this.props.theme.buttonHoverColor
                        },
            
                        marginRight:"2px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 15px",
                          md: "15px 15px 8px 15px",
                          lg: "10px 15px 8px 15px",
                          xl: "10px 15px 8px 15px",
                        },
                        background:this.props.theme.bannerColor,
                        color:this.props.theme.buttonTextColor,
                        border:"1px solid " + this.props.theme.buttonTextColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:"74%",
                        float:"left"
                      }}
                    >
                      {this.state.inProgress ? 
                      <div>
                        <CircularProgress disableShrink size={16} sx={{
                          color: 'white',
                        }}></CircularProgress>
                      </div>
                      :
                        "Create Home Value Report"
                      }
                    </Button>


                    <Button
                      onClick={this.sendCompRequest}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border:"1px solid " + this.props.theme.buttonTextColor,
                          background:this.props.theme.buttonHoverColor
                        },
            
                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 15px",
                          md: "15px 15px 8px 15px",
                          lg: "10px 15px 8px 15px",
                          xl: "10px 15px 8px 15px",
                        },
                        background:this.props.theme.bannerColor,
                        color:this.props.theme.buttonTextColor,
                        border:"1px solid " + this.props.theme.buttonColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:"25%",
                        float:"left"
                      }}
                    >
                        Reset
                    </Button>
                    </Grid>

        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "60%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {tileBuilder}
          </Grid>
        </Grid>
        <Grid
              item
              sx={{
                width: "100%",
                height: {
                  xl: "5%",
                  lg: "5%",
                  md: "5%",
                  sm: "5%",
                },
                padding: "0px 20px 0px 20px",
                fontSize: {
                  md: ".6rem",
                  lg: ".7rem",
                  xl: ".7rem"
                },
                color:"#888888"
              }}
              xs={12}
            >

            * In our Onondaga county market I'm currently tracking pricing, sales trends and true local valuations for over 60,000 homes across over 40 communities.   Enter your address or the address of a home your interested in to see if it's one of the homes I have a true local report for.
            </Grid>        
        <Grid
          item
          sx={{
            padding:"5px",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "15%",
            },
            width: "100%",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme} />
        </Grid>
      </Grid>
    );
  };


  toggleGoogleMapType = () => {
    this.setState({
      googleMapType: this.state.googleMapType === "Goto Street View" ? "Goto Satalite View" : "Goto Street View"
    })
  }

  downloadCompReport = () => {
    if(this.state.target_compvalue){
      this.setState({ generateReport: true, alertDialogOpen: false }, () => {
        this.props.getHomeValueDownload(
          this.props.parentPage,
          this.state.target_number,
          this.state.target_street,
          this.state.target_town,
          this.state.target_taxid,
          this.homeValueReportCallback
          )});

      }
  }

  homeValueReportCallback  = (data) => {

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(data);
      
    // Create a new link element
    const link = document.createElement('a');

    // Set the link's href attribute to the URL
    link.href = url;

    // Set the link's download attribute to the desired file name
    link.download = "homevalue-report";

    link.target = '_blank';
    
    // Click the link to initiate the download
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);

    this.setState({inProgress:false, generateReport:false})
  }


  closeAlertDialog = () => {

    this.setState({
      alertDialogOpen: false
    })

}


  getHomeValueTiles = () => {
     return (
            <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  md:"10px 20px 10px 20px",
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftIcon={null}
                tagTopLeftTxt={
                  this.state.googleMapType === "Goto Street View" ? "Goto Satalite View" : "Goto Street View"
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"white"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}
                tagTopLeftCallback={this.toggleGoogleMapType}                                
                topPadding={"5px"}
                topPictureOpacity={this.state.target_address ? 1 : 1}
                topPicture={
                  (!this.state.target_address || !this.state.inProgress) && this.state.googleMapType === "Goto Street View" 
                      ? 
                        ("general/gray-house-street.png")
                      : (!this.state.target_address || !this.state.inProgress) && this.state.googleMapType === "Goto Satalite View"
                      ?
                        ("general/gray-house-satalite.png")
                      : null
                    }
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={null}
                topWidget={
                  this.state.target_address && !this.state.inProgress && this.state.googleMapType === "Goto Street View" 
                  ? <Image src={"data:image/png;base64," + this.state.streetImage} fit="fit" bgColor="rgba(255,255,255,1)" easing="linear" duration={500} />
                  : this.state.target_address && this.state.googleMapType === "Goto Satalite View"
                  ? <Image src={"data:image/png;base64," + this.state.sataliteImage} fit="fit" bgColor="rgba(255,255,255,1)" easing="linear" duration={500} />
                  : null                  
                }
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["250px", "55%", "55%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={"Home Details"}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxtLineHeight={"1.5"}
                btmPictureSize={"contain"}
                btmTxt={

                  this.state.target_address && !this.state.inProgress?
                  <Grid container sx={{width:"100%", height:"100%", padding:"0px"}} >
                  <Grid item xs={2} sx={{textAlign:"center"}}><center><Image src="mapmarkers/target-marker-transp.png" fit="contain" width={30} height="50px"  duration={325} /></center></Grid>
                  <Grid item xs={10} sx={{lineHeight:"1.25", fontSize:"1rem"}}><b>{this.state.target_address}</b><br/><b>{this.state.target_town}</b><br/>{this.state.target_details}<br/>{this.state.target_extras.replace(/  /g, " | ")}<br/>{this.state.target_lastsold}</Grid>
                  <Grid item xs={12} sx={{lineHeight:"1", fontSize:".75rem"}}><center><i>Image courtesy google maps, may not be current.<br/>Home details courtesy of ongov.net, may not reflect all details.</i></center></Grid>
                  </Grid>                
                  :
                  <Grid container sx={{fontSize:"1rem", width:"100%", height:"100%", padding:"0px"}} >
                      <Grid item xs={11}>Enter the homes address in the text fields above.
                      I'll look for the google street view of the house and
                      the homes inside details (size, bedrooms, bathrooms)</Grid>
                  </Grid>                
                }
                btmWidget={null}             
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["150px", "35%", "35%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
                topProgress={this.state.inProgress}
                topProgressSize={40}
                topProgressColor={null}
              />
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  md:"10px 20px 10px 20px",
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={null}
                tagTxtColor={"black"}
                tagTxtSize={["1rem", "1rem", "1rem"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={!this.state.map_image? "general/map-gray2.png" : null}
                topPictureAlign={"center"}
                topPictureSize={"100% 100%"}
                topTxt={null}
                topWidget={
                  this.state.map_image && !this.state.inProgress
                  ? <Image src={"data:image/png;base64," + this.state.map_image} fit="fit"  bgColor="rgba(255,255,255,1)" easing="linear" duration={1000} />
                  : null                  
                }                
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["250px", "55%", "55%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={"Similar Homes Sold Nearby"}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxtLineHeight={"1.5"}
                btmTxt={
                  this.state.comp1_address && !this.state.inProgress?
                    <Grid container sx={{widht:"100%", height:"100%", padding:"0px", lineHeight:"1"}} >
                      <Grid item xs={1}><Image src="mapmarkers/marker-dkgreen-comp1.png" fit="contain" width={20} height="30px"  duration={325} /></Grid><Grid item xs={11}><b>{this.state.comp1_address}</b><br/>{this.state.comp1_details} | {this.state.comp1_lastsold}</Grid>
                      <Grid item xs={1}><Image src="mapmarkers/marker-dkgreen-comp2.png" fit="contain" width={20} height="30px"  duration={325} /></Grid><Grid item xs={11}><b>{this.state.comp2_address}</b><br/>{this.state.comp2_details} | {this.state.comp2_lastsold}</Grid>
                      <Grid item xs={1}><Image src="mapmarkers/marker-dkgreen-comp3.png" fit="contain" width={20} height="30px"  duration={325} /></Grid><Grid item xs={11}><b>{this.state.comp3_address}</b><br/>{this.state.comp3_details} | {this.state.comp3_lastsold}</Grid>
                  </Grid>
                  :
                  <Grid container sx={{fontSize:"1rem", width:"100%", height:"100%", padding:"0px"}} >
                      <Grid item xs={11}>I'll show you a local street map with up to
                      three homes that have recently sold, are nearby and
                      are similar in size to the home your interested in.</Grid>
                  </Grid>
                }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={[".85rem", ".75rem", ".85rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["200px", "35%", "35%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
                topProgress={this.state.inProgress}
                topProgressSize={40}
                topProgressColor={this.state.inProgress}

              />
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  md:"10px 20px 10px 20px",
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={
                  this.state.target_compvalue ? "Download Your Free True Local Report" : "Download Your Free True Local Report"
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.state.inProgress || this.state.generateReport ? "#A0A0A0" : this.state.target_compvalue ? this.props.theme.bannerTextColor : "#A0A0A0"}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.state.inProgress || this.state.generateReport ? "#EEEEEE" : this.state.target_compvalue ? this.props.theme.bannerColor : "#EEEEEE"}
                tagTopLeftBorder={"0px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}
                tagTopLeftCallback={this.state.inProgress || this.state.generateReport ? null : this.downloadCompReport}                                
                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={"cover"}
                topTxt={null}
                topWidget={null}
                
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["50px", "10%", "10%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={this.state.comp1_address && !this.state.inProgress ? "downloads/comp-report.png" : "downloads/comp-report-gray.png"}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#F0F0F0"}
                mdlBorder={"0px solid black"}
                mdlHeight={this.state.comp1_address ? ["350px", "70%", "70%"] : ["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxtLineHeight={"1.25"}
                btmTxt={
                  this.state.comp1_address && !this.state.inProgress ?
                  <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                    <Grid item xs={12}>
                      <center>
                      <div>
                        <div style={{fontSize:"1rem"}}><b>{this.state.target_address}</b></div>
                        <div style={{fontSize:"1.75rem"}}><b>{this.state.target_compvalue}</b></div>
                        <div style={{fontSize:".75rem"}}>This Is An Estimated Value Based On Local Available Data</div>
                        
                      </div>
                      </center>
                    </Grid>
                </Grid>
                :
                <Grid container justifyContent="center" alignItems="center" sx={{fontSize:"1rem", width:"100%", height:"100%", padding:"0px"}} >
                <Grid item xs={11}>
                  Free True Local Valuation & Market Report<br/>
                    
                </Grid>                
                </Grid>                
                }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".75rem", ".75rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"center"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"flex-end"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={ this.state.comp1_address ? ["50px", "15%", "20%"] : ["50px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
                mdlProgress={this.state.inProgress || this.state.generateReport}
                mdlProgressSize={40}
                mdlProgressColor={this.state.inProgress}

              />
            </Grid>




            </React.Fragment>
          );
  };

  
  render() {

    return (
      <Drawer
          anchor="bottom"
          open={this.props.showHomeValue}
          onClose={this.props.toggleHomeValue}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem"
                },
                color:this.props.theme.bannerTextColor
                      
              }}
            >
              Home Valuation              
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("HomeValue")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleHomeValue}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.homeValueSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top HomeValueage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getHomeValueTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
            
        </Grid>
      </Grid>
      <AlertDialog theme={this.props.theme} open={this.state.alertDialogOpen} yesButtonLabel="Ok" yesButtonAction={this.closeAlertDialog} title="We're Building Your Report" message="Generating your report will take about 10 seconds.  Your download will start automatically when your report is ready." />
      </Drawer>
    );
  }
}

export default HomeValue;
