import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import _ from "lodash";


class Cookies extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

  }

  componentDidMount() {
  
  }

  
  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showCookies}
          onClose={this.props.toggleCookies}
          transitionDuration={1500}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "20%",
                sm: "20%",
                md: "20%",
                lg: "20%",
                xl: "20%",
              },
              width: {
                xs: "90%",
                sm: "90%",
                md: "30%",
                lg: "30%",
                xl: "30%",
              },
              border:"2px solid #333333",
              marginLeft: "auto",
              marginRight:"5px",
              marginBottom:"5px",
              pointerEvents:"auto",
              background:"#F5F5F5",
              borderRadius:"5px"
            },
          }}
        >

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "0px solid #333333",
        }}
      >

          <Grid
            item
            sx={{
              background: "#F5F5F5",
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "35%",
                sm: "35%",
                md: "35%",
                lg: "35%",
                xl: "35%",
              },
            }}
            xs={12}
            md={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={this.props.toggleCookies}
                variant="contained"
                sx={{
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Accept & Close
              </Button>
              
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "10px",
                height: {
                  xl: "65%",
                  lg: "65%",
                  md: "65%",
                  sm: "65%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "1rem",
                border: "0px solid red",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

            We use cookies to provide necessary website security and functionality. By continuing, you agree to our use of cookies.
            </Grid>
        </Grid>
      </Grid>
      </Drawer>
          );
  }
}

export default Cookies;
