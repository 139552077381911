import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';

//import axios from 'axios'; // For making HTTP requests

class AddressAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      addressValue:""
    };
    this.debouncedSearch = debounce(this.handleSearch, 600); // Adjust debounce delay as needed

  }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ addressValue: value })
        this.debouncedSearch(value);
    }

    handleSearch = (value) => {
        // Perform your search operation here
        this.props.getPlaces("HomeValue", value, this.callback)
      };

    handleSelect = (event, value) => {
        if(value){
            this.setState({ selectedValue: value['description'] });
            this.props.setAddress(value['description'])
        }
    };

    callback = (response) => {

        if(response && response.data && response.data.places){    
            const namesArray = response.data.places.map(obj => obj.description);

            this.setState({
                options: response.data.places
            })
        }
    }

  render() {
    return (
      <Autocomplete

        id="address-autocomplete"
        variant="filled"
        size="large"
        sx={{
            width: {
              xs:"100%"
             },
             padding:{
              xs:"0px",
              xs:"0px",
              md:"0px",
              lg:"0px 0px 0px 0px",
              xl:"0px 0px 0px 0px"
             },
             border:"1px solid " + this.props.theme.bannerTextColor,
             backgroundColor: this.props.theme.bannerTextColor,
          }}
        InputProps={{
            style: {
                borderRadius:"10px",
            },
        }}
        options={this.state.options}
        getOptionLabel={(option) => option.description}
        renderInput={(params) => <TextField {...params} size="small" variant="filled" label="Enter Address" />}
        onInputChange={this.handleInputChange}
        onChange={this.handleSelect}
      />
    );
  }
}

export default AddressAutoComplete;
