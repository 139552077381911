import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import WeekdaySelect from "./WeekdaySelect";
import CommonTile from "./CommonTile";
import ReactPlayer from 'react-player'

class VideoShowDialog extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      videoIndex: 0,
      requestShowing: false

    }
  }

  toggleRequestShowing = () => {
      this.setState({
        requestShowing: !this.state.requestShowing
      })    
  }

  nextVideo = (id) => {
    
    if(this.props.listing.pictures && this.props.listing.pictures.length > 0){
      if(this.state.videoIndex < this.props.listing.pictures.length){
        this.setState({
          videoIndex: this.state.videoIndex+1
        })
      }else{
        this.setState({
          videoIndex: 0
        })
      }
    }
  }

  previousVideo = (id) => {
    if(this.props.listing.pictures && this.props.listing.pictures.length > 0){
      if(this.state.videoIndex > 0){
        this.setState({
          videoIndex: this.state.videoIndex-1
        })
      }else{
        this.setState({
          videoIndex: this.props.listing.pictures.length
        })
      }
    }
  }

  getVideoToDisplay = () => {

    if(this.props.listing){
      if(this.state.videoIndex === this.props.listing.pictures.length){
        return null
      }else if(this.props.listing.pictures  && this.props.listing.pictures.length > 0){
         return this.props.listing.pictures[this.state.videoIndex]
      }else{
        return null
      }
    }else{
      return null
    }
  }


  getSendEmailTile = () => {
    return (
      <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%"},
                overflowY:"auto"       
              }}
            >
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            overflowX:"hidden",
            background: "#FFFFFF",
            border:"0px solid #333333"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  
                  width: { md: "100%", xs: "100%" },
                  padding: "0px",
                }}
              >
                <Grid
                  item
                  sx={{
                    height:"200px",
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"center",
                    fontSize:"1.5rem"
                    
                  }}
                  xs={8}
                >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height:"200px",
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    borderBottom:"1px solid #AAAAAA",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1.25rem",
                      xl:"1.25rem",
                    }
                  }}
                >
                  Thank You For Reaching out!<br/> Please tell me a little bit about yourself and your availability for a showing.<br/><br/>  I'll be in touch soon !
                </Grid>
                </Grid>

                <Grid                
                  item
                  sx={{
                    height:"200px",
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"right",
                    borderBottom:"1px solid #AAAAAA",
                    borderRight:"1px solid #AAAAAA",
                    }}
                  xs={4}
                >
                  <Box
                    component="img"
                    src="profilepics/profile-2a.png"
                    alt="Image description"
                    sx={{
                      height: "200px",
                      objectFit: 'contain',
                    }}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Your Name"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Phone Number"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Email Address"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "10px",
                    fontWeight: "normal",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                  xs={12}
                >
                  When would a showing work for you?
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    
                  }}
                  xs={12}
                >
                  <WeekdaySelect
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    
                  }}
                  xs={12}
                >
                  <WeekdaySelect
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    paddingLeft: "0px",
                    paddingTop: "5px",
                    fontWeight: "normal",
                    borderTop: "1px solid #AAAAAA",
                    textAlign:"left",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                  xs={12}
                >
                  <div>
                    Hi Donna,<br />
                    <blockquote>
                      My name is <b>Roger Nestler</b>, I'm intested in a showing for<br/>
                      <br/>
                      <b>1234 Main Street Lane, Baldwinsville NY 13027</b><br/>
                      <br/>
                      Please contact me at:<br/>
                      <b>315-247-3314</b> or <b>roger.nestler@gmail.com.</b>
                      <br />
                      <br />I Have the below days and times availabe.
                      <br/>
                      <b>Monday</b> at <b>11:00AM</b>, <b>6:00PM</b><br/>
                      <b>Saturday</b> at <b>1:00PM</b>, <b>2:00PM</b>, <b>3:00PM</b>

                    </blockquote>
                    Thanks
                    <br />
                    <br />
                  </div>
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                  xs={12}
                >
                  <Button
                    onClick={this.sendContactCard}
                    sx={{
                      textTransform: "none",
                      padding: "7px",
                      width: "100%",
                      height: "100%",
                      borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                      fontSize: {
                        xs: ".75rem",
                        sm: ".75rem",
                        md: ".75rem",
                        lg: "1rem",
                        xl: "1rem"
                      }     
                    }}
                    size="small"
                    variant="contained"
                  >
                    {this.state.contactSent
                      ? "Thank You, I'll be touch with soon  -Donna"
                      : "Click To Send"}
                  </Button>
                </Grid>
              </Grid>
          </Grid>
      </Grid>
    );
  };




  render() {
    const {open, onClose, children } = this.props;

    return (
      <Dialog open={open} onClose={onClose} 
        
        sx={{height:"95dvh", overflowY:"hidden", '& .MuiDialog-paper': {
          width: {
            xs:'100vw',
            sm:'100vw',
            md:'80vw',
            lg:'60vw',
            xl:'60vw',
          },
          maxWidth: 'unset',
        }  }}      
        >
        <DialogContent  sx={{maxHeight:'85dvh', minHeight:'85dvh',padding:"0px" , overflowY:"hidden",             
 background:"rgba(255,55,55,0)"}}> 
        <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background:"rgba(55,55,55,0)",
                height: "85dvh",
                padding: "0px",  
                overflowY:"hidden"             
              }}
            >

          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              color:this.props.theme.bannerTextColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "0px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              overflowY:"hidden",             
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={1}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "0%" },
                paddingLeft: "10px",  
                fontSize:{
                  xs:"0rem",
                  sm:"0rem",
                  md:".8rem",
                  lg:".8rem",
                  xl:".8rem",
                }             
              }}
            >
              
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              color:this.props.theme.bannerTextColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "0px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={11}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                overflowY:"hidden",             
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingRight: "5px",
              }}
            >
                <Button
                  onClick={this.props.onClose}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                      '&:hover': {
                        border:"1px solid " + this.props.theme.buttonTextColor,
                        background:this.props.theme.buttonHoverColor
                      },       
                    marginRight: "5px",
                    borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                    textTransform: "none",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize: {
                      xs: ".75rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem"
                    }                  
                  }}
                >
                  Close
                </Button>
             
              
            </Grid>
          </Grid>                    
            <Grid
              item
              sx={{
                background:"rgba(255,255,255,0)",
                width: { md: "100%", xs: "100%" },
                overflowY : "hidden",            
                
                padding: this.state.requestShowing ? "0px" : "10px",
                height: {
                  xs: "88%",
                },
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {
              this.state.requestShowing ?
                this.getSendEmailTile()
              :
              <CommonTile
                uid={0}
                animationType={null}
                animationTime={null}

                topPadding={"0px"}
                topWidget={null}
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={
                      <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        background:"rgba(55,55,55,0)",
                        height: "100%",
                        width: "100%",
                        padding: "0px",  
                        overflowY:"hidden"             
                      }}
                    >
                     <ReactPlayer
                      playing={true}
                      controls={true}
                      muted={true}
                      url='videos/685idlewood.mp4'
                      width='85%'
                      height='70%'
                    />
                    </Grid>
                }
                topCallback={null}
                topTxtColor={"white"}
                topTxtSize={["12px", "12px", "32px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgbs(255,255,255,0.5)"}
                topHeight={["400px", "100%", "100%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["12px", "12px", "16px"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"5px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["16px", "16px", "16px"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#f0f0f0"}
                mdlBorder={"0px solid black"}
                mdlHeight={["0px", "0%", "0%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={null}
                btmTxtColor={"#333333"}
                btmTxtSize={["16px", "16px", "16px"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["0px", "0%", "0%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"0px solid black"}
                shadow={0}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            }
            </Grid>    
        </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default VideoShowDialog;
