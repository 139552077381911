import React, { Component } from 'react';
import Box from "@mui/material/Box";

class ImageClickCoordinates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      boundingBox_left : 0,
      boundingBox_top :0
    };

    this.coords = [
        [264.7875061035156 , 25.993743896484375],
        [175.78750610351562 , 126.99374389648438],
        [310.7875061035156 , 100.99374389648438],
        [278.7875061035156 , 131.99374389648438],
        [264.7875061035156 , 169.99374389648438],
        [350.7875061035156 , 150.99374389648438],
        [51.787506103515625 , 236.99374389648438],
        [139.78750610351562 , 227.99374389648438],
        [213.78750610351562 , 243.99374389648438],
        [261.7875061035156 , 295.9937438964844],
        [167.78750610351562 , 302.9937438964844],
        [277.7875061035156 , 481.9937438964844],
        [223.78750610351562 , 396.9937438964844],
        [338.7875061035156 , 365.9937438964844],
        [330.7875061035156 , 418.9937438964844],
        [405.7875061035156 , 412.9937438964844],
        [396.7875061035156 , 329.9937438964844],
        [486.7875061035156 , 234.99374389648438],
        [360.7875061035156 , 195.99374389648438],
        [382.7875061035156 , 295.9937438964844],
        [346.7875061035156 , 269.9937438964844],
        [407.7875061035156 , 256.99374389648438],
        [400.7875061035156 , 224.99374389648438],
        [307.7875061035156 , 227.99374389648438]
    ]

    this.screen_adjustment = {
        "xs": {"x":.55,"y":.55},
        "sm": {"x":.55,"y":.55},
        "md": {"x":1,"y":1},
        "lg": {"x":.8,"y":.8},
        "xl": {"x": 1 , "y":1},           
    }

    this.screen_tolerance = {
        "xs": {"x":30,"y":15},
        "sm": {"x":0,"y":0},
        "md": {"x":40,"y":15},
        "lg": {"x":40,"y":15},
        "xl": {"x": 40 , "y":15},           
    }

    this.image_adjustment = {
        "xs": {"x":10,"y":15},
        "sm": {"x":10,"y":15},
        "md": {"x":15,"y":25},
        "lg": {"x":12,"y":20},
        "xl": {"x": 15 , "y":25},           
    }


    this.towns = {
        "North":
            {
                "x": this.coords[0][0], "y": this.coords[0][1]
            },
        "Baldwinsville":
            {
                "x": this.coords[1][0], "y": this.coords[1][1]
            },
        "Brewerton":
            {
                "x": this.coords[2][0], "y": this.coords[2][1]
            },
        "Clay":
            {
                "x": this.coords[3][0], "y": this.coords[3][1]
            },
        "Liverpool":
            {
                "x": this.coords[4][0], "y": this.coords[4][1]
            },
        "Cicero":
            {
                "x": this.coords[5][0], "y": this.coords[5][1]
            },
        "West":
            {
                "x": this.coords[6][0], "y": this.coords[6][1]
            },
        "Eldbridge":
            {
                "x": this.coords[7][0], "y": this.coords[7][1]
            },
        "Camillus":
            {
                "x": this.coords[8][0], "y": this.coords[8][1]
            },
        "Onondaga":
            {
                "x": this.coords[9][0], "y": this.coords[9][1]
            },
        "Skaneateles":
            {
                "x": this.coords[10][0], "y": this.coords[10][1]
            },

        "South":
           {
            "x": this.coords[11][0], "y": this.coords[11][1]
            },
        "Spafford":
            {
                "x": this.coords[12][0], "y": this.coords[12][1]
            },
        "Lafayette":
            {
                "x": this.coords[13][0], "y": this.coords[13][1]
            },
        "Tully":
            {
                "x": this.coords[14][0], "y": this.coords[14][1]
            },
        "Fabius":
            {
                "x": this.coords[15][0], "y": this.coords[15][1]
            },
        "Pompey":
            {
                "x": this.coords[16][0], "y": this.coords[16][1]
            },

        "East":
            {
                "x": this.coords[17][0], "y": this.coords[17][1]
            },
        "Minoa":
            {
                "x": this.coords[18][0], "y": this.coords[18][1]
            },
        "Jamesville":
            {
                "x": this.coords[19][0], "y": this.coords[19][1]
            },
        "DeWitt":
            {
                "x": this.coords[20][0], "y": this.coords[20][1]
            },
        "Manlius":
            {
                "x": this.coords[21][0], "y": this.coords[21][1]
            },
        "Fayetteville":
            {
                "x": this.coords[22][0], "y": this.coords[22][1]
            },


        "Syracuse":
            {
                "x": this.coords[23][0], "y": this.coords[23][1]
            },

    }
  }

  handleClick = (e) => {
    const boundingBox = this.image.getBoundingClientRect();
    const x = e.clientX - boundingBox.left;
    const y = e.clientY - boundingBox.top;
    this.setState({     
        "x": x,
        "y":y,
        "boundingBox_left": boundingBox.left,
        "boundingBox_top":boundingBox.top
    
    });

    
    const keys = Object.keys(this.towns);
    for (let i = 0; i < keys.length; i++) {
        const town_name = keys[i];
        const coord_x = this.towns[town_name]["x"] * this.screen_adjustment[this.props.screenSize]["x"];
        const coord_y = this.towns[town_name]["y"] * this.screen_adjustment[this.props.screenSize]["y"];

        const tolerance_x = this.screen_tolerance[this.props.screenSize]["x"]
        const tolerance_y = this.screen_tolerance[this.props.screenSize]["y"]

        if((coord_x - tolerance_x < x && coord_x + tolerance_x > x) && (coord_y - tolerance_y < y && coord_y + tolerance_y > y)){
            this.props.imageClickCallback(town_name)
        }        
    }
    
  }



  render() {
    
    return(
        <Box sx={{fontSize:".8rem"}}>
            <Box
            component="img"
            src="towns/onondaga-detailed-directions.png"
            alt="Image description"

            ref={(img) => (this.image = img)}
            onClick={this.handleClick}
            sx={{
                display:"relative",
                height:{
                    xs:"275px",
                    sm:"275px",
                    md:"500px",
                    lg:"400px",
                    xl:"500px",
                },
                
                objectFit: 'contain',
                border:"0px solid black"
            }}>

            </Box>

            {
            this.props.screenSize !== "Unknown" ? 
                this.props.selectedTowns.map((town, index) => (
                    <Box
                    component="img"
                    src="logos/loveit-logo.png"
                    alt="Image description"
                    sx={{
                        position:"absolute",
                        display:"block",
                        left :this.screen_adjustment[this.props.screenSize]["x"] * this.towns[town]["x"]  + this.state.boundingBox_left -this.image_adjustment[this.props.screenSize]["x"], 
                        top: this.screen_adjustment[this.props.screenSize]["y"] * this.towns[town]["y"]  + this.state.boundingBox_top - this.image_adjustment[this.props.screenSize]["y"],
                        height:{
                            xs:"15px",
                            sm:"15px",
                            md:"20px",
                            lg:"17px",
                            xl:"20px",
                        },
                        
                        border:"0px solid black"
                    }}>

                    </Box>
            ) )  
            : <box></box>
        }

        </Box>
    )
  }
}

export default ImageClickCoordinates;


// <p>Click coordinates ({this.props.screenSize}) : X: {this.state.x}, Y: {this.state.y}</p>




