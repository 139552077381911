import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import _ from "lodash";


class BuyingAHome extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",
      buyingAHomeTiles: [
          {
            id: "1",
            image: "checklist-househunting2.png",
            name: "Your Resources",
            summary:
              "This webpage requires data that you entered earlier in order to be properly displayed. You can send this data again.",
            download: true,
          },
          {
            id: "1",
            image: "checklist-financing2.png",
            name: "Design Help",
            summary:
              "This webpage requires data that you entered earlier in order to be properly displayed. You can send this data again.",
            download: true,
          },
         
      ],      
    }
  }


  toggleBuyingAHome = () => {
    this.setState({
      BuyingAHome: !this.state.BuyingAHome,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };

  getContactTile = () => {
    let tile = {
      id: "1",
      image: "contact-profile.png",
      tag: null,
      name: "Contact Your Realtor",
      summary:
      "Have you heard, Howard Hanna can get you financed with a a great rate.  If you thinking about making a move give me a call so we can discuss your options and how we can get you into the home of your dreams.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: "20px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                background: "rgba(255,255,255,1)",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={tile.tag}
                tagTxtColor={"black"}
                tagTxtSize={["12px", "12px", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={tile.image}
                topPictureAlign={"100% 100%"}
                topPictureSize={"contain"}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["12px", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["200px", "60%", "60%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"flex-end"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={tile.name}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#CCCCCC"}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.summary}
                btmTxtColor={"black"}
                btmTxtSize={["1rem", ".75rem", "1rem"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.5"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["100px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };

  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }

  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#FFFFFF",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "20px",
            fontSize: "2rem",
          }}
          xs={12}
        >
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "70%",
              lg: "70%",
              md: "70%",
              sm: "70%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            {tileBuilder}
            {this.getContactTile()}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "20%",
              lg: "20%",
              md: "20%",
              sm: "20%",
            },
            padding: "20px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
          <Button
                onClick={() => this.downloadResources("checklist-closing-timeline.png")}
                variant="contained"
                sx={{
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Download The Buyers Resources
              </Button>
              </Grid>
        </Grid>
      </Grid>
    );
  };


  getBuyingAHomeTiles = () => {
    return (
      <React.Fragment>
        {this.state.buyingAHomeTiles.map((tile, index) => {
          return (
            <Grid
              item
              sx={{
                width: "100%",
                padding: "20px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                background: "rgba(255,255,255,1)",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={tile.tag}
                tagTxtColor={"black"}
                tagTxtSize={["12px", "12px", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={tile.image}
                topPictureAlign={"center"}
                topPictureSize={"contain"}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["12px", "12px", "12px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["200px", "60%", "60%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={tile.name}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#CCCCCC"}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.summary}
                btmTxtColor={"black"}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["100px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
          );
        })}
      </React.Fragment>
    );
  };

  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showBuyingAHome}
          onClose={this.props.toggleBuyingAHome}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "90%",
                sm: "90%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",
              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#F0F0F0",
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "1px solid #333333",
        }}
      >

      <Grid
            item
            sx={{
              background: "#F0F0F0",
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "10px",
                fontSize:{
                  xs:".75rem",
                  sm:".75rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",

                }               
              }}
            >
              Buying A Home
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: "#F0F0F0",
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant="contained"
                sx={{
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleBuyingAHome}
                variant="contained"
                sx={{
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "#F0F0F0",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  background: "#F0F0F0",
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.buyingAHomeSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top BuyingAHomeage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getBuyingAHomeTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default BuyingAHome;
