import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Speedometer from 'react-d3-speedometer';
import Slider from '@mui/material/Slider';
import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class MyServicesCommunities extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      selectedEntry:  -1,
      pictureHeight: "100%",
      pictureWidth: "60%",
      communities:[
        "Baldwsinville (Lysander)",
        "Clay",
        "Cicero",
        "Camillus",
        "Jamesville-Dewitt",
        "Jordan-Eldbridge",
        "Fabius",
        "Fayetteville-Manlius",
        "Geddes",
        "Marcellus",
        "Lafayette",
        "Onondaga",
        "Otisco",
        "Pompey",
        "Salina (Liverpool)",
        "Skaneateles",
        "Spafford",
        "Syracuse (City)",
        "Tully",
        "Van Buren",
      ],

      reportDate: "",
      medForSale: 0,
      medDaysOnMarket: 0,
      medPrice: 0,
      medSqf: 0,
      inProgres: false,

      MyServicesCommunitiesTiles: [
        {
          id: "1",
          image: "towns/onondaga-baldwinsville-lysander-shadow.png",
          name: "Baldwinsville",
          fullSize: true,
          summary:
            "A charming village/town setting situated along the banks of the Seneca River. The village is known for its picturesque setting and small-town atmosphere. With a rich history dating back to the early 19th century.  Baldwinsville offers a blend of historic architecture, scenic waterfront views, and a vibrant community.",
          download: "Baldwinsville",
        },
        {
          id: "1",
          image: "towns/onondaga-clay-shadow.png",
          name: "Clay",
          fullSize: true,
          summary:
            "Clay's blend of suburban charm, recreational opportunities, and access to urban amenities makes it an attractive place for families, professionals, and individuals looking for a well-rounded community experience. Whether enjoying the local parks, shopping at the mall, or participating in community events, residents of Clay find a welcoming and vibrant environment to call home.",
          download: "Clay",
        },
        {
          id: "1",
          image: "towns/onondaga-cicero-shadow.png",
          name: "Cicero",
          fullSize: true,
          summary:
            "One of Cicero's notable attractions is Oneida Lake, a large freshwater lake located to the north of the town. Boating, fishing, and recreational activities on the lake are popular among residents and visitors alike. Additionally, the town features parks and green spaces, including William Park, providing opportunities for outdoor recreation and community gatherings.",
          download: "Cicero",
        },
        {
          id: "1",
          image: "towns/onondaga-camilus-shadow.png",
          name: "Camillus",
          fullSize: true,
          summary:
            "Camillus, New York, is a picturesque town located in Onondaga County, southwest of Syracuse. Camillus is characterized by a mix of residential neighborhoods, parks, and commercial areas. The town is home to the Camillus Erie Canal Park, which showcases the historical significance of the Erie Canal in the development of the region. Visitors can explore the canal's towpath, locks, and the Nine Mile Creek Aqueduct, providing a glimpse into the town's heritage.",
          download: "Camillus",
        },
        {
          id: "1",
          image: "towns/onondaga-dewitt-shadow.png",
          name: "Jamesville-Dewitt",
          fullSize: true,
          summary:
            "DeWitt is home to several parks and green spaces, providing residents with ample opportunities for outdoor activities and community gatherings. One of the notable parks is the Butternut Creek Recreation and Nature Trail, offering scenic trails and natural beauty. The town's commitment to preserving green spaces contributes to a high quality of life for its residents.",
          download: "Jamesville-Dewitt",
        },
        {
          id: "1",
          image: "towns/onondaga-eldbridge-shadow.png",
          name: "Jordan-Eldbridge",
          fullSize: true,
          summary:
            "The town is known for its agricultural heritage, and the surrounding countryside features farms and open spaces. Elbridge is home to the Jordan-Elbridge Central School District, which serves the educational needs of local students. The community's commitment to education is evident in the engagement and support for school events and activities.",
          download: "Jordan-Eldbridge",
        },
        {
          id: "1",
          image: "towns/onondaga-fabius-shadow.png",
          name: "Fabius",
          fullSize: true,
          summary:
            "Fabius maintains a balance between its rural character and access to modern amenities. While the town offers a peaceful retreat from urban life, it is conveniently located near major transportation routes, allowing for easy access to neighboring communities. Residents can also enjoy local businesses, community events, and a sense of camaraderie that defines small-town living.",
          download: "Fabius",
        },
        {
          id: "1",
          image: "towns/onondaga-fayetteville-manlius-shadow.png",
          name: "Fayetteville-Manlius",
          fullSize: true,
          summary:
          "The town is bordered by green spaces, including parks and nature reserves. Green Lakes State Park, with its two glacial lakes and scenic trails, is a popular destination for outdoor activities such as hiking, swimming, and picnicking. The town's commitment to preserving green spaces enhances the quality of life for residents and contributes to the overall appeal of Fayetteville-Manlius.",

          download: "Fayetteville-Manlius",
        },
        {
          id: "1",
          image: "towns/onondaga-geddes-shadow.png",
          name: "Geddes",
          fullSize: true,
          summary:
            "Geddes is home to the New York State Fairgrounds, a prominent venue hosting the annual New York State Fair. The fair, held in late summer, attracts visitors from across the state with its diverse array of entertainment, agricultural displays, and cultural exhibits. The fairgrounds also host other events throughout the year, contributing to Geddes' cultural vibrancy.",          download: true,
           download: "Geddes",
          },
        {
          id: "1",
          image: "towns/onondaga-marcellus-shadow.png",
          name: "Marcellus",
          fullSize: true,
          summary:
            "Nature enthusiasts in Marcellus can explore the nearby Highland Forest, a vast expanse of woodlands offering hiking trails, cross-country skiing, and opportunities for birdwatching. The town also features Marcellus Park, providing recreational amenities such as sports fields, playgrounds, and a swimming pool.",
          download: "Marcellus",
        },
        {
          id: "1",
          image: "towns/onondaga-lafeyette-shadow.png",
          name: "Lafayette",
          fullSize: true,
          summary:
            "Agriculture plays a significant role in LaFayette, with local farms producing a variety of crops and products. The town hosts the LaFayette Apple Festival, an annual event that celebrates the fall harvest and showcases the region's agricultural bounty. This festival has become a beloved tradition, attracting visitors from the surrounding areas.",
          download: "Lafayette",
        },
        {
          id: "1",
          image: "towns/onondaga-onondaga-shadow.png",
          name: "Onondaga Hill",
          fullSize: true,
          summary:
          "Nestled in the scenic landscape of Central New York, Onondaga Hill provides residents with a tranquil setting while being in close proximity to the amenities of Syracuse.  Known for its residential neighborhoods, often characterized by a mix of housing styles and tree-lined streets.  Residents enjoy access to outdoor recreational opportunities, including the nearby Split Rock Quarry, a historic site with walking trails and scenic views.",

          download: "Onondaga",
        },
        {
          id: "1",
          image: "towns/onondaga-otisco-shadow.png",
          name: "Otisco",
          fullSize: true,
          summary:
            "Otisco, a town in Onondaga County, New York, is a small community known for its rural character and natural beauty.  Otisco Lake, one of the smaller Finger Lakes, serves as a focal point for the town. The lake provides residents with opportunities for boating, fishing, and other water-based activities. The surrounding hills and valleys add to the scenic beauty of the area, making Otisco a destination for those seeking a peaceful retreat.",

          download: "Otisco",
        },
        {
          id: "1",
          image: "towns/onondaga-pompey-shadow.png",
          name: "Pompey",
          fullSize: true,
          summary:
            "Nature enthusiasts in Pompey can explore Green Lakes State Park, a nearby natural gem offering hiking trails, two glacial lakes, and stunning scenery. The town also features recreational opportunities such as Highland Forest, providing outdoor activities like cross-country skiing, hiking, and camping.",
          download: "Pompey",
        },
        {
          id: "1",
          image: "towns/onondaga-salina-liverpool-shadow.png",
          name: "Salina (Liverpool)",
          fullSize: true,
          summary:
            "Liverpool is characterized by a blend of residential neighborhoods, parks, and commercial areas. Residents enjoy a sense of community, with various local events and festivals held throughout the year, fostering a strong neighborhood spirit. The village is home to the picturesque Onondaga Lake Park, a popular recreational spot offering walking and biking trails, as well as scenic views of Onondaga Lake.",
          download: "Liverpool-Salina",
        },
        {
          id: "1",
          image: "towns/onondaga-skaneateles-shadow.png",
          name: "Skaneateles",
          fullSize: true,
          summary:
          "Skaneateles, a picturesque village located in the Finger Lakes region of New York, is renowned for its small-town charm, historic significance, and scenic beauty. Nestled on the shores of Skaneateles Lake, one of the pristine Finger Lakes, the village offers residents and visitors alike a tranquil escape surrounded by rolling hills and crystal-clear waters.",

          download: "Skaneateles",
        },
        {
          id: "1",
          image: "towns/onondaga-spafford-shadow.png",
          name: "Spafford",
          fullSize: true,
          summary:
          "The town of Spafford is characterized by a charming blend of farmland and residential areas. Skaneateles Lake, one of the pristine Finger Lakes, borders Spafford and offers residents opportunities for outdoor recreation such as boating, fishing, and lakeside picnics. The town's natural beauty is complemented by its proximity to parks and green spaces, providing additional opportunities for residents to connect with nature.",

          download: "Spafford",
        },
        {
          id: "1",
          image: "towns/onondaga-syracuse-city-shadow.png",
          name: "Syracuse (City)",
          fullSize: true,
          summary:
          "Syracuse is a vibrant city with a rich history, diverse culture, and a significant economic presence in Central New York. The city is known for its historic neighborhoods, each with its own unique character. Armory Square, located in downtown Syracuse, is a trendy district filled with restaurants, shops, and entertainment venues. The historic buildings and cobblestone streets add to the area's charm, making it a popular destination for residents and visitors alike.",

          download: "Syracuse City",
        },
        {
          id: "1",
          image: "towns/onondaga-tully-shadow.png",
          name: "Tully",
          fullSize: true,
          summary:
          "Tully's historic downtown area exudes charm, with tree-lined streets, local shops, and eateries contributing to the town's inviting atmosphere. Tully Lake, a picturesque reservoir, and the nearby Tully Valley showcase the town's natural beauty.",

          download: "Tully",
        },
        {
          id: "1",
          image: "towns/onondaga-vanburen-shadow.png",
          name: " Van Buren",
          fullSize: true,
          summary:
          "The town encompasses a blend of residential neighborhoods, parks, and open spaces. Van Buren Park, situated along the western shore of Cross Lake, provides residents with opportunities for outdoor activities such as picnicking, hiking, and fishing. The Erie Canal, running through the southern part of the town, adds historical significance and scenic beauty to the area.",

          download: "Van Buren",
        },
      ],      
    }
  }

  getCommunityReport = () => {
    
    this.setState({ generateReport: true, alertDialogOpen: true }, () => {
        this.props.getCommunityReport(
        "Snapshot",
        this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].download,
        this.getCommunityReportCallback
        )});
  }
      
  getCommunityReportCallback  = (response) => {
    this.setState({
      reportDate:response.data.snapshot.RunDate,
      medForSale:response.data.snapshot.Count,
      medDaysOnMarket:response.data.snapshot.DaysOnMarket,
      medPrice:response.data.snapshot.ListPrice,
      medSqf: response.data.snapshot.LivingArea,

      Count_stat:response.data.snapshot.Count_stat,
      DaysOnMarket_stat:response.data.snapshot.DaysOnMarket_stat,
      ListPrice_stat:response.data.snapshot.ListPrice_stat,
      LivingArea_stat: response.data.snapshot.LivingArea_stat
    })
  }

  downloadCommunityReport = () => {
    
      this.setState({ inProgress: true, generateReport: true, alertDialogOpen: true }, () => {
          this.props.getCommunityDownload(
          this.props.parentPage,
          this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].download,
          this.downloadCommunityReportCallback
          )});

  }
        

  downloadCommunityReportCallback  = (data) => {

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(data);
      
    // Create a new link element
    const link = document.createElement('a');

    // Set the link's href attribute to the URL
    link.href = url;

    // Set the link's download attribute to the desired file name
    link.download = this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].download + "-community-report.jpg";

    link.target = '_blank';
    
    // Click the link to initiate the download
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);

    this.setState({inProgress:false, generateReport:false})
  }

  toggleMyServicesCommunities = () => {
    this.setState({
      MyServicesCommunities: !this.state.MyServicesCommunities,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };

  getSummaryTile = (selectedtile) => {
    let tile = {
      id: "1",
      image: "towns/onondaga-baldwinsville-lysander.png",
      tag: null,
      name: "Baldwinsville Housing Market",
      summary:
      "Expect multiple competitive offers with escalation and limited contingencies.",
      summary:[
        "Expect limited offers, below asking pice offers and strong contingencies.",
        "Expect offers at or below asking price, with strong contingencies.",
        "Expect offers at or below asking price, with strong contingencies.",

        "Expect multiple offers at or near fair market price with resonable contingencies.",
        "Expect multiple offers at or near fair market price with resonable contingencies.",
        "Expect multiple offers at or near fair market price with resonable contingencies.",

        "Expect multiple competitive offers, some with escalation and limited contingencies.",
        "Expect multiple competitive offers, some with escalation and limited contingencies.",
        "Expect very competitive offers above list price, with escalation and limited contingencies."
      ],

      market:[
        "Strong Buyers Market",
        "Moderate Buyers Market",
        "Moderate Buyers Market",
        "Neautral Market",
        "Neautral Market",
        "Neautral Market",
        "Moderate Sellers Market",
        "Moderate Sellers Market",
        "Strong Sellers Market",
      ],

      marketValue:[
        50,
        170,
        170,
        350,
        500,
        650,
        800,
        800,
        950
      ],

      download: true,
    }

    let market = Math.floor(Math.random() * 9)




    const market_range = [
      {
        value: 5,
        label: 'Less Than Others',
      },
      {
        value: 50,
        label: 'Similar',
      },
      {
        value: 95,
        label: 'More Than Others',
      },
    ];

    const formatValueLabel = (value) => {
            
      const numberString = value.toString();
    
      if (/\.01$/.test(numberString)) 
        return "Count"

      if (/\.02$/.test(numberString)) 
        return "Days"

      if (/\.03$/.test(numberString)) 
        return "Price"

      if (/\.04$/.test(numberString)) 
        return "SqF"

      return "--"

    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                fontSize:".75rem",
                textAlign:"left",
                color:"#888888"
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={tile.tag}
                tagTxtColor={"black"}
                tagTxtSize={["1rem", "1rem", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={"contain"}
                topTxt={this.state.selectedEntry >= 0 ? this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].name + " Market Snapshot" : "Market Snapshot"}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1.2rem", "1rem", "1.2rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={["50px", "10%", "10%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"10px"}
                mdlPicture={this.state.selectedEntry >= 0 ? this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].image : "towns/all-onondaga-boundaries-shadow.png" }
                mdlPictureAlign={"50% 50%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", ".75rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"rgba(255,255,255,0)"}
                mdlBorder={"0px solid black"}
                mdlHeight={["250px", "50%", "50%"]}
                mdlGraphType={"line"}
                mdlGraphColors={["red","blue","gold","green"]}
                mdlGraphData={null}
                mdlGraphLegend={false}
                mldGraphGrid={false}                
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={
                  <Grid container sx={{ width:"100%", borderTop:"0px solid #AAAAAA", height:"100%", paddingTop:"10px"}} >
                    <Grid item sx={{fontWeight:"bold", fontSize:".75rem", textAlign:"center"}} xs={3}>For Sale (Count)</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:".75rem", textAlign:"center"}} xs={3}>Med Days on Market</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:".75rem", textAlign:"center"}} xs={3}>Med Price</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:".75rem", textAlign:"center"}} xs={3}>Med SqF</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:"1.25rem",textAlign:"center"}} xs={3}>{parseInt(this.state.medForSale)}</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:"1.25rem",textAlign:"center"}} xs={3}>{parseInt(this.state.medDaysOnMarket)}</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:"1.25rem",textAlign:"center"}} xs={3}>${parseInt(this.state.medPrice).toLocaleString()}</Grid>
                    <Grid item sx={{fontWeight:"bold", fontSize:"1.25rem",textAlign:"center"}} xs={3}>{parseInt(this.state.medSqf).toLocaleString()}</Grid>
                    <Grid item sx={{height:"70%", fontWeight:"bold", borderTop:"0px solid #AAAAAA"}} xs={12}>
                      <Grid container 
                      justifyContent="center"
                      alignItems="center"
                      sx={{width:"100%", height:"100%", padding:"0px", border:"0px solid black"}} >

                        <Grid item sx={{border:"0px solid red", width:"100%", height:"100%", fontWeight:"bold"}} 
                        xs={10}
                        md={10}
                        >
                          <Grid container 
                          justifyContent="center"
                          alignItems="flex-end"
                          sx={{width:"100%", height:"100%", padding:"0px"}} >

                              <Slider
                                getAriaLabel={() => 'Market Range'}
                                orientation="horizontal"
                                value={[this.state.Count_stat, this.state.DaysOnMarket_stat, this.state.ListPrice_stat, this.state.LivingArea_stat]}
                                valueLabelDisplay="on"
                                valueLabelFormat={formatValueLabel}
                                marks={market_range}
                                track={false}
                                sx={{
                                  '& .MuiSlider-valueLabel': {
                                    fontSize: "10px", // Set the font size here
                                    background:this.props.theme.textColor,
                                    padding:"5px"
                                  },
                                  '& .MuiSlider-markLabel': {
                                    fontSize: '10px', // Adjust the font size as needed
                                  },
                                  color:this.props.theme.textColor,
                                }}
                              />

                            {/*
                              <Speedometer
                                    needleHeightRatio={0.7}
                                    value={tile.marketValue[market]}
                                    width={150}
                                    customSegmentStops={[0, 250, 750, 1000]}
                                    segmentColors={['#b0deb6', '#61bd6d', '#b0deb6']}
                                    currentValueText={tile.market[market]}
                                    labelFontSize=".85rem"
                                    valueTextFontSize=".85rem"
                                    customSegmentLabels={[
                                      {
                                        text: '',
                                        position: 'INSIDE',
                                        color: '#333333',
                                      },
                                      {
                                        text: '',
                                        position: 'INSIDE',
                                        color: '#333333',
                                      },
                                      {
                                        text: '',
                                        position: 'INSIDE',
                                        color: '#333333',
                                        size:".5rem"
                                      },
                                    ]}
                                    ringWidth={30}
                                    needleTransitionDuration={3333}
                                    needleTransition="easeElastic"
                                    needleColor={'#333333'}
                                    textColor={'#333333'}
                                />
                                  */}
                            </Grid>
                      </Grid>
                      {/*
                      <Grid item sx={{height:"30%", width:"100%", fontSize:{md:"1rem", lg:"1rem"}}} xs={12}>
                        <Grid container 
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{width:"100%", height:"100%", padding:"0px", lineHeight:"1"}} >
                                    {tile.market[market]} : {tile.summary[market]}
                         </Grid>
                        </Grid>
                      */}
                      </Grid>
                      
                      </Grid>
                    </Grid>
                  }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["15px", ".75rem", ".85rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "40%", "40%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              &nbsp;&nbsp;Snapshot Date {this.state.reportDate}
            </Grid>
      </React.Fragment>
    );
  };

  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
                <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "5px",
            fontSize: "2rem",
            borderBottom: "0px solid #333333",
          }}
          xs={12}
        >


          </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{              
              height: "100%",
              width: "100%",
            }}
          >

            {this.getCommunityListTile()}
            {this.getSummaryTile(this.state.selectedEntry ? this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1] : null)}
            {this.getCommunityInfoTile(this.state.selectedEntry ? this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1]: null)}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };

  getCommunityListTile = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",

                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["1rem", "1rem", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"Local Communities"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.2rem", "1.25rem", "1.25rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={
              <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
              <Grid item xs={6}>
              <Grid container sx={{width:"100%", height:"100%", padding:"0px"}} >
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 1 }, () => this.getCommunityReport() )}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 1 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 1 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[0]}</Grid></Grid>
                <Grid item 
                      onClick={() => {this.setState({selectedEntry: 2 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 2 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 2 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[1]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 3 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 3 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 3 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[2]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 4 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 4 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 4 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[3]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 5 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 5 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 5 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[4]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 6 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 6 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 6 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[5]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 7 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 7 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 7 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[6]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 8 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 8 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 8 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[7]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 9 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 9 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 9 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[8]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 10 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 10 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 10 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[9]}</Grid></Grid>
              </Grid>
              </Grid>

              <Grid item sx={{paddingLeft:"5px" }} xs={6}>
              <Grid container sx={{width:"100%", height:"100%", padding:"0px"}} >
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 11 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 11 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 11 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[10]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 12 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 12 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 12 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[11]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 13 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 13 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 13 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[12]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 14 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 14 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 14 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[13]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 15 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 15 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 15 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[14]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 16 }, () => this.getCommunityReport())}}
                  sx={{
                    height:"10%",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 16 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 16 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[15]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 17 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 17 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 17 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[16]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 18 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 18 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 18 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[17]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 19 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 19 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 19 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[18]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 20 }, () => this.getCommunityReport())}}
                  sx={{
                    height:{
                      xl:"10%",
                      lg:"10%",
                      md:"10%",
                      sm:"10%",
                      xs:"40px"
                    },
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },
                      fontWeight:"bold", 
                      background: this.state.selectedEntry === 20 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", 
                      border: this.state.selectedEntry === 20 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", 
                      padding:"2px 0px 2px 0px"}} xs={12}>
                        <Grid container sx={{width:"100%", height:"100%", padding:"0px"}}  alignItems="center" justifyContent="center">{this.state.communities[19]}
                      
                      </Grid>
                      </Grid>


              </Grid>
              </Grid>

          </Grid>
        
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", ".8rem", ".8rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["400px", "80%", "80%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              null           }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "10%", "10%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getCommunityInfoTile = (tile) => {
    return (
      <Grid
      item
      sx={{
        width: "100%",
        padding: {
          xs:"10px",
          sm:"10px",
          md:"10px",
          lg:"20px",
          xl:"20px"
        },
        height: {
          xl: "100%",
          lg: "100%",
          md: "100%",
          sm: "100%",
        },
        
      }}
      xl={4}
      lg={4}
      md={4}
      sm={6}
      xs={12}
    >
               <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={
                  this.state.inProgress ?                     
                  <div  style={{ padding: '0px 20px 0px 20px' }}>
                  <CircularProgress disableShrink size={12} sx={{color:this.props.theme.bannerTextColor, opacity:"1"}}/>
                  </div>
                  : this.state.selectedEntry >= 0 && tile.download ? "Download " + this.state.MyServicesCommunitiesTiles[this.state.selectedEntry-1].name : ""
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"0px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={["0px","20px","20px","0px"]}
                tagTopLeftCallback={!this.state.inProgress ? this.downloadCommunityReport : null}                                

                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["50px", "10%", "10%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={"downloads/my-services-community-report.jpg"}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"white"}
                mdlBorder={"0px solid black"}
                mdlHeight={["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                mdlProgress={this.state.inProgress}
                mdlProgressSize={40}
                mdlProgressColor={this.props.theme.bannerColor}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={"Call Me For An Indepth Discusson"}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"center"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["50px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              </Grid>
    );
  };

  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showCommunities}
          onClose={this.props.toggleCommunities}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "78%",
                sm: "77%",
                md: "73%",
                lg: "74%",
                xl: "74%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex,
              borderTop:"2px solid " + this.props.theme.bannerColor,

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.MyServicesCommunitiesSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top MyServicesCommunitiesage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default MyServicesCommunities;
