import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl, InputLabel, Select, ListItemText, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';

import _ from "lodash";


class Contact extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",

      inProgress: false,
      name: '',
      phoneNumber: '',
      email: '',
      message: '',
      selectedHelpItems:[],
      errors: {
        name: '',
        phoneNumber: '',
        email: '',
        message: '',
      },
      requestSent: false,
      enableSubmit: false,
    };
  }

  handleRecaptcha = (response) => {

    // Check the reCAPTCHA score
    const score = response ? response.score : 0;
    const isHuman = score >= 0.75; // Adjust the threshold as needed

    this.setState({
      enableSubmit: score >= .75 ? true : false 
    })

  }
  
  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    const errors = this.state.errors;

    errors[name] = ''

    this.setState({ [name]: newValue, errors: errors, requestSent:false});

  };  

  handleHelpItemChange = (event) => {
    this.setState({ selectedHelpItems: event.target.value });
  };

  sendContactCard = (event) => {

      event.preventDefault();
  
      const { name, phoneNumber, email, homeValuation, buyingAHome, sellingAHome, investmentPorperties, commercialRealestate, buildingAHome, relocation, jointheteam } = this.state;
      const errors = {};
  
      if (!name) {
        errors.name = 'Name is required';
      } else if (!/^[a-zA-Z0-9\s]+$/.test(name)) {
        errors.name = 'Name must be alphanumeric';
      }

     // Phone number validation
      if (!phoneNumber) {
        errors.phoneNumber = 'Phone number is required';
      } else {
        let strippedPhoneNumber = phoneNumber.replace(/-/g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\./g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\s/g, ''); // Remove dashes from the input

        if (!/^\d{10}$/.test(strippedPhoneNumber)) {
          errors.phoneNumber = 'Phone number must be 10 digits 123-456-7890';
        } else {
          // Format the phone number with dashes for display
          const formattedPhoneNumber = strippedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          this.setState({ phoneNumber: formattedPhoneNumber });
        }
      }
  
      // Email validation
      if (!email) {
        errors.email = 'Email address is required';
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.email = 'Invalid email address';
      }

      if (Object.keys(errors).length > 0) {
        this.setState({  errors: errors, requestSent:false });
      } else {
        // Form is valid, perform further actions or submit
        this.setState({ errors: errors, inProgress:true }, () => {
        this.props.connect(
          this.props.parentPage,
          this.state.name,
          this.state.phoneNumber,
          this.state.email,
          this.state.selectedHelpItems.includes("Home Valuation") ? true : false,
          this.state.selectedHelpItems.includes("Buying A Home") ? true : false,
          this.state.selectedHelpItems.includes("Selling A Home") ? true : false,
          this.state.selectedHelpItems.includes("Investment Properties") ? true : false,
          this.state.selectedHelpItems.includes("Commercial Real Estate") ? true : false,
          this.state.selectedHelpItems.includes("Building A Home") ? true : false,
          this.state.selectedHelpItems.includes("Relocation To Syracuse") ? true : false,
          this.state.selectedHelpItems.includes("Buying or Selling Land") ? true : false,
          this.state.selectedHelpItems.includes("Becoming A Realtor") ? true : false,
          this.callback
          )});
      }
  }

  callback = (data) => {
    this.setState({inProgress:false, requestSent:true, enableSubmit:false})
  }

  getSendEmailTile = () => {
    
    const { selectedHelpItems } = this.state;

    const helpItems = ['Home Valuation', 'Buying A Home','Selling A Home','Building A Home', 'Buying or Selling Land', 'Investment Properties','Commercial Real Estate','Relocation To Syracuse','Becoming A Realtor'];

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "0px",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            overflowY:"auto",
            overflowX:"hidden",
            background: "#FFFFFF",
            border:"0px solid #333333",

          }}
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
        >
          <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  borderRight:"1px solid #AAAAAA",
                  borderLeft:"1px solid #AAAAAA",
                  height:"100%",
                  width: { md: "100%", xs: "100%" },
                  padding: "0px",
                  background: this.props.theme.bannerTextColor,

                }}
              >
                <Grid
                  item
                  sx={{
                    height:{
                      xs:"200px",
                      sm:"200px",
                      md:"150px",
                      lg:"200px",
                      xl:"200px",
                    },
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"center",
                    fontSize:"1.5rem"
                    
                  }}
                  xs={7}
                  sm={8}
                  md={10}
                  lg={10}
                  xl={10}
                >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height:{
                      xs:"200px",
                      sm:"200px",
                      md:"150px",
                      lg:"200px",
                      xl:"200px",
                    },
                    width: { md: "100%", xs: "100%" },
                    paddingLeft: {
                      xs:"0px",
                      sm:"0px",
                      md:"100px"
                    },
                    borderBottom:"1px solid #AAAAAA",
                    fontSize:{
                      xs:".8rem",
                      sm:".75rem",
                      md:".8rem",
                      lg:"1rem",
                      xl:"1rem",
                    },
                    background:"#FFFFFF",
                    color:this.props.theme.bannerColor,
                  }}
                >
                  <br/>You Can Also Always Contact Me Directly At<br/><br/>  {this.props.phone} <br/> {this.props.email} 
                </Grid>
                </Grid>

                <Grid                
                  item
                  sx={{
                    height:{
                      xs:"200px",
                      sm:"200px",
                      md:"150px",
                      lg:"200px",
                      xl:"200px",
                    },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"right",
                    borderBottom:"1px solid #AAAAAA",
                    borderRight:"0px solid #AAAAAA",
                    background:"#FFFFFF",
                    color:this.props.theme.bannerColor,

                    }}
                    xs={5}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={2}
                  >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{
                    height:{
                      xs:"100%",
                    },
                    width: { md: "100%", xs: "100%" },
                  }}
                >

                  <Box
                    component="img"
                    src={"profilepics/" + this.props.profilepic}
                    alt="Image description"
                    sx={{
                      height:{
                        xs:"185px",
                        sm:"175px",
                        md:"150px",
                        lg:"200px",
                        xl:"200px",
                      },
                        objectFit: 'contain',
                    }}
                  />

                </Grid>

                </Grid>

                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"10%",
                    },

                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                      width: "100%" ,
                      borderRadius:"10px",
                      backgroundColor: this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                    }}                    
                    InputProps={{
                      style: {
                        borderRadius:"10px",
                        background:this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}

                    label="Your Name"
                    variant="filled"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.name)}
                    helperText={this.state.errors.name}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"10%",
                    }
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       borderRadius:"10px",
                       backgroundColor: this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                      InputProps={{
                        style: {
                          borderRadius:"10px",
                          background:this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                        },
                      }}

                    label="Phone Number"
                    variant="filled"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.phoneNumber)}
                    helperText={this.state.errors.phoneNumber}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"10%",
                    }
                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       borderRadius:"10px",
                       backgroundColor: this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                    InputProps={{
                        style: {
                          borderRadius:"10px",
                          background:this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                        },
                      }}

                    id="outlined-basic"
                    label="Email Address"
                    variant="filled"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.email)}
                    helperText={this.state.errors.email}
                  />
                </Grid>


                 <Grid
                  item
                  sx={{
                    padding: "5px",
                  }}
                  xs={12}
                >

                 <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:"1rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                >
                  <Grid
                      item
                      sx={{
                        fontWeight: "normal",
                        borderBottom:"0px solid #AAAAAA",                    
                        borderRight:"0px solid #AAAAAA",                    
                        textAlign:"left"
                      }}
                      xs={12}
                    >

                  <FormControl
                    sx={{
                      borderRadius:"10px",

                    width:{
                      xs:"100%", 
                    },
                    padding:"10px 0px 2px 0px"}}>
                    <InputLabel id="time-select-label">How Can I Help</InputLabel>
                    <Select
                      size="small"
                      labelId="help-select-label"
                      id="help-select"
                      
                      multiple
                      value={selectedHelpItems}
                      onChange={this.handleHelpItemChange}
                      renderValue={(selected) => (
                        <Box sx={{                         borderRadius:"10px",
                        display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      InputProps={{
                        style: {
                          borderRadius:"10px",
                          background:"rgb(232, 240, 254)",
                        },
                      }}


                      sx={{
                        borderRadius:"10px",
                        backgroundColor: 'rgb(232, 240, 254)',
                        fontSize:{
                          xs:"1rem", 
                          sm:"1rem", 
                          md:"1rem", 
                          lg:"1rem", 
                          xl:"1rem"
                        },
                        padding:{
                          xs:"2px", 
                          sm:"2px", 
                          md:"5px", 
                          lg:"5px", 
                          xl:"5px"
                        }
                    }}          
                    >
                      {helpItems.map((item) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={selectedHelpItems.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>

                <Grid
                  item
                  sx={{
                    fontWeight: "bold",
                    padding:"20px 5px 5px 5px",
                  }}
                  xs={12}
                >
                    {this.state.inProgress ? 
                      <Button
                      variant={this.props.theme.buttonVariant}
                      sx={{
                        '&:hover': {
                          border:"1px solid #AAAAAA",      
                          background:this.props.theme.bannerColor,      
                        },
                        textTransform: "none",
                        width: "100%",
                        height: "50px",
                        borderRadius: "10px",
                        color:this.props.theme.buttonTextColor,
                        border:"1px solid " + this.props.theme.buttonColor,      
                        background:this.props.theme.bannerColor,     
                        borderTopLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderBottomLeftRadius: "25px",      
                      }}
                      size="small"
                    >
                        <CircularProgress sx={{color:this.props.theme.bannerTextColor}} disableShrink size={40} color="primary"/>
                    </Button>
                    :

                  <Button
                    onClick={this.sendContactCard}
                    variant={this.props.theme.buttonVariant}
                    sx={{
                      '&:hover': {
                        border:"1px solid #AAAAAA",
                        background:this.props.theme.bannerColor,      
                        color:this.props.theme.bannerTextColor,
                      },
                      textTransform: "none",
                      width: "100%",
                      height: "50px",
                      borderRadius: "10px",
                      color:this.props.theme.bannerTextColor,
                      border:"1px solid " + this.props.theme.buttonColor,      
                      background:this.props.theme.bannerColor,   
                      borderTopLeftRadius: "25px",
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                      borderBottomLeftRadius: "25px",                       
                    }}
                    size="small"
                  >
                    Click To Send
                  </Button>
                  }

                </Grid>      
                <Grid
                  item
                  sx={{
                    borderRadius:"20px",
                    paddingTop:"10px"
                  }}
                  xs={12}
                >

                {this.state.requestSent
              ? <Alert sx={{"borderRadius":"10px","padding":"2px 5px 2px 5px", }} severity="success">Thank You {this.state.name.split(" ")[0]}, I'll be in touch with you soon to learn more about your real estate needs - Donna</Alert>
              : <Alert sx={{"borderRadius":"10px","padding":"2px 5px 2px 5px", }} severity="info">Let Me Know How I can help, I'll put a package together for you that we can go over next time we talk.</Alert>
                }


                </Grid>
 
                 </Grid>
              </Grid>

                {/*
                <Grid
                  item
                  sx={{
                    paddingLeft: "0px",
                    paddingTop: "5px",
                    fontWeight: "normal",
                    borderTop: "1px solid #AAAAAA",
                    textAlign:"left"
                  }}
                  xs={12}
                >
                  <div>
                    Hi Donna, <br />
                    <blockquote>
                      My name is <b>{this.state.name}</b>, I'm intested in learning more about.
                      <br/>
                      <br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>{this.state.sellingAHome ? "Selling My Home" : null }</b>
                      <br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>{this.state.buyingAHome ? "Buying A Home" : null }</b>
                      <br/>
                      <br/>
                      Please contact me at:<br/>
                      <b>{this.state.phoneNumber}</b> or <b>{this.state.email}.</b>

                    </blockquote>
                    Thanks
                    <br />
                    {this.state.name}
                    <br />
                  </div>
                </Grid>
                */}                
                
              </Grid>

        </Grid>
      </React.Fragment>
    );
  };



  render() {

    return (
      <Drawer
          anchor="bottom"
          open={this.props.showContact}
          onClose={this.props.toggleContact}
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              marginLeft: "auto",
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: this.props.theme.bannerColor,
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "1px solid #333333",
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={2}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "10px",               
                color:this.props.theme.bannerTextColor  
              }}
            >
              Contact {this.props.name ? this.props.name.split(' ')[0] : ""}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={10}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={this.props.toggleContact}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor
                }}
              >
                Cancel Request
              </Button>
              <Button
                onClick={this.props.toggleContact}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor                 
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "#FFFFFF",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "#FFFFFF",
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >
                {this.getSendEmailTile()}                
              </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default Contact;
