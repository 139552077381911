import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CommonTile from "./CommonTile";
import WeekdaySelect from "./WeekdaySelect";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CheckBox from "@mui/material/Checkbox";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Image from 'mui-image'

import PlayIcon from '@mui/icons-material/PlayCircle';
import _ from "lodash";


class RequestShowing extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",
      inProgress: false,
      name: '',
      phoneNumber: '',
      email: '',
      errors: {
        name: '',
        phoneNumber: '',
        email: '',
      },
      firstDay: null,
      firstTimes: [],
      secondDay: null,
      secondTimes:[],
      requestSent:false,
      favorites: [],
      
    };
  }


  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    const errors = this.state.errors;

    errors[name] = ''

    this.setState({ [name]: newValue, errors: errors, requestSent:false});

  };  


  sendContactCard = (event) => {

      event.preventDefault();
  
      const { name, phoneNumber, email } = this.state;
      const errors = {};
  
      if (!name) {
        errors.name = 'Name is required';
      } else if (!/^[a-zA-Z\s]+$/.test(name)) {
        errors.name = 'Name must be alphanumeric';
      }

     // Phone number validation
      if (!phoneNumber) {
        errors.phoneNumber = 'Phone number is required';
      } else {
        let strippedPhoneNumber = phoneNumber.replace(/-/g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\)/g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\./g, ''); // Remove dashes from the input
        strippedPhoneNumber = strippedPhoneNumber.replace(/\s/g, ''); // Remove dashes from the input

        if (!/^\d{10}$/.test(strippedPhoneNumber)) {
          errors.phoneNumber = 'Phone number must be 10 digits 123-456-7890';
        } else {
          // Format the phone number with dashes for display
          const formattedPhoneNumber = strippedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          this.setState({ phoneNumber: formattedPhoneNumber });
        }
      }
  
      // Email validation
      if (!email) {
        errors.email = 'Email address is required';
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.email = 'Invalid email address';
      }

      if (Object.keys(errors).length > 0) {
        this.setState({ errors: errors, requestSent:false});
      } else {
        // Form is valid, perform further actions or submit
        this.setState({ errors: errors, inProgress:true }, () => {
          this.props.requestShowing(
            this.props.parentPage,
            this.state.name,
            this.state.phoneNumber,
            this.state.email,
            this.props.listings && this.props.listings.length > 0 ? this.props.listings[0].ListingId : "",
            this.props.listings && this.props.listings.length > 0 ? this.props.listings[0].UnparsedAddress : "",
            this.props.listings && this.props.listings.length > 0 ? this.props.listings[0].ListPrice : "",
            this.props.listings && this.props.listings.length > 0 ? this.props.listings[0].LivingArea + " sf | " + this.props.listings[0].BedroomsTotal + " bd | " + this.props.listings[0].BathroomsFull + "." + this.props.listings[0].BathroomsHalf + " ba": "",
            this.state.firstDay,
            this.state.firstTimes,
            this.state.secondDay,
            this.state.secondTimes,
            this.callback
            )});
      }
  }

  callback = (data) => {
    this.setState({inProgress:false, requestSent:true})
  }


  setFirstDay = (firstDay) => {

    this.setState({
      firstDay: firstDay
    })
  }

  setFirstTimes = (firstTimes) => {

    this.setState({
      firstTimes: firstTimes
    })
  }

  setSecondDay = (secondDay) => {

    this.setState({
      secondDay: secondDay
    })
  }

  setSecondTimes = (secondTimes) => {

    this.setState({
      secondTimes: secondTimes
    })
  }


  getYourFavoritesTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "0px",
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            
            background: "#FFFFFF",
            borderLeft:"1px solid #333333",
            borderRight:"0px solid #333333"
          }}
          xl={8}
          lg={8}
          md={7}
          sm={12}
          xs={12}
        >

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "#FFFFFF",
              height: {
                xs: "100%",
              },
              width: {
                xs: "100%",
              },
              overflowX:"hidden",
              overflowY: this.props.listings && this.props.listings.length > 1 ? "auto" : "hidden",
              border: "0px solid #333333",
            }}>


          {this.props.listings && this.props.listings.length > 0  ? this.props.listings.map((tile, index) => {
          return (
            <React.Fragment>

            <Grid
              item
              sx={{                
                padding: "10px",
                height: {
                  xl: this.props.listings && this.props.listings.length > 1 ? "50%" : "90%",
                  lg: this.props.listings && this.props.listings.length > 1 ? "50%" : "90%",
                  md: "90%",
                  sm: "40%",
                },
                width:"100%",
                borderBottom:{
                  xs:"1px solid #AAAAAA",
                  md:"0px solid #AAAAAA",
                },
                background: "#FFFFFF",
              }}
              
              xs={12}
              md={10}
            >
              <CommonTile
                uid={tile.ListingId}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={ 

                  this.props.favorites.some(obj => obj.ListingId === tile.ListingId) ? 
                  <React.Fragment>
                    <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="fit" height={35} width={55} duration={200}/>              
                    </React.Fragment>              
                    :
                    <React.Fragment>
                    <Image src={"logos/loveit-gray.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="fit" height={35} width={55} duration={200}/>              
                    </React.Fragment>     

                }
                tagTopRightTxt={null}
                tagTopRightPadding={"5px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                tagTopRightPaddingRight={"2px"}
                tagTopRightPaddingTop={"2px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,0)"}
                tagTopRightBorder={"0px solid black"}
                tagTopRightBorderRadius={"5px"}
                tagTopRightCallback={null}

                tagBottomRightIcon={
            tile.ListAgentMlsId === "WILLSDO" ? <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={30} width={100} duration={200}/> : null
          }
          tagBottomRightTxt={
            null
          }
                tagBottomRightPadding={"5px 10px 5px 10px"}
                tagBottomRightTxtColor={"black"}
                tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagBottomRightPaddingRight={"0px"}
                tagBottomRightPaddingBottom={"0px"}
                tagBottomRightTxtType={"bold"}
                tagBottomRightTxtAlign={"center"}
                tagBottomRightBgColor={this.props.theme.highlightColor}
                tagBottomRightBorder={"0px solid black"}
                tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
                tagBottomRightCallback={null}

                tagTopLeftIcon={null}
                tagTopLeftTxt={
                  tile.status
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={[".75rem", ".75rem",  ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={tile.status === "Available" ? "rgba(115, 190, 115,.95)" : "rgba(255, 255, 0,.85)"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}

                tagBottomLeftIcon={null}
                tagBottomLeftTxt={tile.video && tile.videos.length > 0 ?                 
                  <Button variant="filled" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".75rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
                    Video
                  </Button>                
                  : null
                }
                tagBottomLeftPadding={"0px"}
                tagBottomLeftTxtColor={this.props.theme.bannerColor}
                tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
                tagBottomLeftPaddingLeft={"0px"}
                tagBottomLeftPaddingBottom={"5px"}
                tagBottomLeftTxtType={"bold"}
                tagBottomLeftTxtAlign={"center"}
                tagBottomLeftBgColor={"rgba(255,255,255,1)"}
                tagBottomLeftBorder={"0px solid black"}
                tagBottomLeftBorderRadius={["0px", "10px", "10px", "0px"]}
                tagBottomLeftCallback={null}

                topCallback={null}
                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={null}
                topTxtColor={"white"}
                topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgba(255,255,255,0)"}
                topHeight={["250px", "75%", "80%"]}
                topGraphType={null}
                topGraphData={null}
                topWidget={
                  tile.Media && tile.Media.length > 0 ?
                  <Image src={tile.Media[0]["MediaURL"]} fit="cover" duration={200} />
                  :
                  null
                }

                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxtLineHeight={1.5}
                mdlTxt={null}

                mdlWidget={
                  <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "rgba(255,255,255,0)",
                    height:"100%",
                    width:"100%",     
                    border: "0px solid #AAAAAA",
                  }}
                >
                    <Grid
                      item
                      sx={{
                        background: "rgba(255,255,255,0)",
                        overflowX:"hidden",
                        textAlign:"left",
                        fontSize: {
                          xs:"1rem",
                          sm:"1rem",
                          md:"1rem",
                          lg:"1rem",
                          xl:"1rem",
                        },
                        fontWeight:"normal",
                      }}
                      xs={10}
                    >

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            fontWeight:"normal",
                          }}
                          xs={12}
                        >
                            <Box sx={{
                              fontSize:{
                              xs:"1.25rem",
                              sm: "1.25rem",
                              md:"1.25rem",
                              lg:"1.25rem",
                              xl:"1.25rem",
                              },
                              paddingLeft: {
                                xs:"10px",
                                sm:"10px",
                                md:tile.ListAgentMlsId === "WILLSDO" ? "5px" : "10px",
                                lg:"10px",
                                xl:"10px",
                              },
  
                              fontWeight:"bold", display:"inline"}} >
                          {"$"+ tile.ListPrice ? tile.ListPrice.toLocaleString(): "--"}
                          </Box>
                            &nbsp;|&nbsp;
                          {tile.LivingArea ? tile.LivingArea.toLocaleString() : "--"} sf &nbsp;|&nbsp;
                          {tile.BedroomsTotal} bd&nbsp;|&nbsp;
                          {tile.BathroomsFull}.{tile.BathroomsHalf > 0 ? "5":"0"} ba
                        </Grid>                           

                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"left",
                            paddingLeft: {
                              xs:"10px",
                              sm:"10px",
                              md:tile.ListAgentMlsId === "WILLSDO" ? "5px" : "10px",
                              lg:"10px",
                              xl:"10px",
                            },
                            fontSize: {
                              xs:"1rem",
                              sm: "1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem",
                            },
                            lineHeight:"1"
                          }}
                          xs={12}
                        >
                         {tile.UnparsedAddress + ", " + tile.PostalCity}
                      </Grid>                 
                    </Grid>                 
                    </Grid>                 

                    <Grid
                      item
                      sx={{
                        overflowX:"hidden",
                        textAlign:"left",
                        fontSize: {
                          xs:"1rem",
                          sm:"1rem",
                          md:"1rem",
                          lg:"1rem",
                          xl:"1rem",
                        },
                        fontWeight:"normal",
                        height:"100%",
                        width:"100%",     
                      }}
                      xs={2}
                    >

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{
                        background: "rgba(255,255,255,0)",
                        height:"100%",
                        width:"100%",     
                        border: "0px solid #AAAAAA",
                        paddingLeft:
                        {
                          xl:"20px",
                        },
                        float:"right"

                      }}
                    >
                      {tile.ListAgentMlsId === "WILLSDO"?
                      <Image src={"profilepics/profile-full-white-lowres.jpg"} bgColor="rgba(255,255,255,0)" fit="contain" duration={200}/>              
                        :null}
                    </Grid>

                    </Grid>
                </Grid>      
                  }
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem",  ".75rem"]}
                mdlTxtType={"normal"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"flex-end"}
                mdlBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor : "#FFFFFF" }
                mdlBorder={"0px solid black"}
                mdlHeight={["100px", "20%", "15%"]}
                mdlGraphType={null}
                mdlGraphData={null}                
                btmPadding={"2px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={ 
                  <center>
                  <div style={{fontSize:".5rem"}}>
                  <img alt="image" src="howardhanna/idx-logo-sm.png" height="10px"/>  MLS:  {tile.ListingId} - Listing By {tile.ListOfficeName}
                  </div>
                </center>      
              }
                btmTxtColor={"#333333"}
                btmTxtSize={[".75rem", ".75rem",  ".75rem"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["25px", "5%", "5%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"5px solid #FFFFFF"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
            </React.Fragment>
          );
        }): null}


          </Grid>
        </Grid>
      </React.Fragment>
    );
  };


  getSendEmailTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: {
              xs:"0px",
              md:"5px"
            },
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
            overflowY:"auto",
            overflowX:"hidden",
            background: "#FFFFFF",
            border:"0px solid #333333"
          }}
          xl={4}
          lg={4}
          md={5}
          sm={12}
          xs={12}
        >
          <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height:"100%",
                  width: { md: "100%", xs: "100%" },
                  padding: "0px",
                }}
              >
                <Grid
                  item
                  sx={{
                    height:{
                      xs:"200px",
                      sm:"200px",
                      md:"120px",
                      lg:"200px",
                      xl:"200px"
                    },
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"center",
                    fontSize:"1.5rem"
                    
                  }}
                  xs={8}
                  md={8}
                >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height:{
                      xs:"200px",
                      sm:"200px",
                      md:"120px",
                      lg:"200px",
                      xl:"200px"
                    },
                    width: { md: "100%", xs: "100%" },
                    padding: "0px",
                    borderBottom:"1px solid #AAAAAA",
                    fontSize:{
                      xs:"1rem",
                      sm:"1rem",
                      md:".8rem",
                      lg:"1rem",
                      xl:"1rem",
                    },
                    lineHeight:"1.75"
                  }}
                >
                  Thank You For Reaching out!<br/> Please tell me how best to connect with you<br/>and your availability for a showing.<br/>
                </Grid>
                </Grid>

                <Grid                
                  item
                  sx={{
                    height:{
                      xs:"200px",
                      sm:"200px",
                      md:"120px",
                      lg:"200px",
                      xl:"200px"
                    },
                    padding: "0px",
                    fontWeight: "normal",
                    textAlign:"right",
                    borderBottom:"1px solid #AAAAAA",
                    borderRight:"0px solid #AAAAAA",
                    }}
                  xs={4}
                  md={4}
                >
                  <Box
                    component="img"
                    src="profilepics/profile-full-white-lowres.jpg"
                    alt="Image description"
                    sx={{
                      height:{
                        xs:"200px",
                        sm:"200px",
                        md:"120px",
                        lg:"200px",
                        xl:"200px"
                      },
                      
                    }}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"10%",
                    }
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                      width: "100%" ,
                      backgroundColor: this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                    }}                    
                    InputProps={{
                      style: {
                        background:this.state.errors.name ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    label="Your Name"
                    variant="filled"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.name)}
                    helperText={this.state.errors.name}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"10%",
                    }
                  }}
                  xs={6}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       backgroundColor: this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                    InputProps={{
                      style: {
                        background:this.state.errors.phoneNumber ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    id="outlined-basic"
                    label="Phone Number"
                    variant="filled"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.phoneNumber)}
                    helperText={this.state.errors.phoneNumber}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: "5px",
                    fontWeight: "normal",
                    height:{
                      xs:"15%",
                      sm:"15%",
                      md:"15%",
                      lg:"10%",
                    }
                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    sx={{
                       width: "100%",
                       backgroundColor: this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      }}
                    InputProps={{
                      style: {
                        background:this.state.errors.email ? 'rgba(255, 0, 0, 0.1)' : 'rgb(232, 240, 254)',
                      },
                    }}                      
                    id="outlined-basic"
                    label="Email Address"
                    variant="filled"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={Boolean(this.state.errors.email)}
                    helperText={this.state.errors.email}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop: "5px",
                    fontWeight: "normal",                    
                  }}
                  xs={12}
                >
                  <WeekdaySelect
                    setDay={this.setFirstDay}
                    setTimes={this.setFirstTimes}
                  />
                </Grid>
                    <Grid
                    item
                    sx={{
                      paddingTop: "5px",
                      fontWeight: "normal",                    
                    }}
                    xs={12}S
                  >
                    <WeekdaySelect
                      setDay={this.setSecondDay}
                      setTimes={this.setSecondTimes}
                    />
               </Grid>


                <Grid
                  item
                  sx={{
                    fontWeight: "bold",
                    paddingTop:"15px"
                  }}
                  xs={12}
                >
                    {this.state.inProgress ? 
                      <Button
                      variant={this.props.theme.buttonVariant}
                      sx={{
                        textTransform: "none",
                        marginRight: "10px",
                        textTransform: "none",
                        textAlign: "center",
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        background:this.props.theme.bannerColor,
                        color:this.props.theme.bannerTextColor,
                        border:"1px solid " + this.props.theme.buttonColor,
                        textTransform: "none",
                        width: "100%",
                        height: "40px",
                        borderTopLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderBottomLeftRadius: "25px",
                      }}
                      size="small"
                    >
                        <CircularProgress disableShrink size={20} color="primary"/>
                    </Button>
                    :
                  <Button
                    onClick={this.sendContactCard}
                    variant={this.props.theme.buttonVariant}
                  sx={{
                    '&:hover': {
                      border:"1px solid " + this.props.theme.buttonTextColor,
                      background:this.props.theme.bannerColor
                    },
        
                    marginRight: "10px",
                    textTransform: "none",
                    textAlign: "center",
                    fontSize:{
                      xs:".75rem",
                      sm:".75rem",
                      md:".75rem",
                      lg:"1rem",
                      xl:"1rem"
                    },
                    background:this.props.theme.bannerColor,
                    color:this.props.theme.bannerTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    textTransform: "none",
                    width: "100%",
                    height: "40px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderBottomLeftRadius: "25px",
                  }}
                  size="small"
                  >
                    Click To Send
                  </Button>
                  }

                </Grid>      
                <Grid
                  item
                  sx={{
                    paddingTop:"10px"
                  }}
                  xs={12}
                >

                    {this.state.requestSent
                      ? <Alert sx={"padding:2px 5px 2px 5px"} severity="success">Thank you, I'll be in touch soon to confirm your showings - Donna</Alert>
                      : <Alert sx={"padding:2px 5px 2px 5px"} severity="info">Providing time slots will help me schedule your tour times quicker.</Alert>}


                </Grid>
              </Grid>

        </Grid>
      </React.Fragment>
    );
  };



  render() {

    return (
      <Drawer
          anchor="bottom"
          open={this.props.showRequestShowing}
          onClose={this.props.toggleRequestShowing}
          PaperProps={{
            sx: {
              height: {
                xs: "86%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              marginLeft: "auto",
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#FFFFFF",
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "1px solid #333333",
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={3}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "10px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                },          
                color:this.props.theme.bannerTextColor  
              }}
            >
              Request Showing
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={9}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={this.props.toggleRequestShowing}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Cancel Request
              </Button>

              <Button
                onClick={this.props.toggleRequestShowing}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "#FFFFFF",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  background: "#FFFFFF",
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >
                
                {this.getYourFavoritesTile()}
                {this.getSendEmailTile()}                
              </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default RequestShowing;
