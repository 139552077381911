class DwrServices {
    constructor(baseUrl) {
      this.baseUrl = baseUrl;
    }
  
    static post(url, data, callback) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
          
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.json()
          .then(data => {
            return {status: response.status, data: data};
          });
          
        })
        .then(data => {
          callback(data);
        })
        .catch(error => {
          callback(error, null);
        });
    }

    static image(url, data, callback) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob()          
        })
        .then(data => {
          callback(data);
        })
        .catch(error => {
          callback(error);
        });
    }

  }

  export default DwrServices;