import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AlertDialog from './AlertDialog'
import Image from 'mui-image'
import _ from "lodash";


class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      showContact: false,
      hhalertDialogOpen: false,
      hudalertDialogOpen: false
    }
  }

  
  
  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }

  closeAlertDialog = () => {

    this.setState({
      hhalertDialogOpen: false,
      hudalertDialogOpen: false
    })

  }
  
  gotoHowardHanna = () => {
    this.setState({hhalertDialogOpen:true})
  }

  gotoHowardHannaWebsite = () => {
    window.location.href = 'http://howardhanna.com';
  }


  gotoHud = () => {
    this.setState({hudalertDialogOpen:true})
  }

  gotoHudWebsite = () => {
    window.location.href = 'https://www.hud.gov/program_offices/fair_housing_equal_opp';
  }

  
  render() {

    return (
      <Drawer
          anchor="right"
          open={this.props.showMainMenu}
          onClose={this.props.toggleMainMenu}
          PaperProps={{
            sx: {
              height: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "30%",
                lg: "20%",
                xl: "20%",
              },
              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "0px solid #333333",
          background:"F5F5F5"
        }}
      >
      <Grid
          item
          sx={{
            background:this.props.theme.bannerColor,
            cursor: "pointer",
            textAlign: "left",
            padding: "5px",
            borderBottom: "1px solid #A0A0A0",
            height:"10%",
            fontWeight: "bold",
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
          xs={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              height: "100%",
              width: { md: "100%", xs: "100%" },
              paddingBottom: "0px",
              background:this.props.theme.bannerColor,
            }}
          >
          
          </Grid>
      </Grid>


      <Grid
          item
          sx={{
            background:this.props.theme.bannerColor,
            cursor: "pointer",
            textAlign: "left",
            padding: "5px",
            borderBottom: "1px solid #A0A0A0",
            height:"10%",
            fontWeight: "bold",
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
          xs={10}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              height: "100%",
              width: { md: "100%", xs: "100%" },
              paddingBottom: "0px",
            }}
          >
            <Button
              onClick={() => this.props.showContact("mainMenu")}
              variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "15px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:".75rem",
                    xl:".75rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,

              }}
            >
              Contact Donna
            </Button>
            
            <Button
              onClick={this.props.toggleMainMenu}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                marginRight: "10px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                borderBottomLeftRadius: "0px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:".75rem",
                  sm:".75rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem"
                },
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
            }}
            >
              Close
            </Button>
          </Grid>
        </Grid>        

       <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFF0",
              height:"8%",
              fontWeight: "bold",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showMyServices()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={35} width={40} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    My Services
                  </Grid>
            </Grid>
        </Grid>
        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showFinancing()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"general/calculator.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Payment Calculator
                  </Grid>
            </Grid>
        </Grid>
        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showHomeValue()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"general/house-transp.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Home Valuation Report
                  </Grid>
            </Grid>
        </Grid>
        <Grid
            item
            sx={{
                '&:hover': {
                  cursor: 'pointer',
                  border: "1px solid #DDDDDD", 
                  background: "#EEEEEE", 
                },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showUltimateGuides()}
            xs={12}
          >
             <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"general/document.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={40} width={40} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    My Ultimate Guides
                  </Grid>
            </Grid>
       </Grid>
        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showFavoriteListings()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"general/loveit-heart.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Favorite Listings
                  </Grid>
            </Grid>
        </Grid>
        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showSearchListings()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"general/search.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Search Listings
                  </Grid>
            </Grid>
        </Grid>
      
        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },

              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showFeaturedListings()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}

                    xs={2}
                  >  
                    <Image src={"general/featured.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Featured Properties
                  </Grid>
            </Grid>
        </Grid>
          
        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },
              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",              
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showRelocation()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item      
                    sx={{paddingLeft:"10px"}}      
                    xs={2}
                  >  
                    <Image src={"general/map-marker.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Relocation
                  </Grid>
            </Grid>
        </Grid>

        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
            background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },
                            
              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showRecentlySold()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={2}
                  >  
                    <Image src={"general/realestate-sold2.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Recently Sold
                  </Grid>
            </Grid>
        </Grid>
        {/*
        <Grid
            item
            sx={{
              background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"15px 5px 5px 75px",
                md:"15px 5px 5px 10px",
                lg:"15px 5px 5px 10px"
              },

              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              height:"10%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showHowardHanna()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"5px"}}

                    xs={2}
                  >  
                    <Image src={"general/hh-color-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={75} width={60} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"20px"}}
                    xs={10}
                  >  
                    Howard Hanna
                    <div style={{fontSize:".75rem"}}>Real Estate Services</div>
                  </Grid>
            </Grid>
        </Grid>
      */}

        <Grid
            item
            sx={{
              '&:hover': {
                cursor: 'pointer',
                border: "1px solid #DDDDDD", 
                background: "#EEEEEE", 
              },
            background: "#FFFFFF",
              color:this.props.theme.textColor,
              cursor: "pointer",
              textAlign: "left",
              padding: {
                xs:"5px 5px 5px 75px",
                md:"15px 5px 5px 20px",
                lg:"15px 5px 5px 10px"
              },
                            
              border: "1px solid #FFFFFF",
              fontWeight: "bold",
              height:"8%",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
            }}
            onClick = { () => this.props.showFairHousing()}
            xs={12}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              >
                <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={2}
                  >  
                    <Image src={"general/fair-housing-transp.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={30} width={30} duration={500}/>              
                  </Grid>
                  <Grid
                    item
                    sx={{paddingLeft:"10px"}}
                    xs={10}
                  >  
                    Fair Housing
                  </Grid>
            </Grid>
        </Grid>


      </Grid>
      <AlertDialog theme={this.props.theme} open={this.state.hhalertDialogOpen}  yesButtonLabel="Yes" yesButtonAction={this.gotoHowardHannaWebsite} noButtonLabel="No" noButtonAction={this.closeAlertDialog} closeAlertDialog={this.closeAlertDialog} title="Visit HowardHanna.com" message="Would you like to be redirected to the Howard Hanna webstie?"/>
      <AlertDialog theme={this.props.theme} open={this.state.hudalertDialogOpen}  yesButtonLabel="Yes" yesButtonAction={this.gotoHudWebsite} noButtonLabel="No" noButtonAction={this.closeAlertDialog} closeAlertDialog={this.closeAlertDialog} title="Visit HUD.gov" message="Would you like to be redirected to HUD Fair Housing website?"/>

      </Drawer>
    );
  }
}

export default MainMenu;
