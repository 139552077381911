import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Contact from './Contact'
import Slider from '@mui/material/Slider';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Image from 'mui-image'
import HelpIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import AlertDialog from './AlertDialog'
import Footer from './Footer'

import _ from "lodash";


class RadissonHoa extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      assesmentPayment: 0,
      selectedSquareFootage: 2000,
      showContact: false,
      pictureHeight: "100%",
      pictureWidth: "60%",

      alertDialogOpen:false,      

      inProgress:false
    }

  }

  
  closeAlertDialog = () => {

    this.setState({
      alertDialogOpen: false
    })

  }

  callback = (data) => {

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(data);
      
    // Create a new link element
    const link = document.createElement('a');

    // Set the link's href attribute to the URL
    link.href = url;

    // Set the link's download attribute to the desired file name
    link.download = "financing-report";

    // Click the link to initiate the download
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);

    this.setState({inProgress:false, requestSent:true, enableSubmit:false})
  }



  ArchitecturalStandards = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            width: "100%",
            padding: {
              xs:"10px",
              sm:"10px",
              md:"10px",
              lg:"20px",
              xl:"20px"
            },
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={6}
          xs={12}
        >
          <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={null}
                tagTxtColor={"black"}
                tagTxtSize={["1rem", "1rem", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={"contain"}
                topTxt={"Architectural Standards"}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1.2rem", "1rem", "1.2rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={["50px", "10%", "10%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"10px"}
                mdlPicture={"downloads/radisson-architectural-standards.png"}
                mdlPictureAlign={"50% 50%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", ".75rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"rgba(255,255,255,0)"}
                mdlBorder={"0px solid black"}
                mdlHeight={["250px", "50%", "60%"]}
                mdlGraphType={"line"}
                mdlGraphColors={["red","blue","gold","green"]}
                mdlGraphData={null}
                mdlGraphLegend={false}
                mldGraphGrid={false}                
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit"
                  }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["15px", ".85rem", ".85rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "40%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
        </Grid>
      </React.Fragment>
    );
  };


  formatOptionValue = (value) => {
    return `$ ${value}`;
  };

  BuyingSelling = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Howard Hanna offers a real estate experience that is truly unique. Let us walk you through the entire home buying or selling process from start to finish with the help of our exclusive marketing and loan programs.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            width: "100%",
            padding: {
              xs:"10px",
              sm:"10px",
              md:"10px",
              lg:"20px",
              xl:"20px"
            },
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={6}
          xs={12}
        >

          <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={"Download My Guide"}
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"5px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"1px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={"0px"}
                tagTopLeftCallback={this.downloadInformation}                                
                topPadding={"5px"}                
                topPicture={"downloads/radisson-buying-selling.png"}
                topPictureAlign={"50% 50%"}
                topPictureSize={"contain"}
                topTxt={null}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1.2rem", "1rem", "1.2rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={null}
                topHeight={["250px", "60%", "70%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={"50% 50%"}
                mdlPictureSize={"contain"}
                mdlTxt={"Rules For Buying & Selling"}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1.25rem", "1.25rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"0px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={"line"}
                mdlGraphColors={["red","blue","gold","green"]}
                mdlGraphData={null}
                mdlGraphLegend={false}
                mldGraphGrid={false}                
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit"
                  
                  }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["15px", ".85rem", ".85rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "30%", "20%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
        </Grid>
      </React.Fragment>
    );
  };


  toggleHomeValue = () => {
    this.setState({
      HomeValue: !this.state.HomeValue,
    });
  };
  
  calculateAssesment = (squareFootage) => {
  
    // Calculate mortgage payment
    const assessmentPayment = squareFootage/4

    this.setState({
      assessmentPayment: Math.floor(assessmentPayment)
    })
  }

  handleSquareFootageChange = (event, newValue) => {

    this.setState({selectedSquareFootage: newValue }, () => {
      this.calculateAssesment(this.state.selectedSquareFootage)
    });

  };

  formatSquareFootageValueLabel = (value) => {
    // Format the value as desired
    return (Number(value) + " sqf");
  };


  getContactTile = () => {
    let tile = {
      id: "1",
      image: "downloads/SellingAHome.png",
      tag: null,
      name: "Dnwwload My Radisson HOA Guide",
      summary:
      "Howard Hanna offers a real estate experience that is truly unique. Let us walk you through the entire home buying or selling process from start to finish with the help of our exclusive marketing and loan programs.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={null}
                tagTxtColor={"black"}
                tagTxtSize={["1rem", "1rem", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={"contain"}
                topTxt={"Assessment Fees"}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1.2rem", "1rem", "1.2rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={["50px", "10%", "10%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"10px"}
                mdlPicture={"downloads/radisson-assesment.png"}
                mdlPictureAlign={"50% 50%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", ".75rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"rgba(255,255,255,0)"}
                mdlBorder={"0px solid black"}
                mdlHeight={["250px", "40%", "50%"]}
                mdlGraphType={"line"}
                mdlGraphColors={["red","blue","gold","green"]}
                mdlGraphData={null}
                mdlGraphLegend={false}
                mldGraphGrid={false}                
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={
                  <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                     <Grid item sx={{fontSize:"1rem", width:"80%", padding:"0px 0px 0px 0px", textAlign:"left"}} xs={1}></Grid>
                     <Grid item sx={{fontSize:"1rem", width:"80%", padding:"40px 0px 0px 0px", textAlign:"left"}} xs={10}>
                    <Slider
                        marks={true}
                        min={1000}
                        max={5000}
                        step={100}
                        valueLabelDisplay="on"
                        getAriaLabel={() => 'Sq. Footage'}
                        value={this.state.selectedSquareFootage}
                        sx={{
                          '& .MuiSlider-valueLabel': {
                            fontSize: 10, // Set the font size here
                            background:this.props.theme.textColor
                          },
                          color:this.props.theme.textColor
                        }}
                        valueLabelFormat={this.formatSquareFootageValueLabel}
                        onChange={this.handleSquareFootageChange}
                      />   
                    </Grid>
                    <Grid item sx={{fontSize:"1rem", width:"80%", padding:"0px 0px 0px 0px", textAlign:"left"}} xs={1}></Grid>

                    <Grid item sx={{fontSize:".85rem", padding:"5px 0px 5px 5px", textAlign:"left"}} xs={12}>
                      Estimate your Radisson assesment.  Use the slider above to enter the homes square footage. Below I'll show you the estimated quarterly fee.
                    </Grid>
                    <Grid item sx={{fontSize:"2rem", padding:"5px 0px 5px 10px", textAlign:"center"}} xs={12}>
                      ${this.state.assessmentPayment}
                    </Grid>

                </Grid>
                }
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["15px", ".75rem", ".75rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "50%", "40%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };


 

  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "10px",
            fontSize: "2rem",
          }}
          xs={12}
        >
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {this.BuyingSelling()}
            {this.ArchitecturalStandards()}
            {this.getContactTile()}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme} />
        </Grid>
      </Grid>
    );
  };


  downloadInformation = () => {
    this.setState({ inProgress: true }, () => {
      this.props.getInformationDownload(
        this.props.parentPage,
        "radissionbuyingselling",
        this.servicesCallback,
        )});
}


servicesCallback = (response) => {

  if(response.data.message){
    const base64String = response.data.message;

    // Convert base64 string to Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    // Create a download link and trigger click
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = response.data.filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  this.setState({inProgress:false})

}



  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showSellingAHome}
          onClose={this.props.toggleSellingAHome}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "86%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px", 
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem"
                },
                color:this.props.theme.bannerTextColor
                      
              }}
            >
              &nbsp;&nbsp;Radisson HOA
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleSellingAHome}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.SellingAHomeSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top HomeValueage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      <AlertDialog theme={this.props.theme} open={this.state.alertDialogOpen} yesButtonLabel="Ok" yesButtonAction={this.closeAlertDialog} title={this.state.alertDialogTitle} message={this.state.alertDialogMessage} />
      </Drawer>
    );
  }
}

export default RadissonHoa;
