import React, { Component } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';


import {
  BarChart,
  PieChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Pie,
  Sector,
  Cell,
  LineChart,
  Line,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from "recharts";

gsap.registerPlugin(ScrollTrigger);

const RADIAN = Math.PI / 180;

class CommonTile extends Component {
  constructor(props) {
    super(props);

    this.section10Ref = React.createRef();

    this.state = {
      current: true,
    };
  }

  componentDidMount() {
    const section10 = this.section10Ref.current;
   

    if(this.props.animationTime){
      if (this.props.animationType === "slide-in-right-center") {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: section10,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        });
        tl.fromTo(
          section10,
          {
            opacity: 0,
            x: "100%",
          },
          {
            opacity: 1,
            x: "0%",
            duration: 2,
            ease: "power4.out",
          }
        );
      } else if (this.props.animationType === "slide-in-right-right") {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: section10,
              start: "top 80%",
              toggleActions: "play none none reverse",
            },
          });
          tl.fromTo(
            section10,
            {
              opacity: 0,
              x: "100%",
            },
            {
              opacity: 1,
              x: "0%",
              duration: 2,
              ease: "power4.out",
            }
          );
      } else {
        if(this.props.animationContainer){
          // Create a GSAP timeline for the animation
          const timeline = gsap.timeline();

          // Add an animation to the timeline
          timeline.from(section10, {
            opacity: 0,
            x:200,
            duration: this.props.animationTime,
            ease: this.props.animationType,
          });

          // Use ScrollTrigger to trigger the animation as the parent is scrolled
          ScrollTrigger.create({
            trigger: section10,
            toggleActions: "play none none reverse",
            start: "top 90%", // start the animation when the top of the parent is at the top of the viewport
            end: "bottom 60%", // end the animation when the bottom of the parent is at the bottom of the viewport
            scrub: true, // set scrub to true to make the animation smooth and natural
            pin: false, // set pin to true to pin the parent container to the top of the viewport while scrolling
            pinSpacing: false, // set pinSpacing to false to remove any extra spacing between the parent and pinned element
            animation: timeline, // use the timeline we created above for the animation
            scroller:document.querySelector(".listings-ref"),
            markers:true
          });


        }else{
          gsap.from(section10, {
            opacity: 0,
            y: 50,
            duration: this.props.animationTime,
            ease: this.props.animationType,
            scrollTrigger: {
              trigger: section10,
              start: "top 80%",
              end: "bottom 20%",
              toggleActions: "play none none reverse",
            },
          });
        }
      }
    }
  }

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderGraph = (graphType, graphData, graphColors, graphGrid, graphLegend) => {
    let COLORS = graphColors
      ? graphColors
      : ["#8884d8", "#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

      
    if (graphType === "bar") {
      return (
        <ResponsiveContainer debounce={300} width="100%" height="100%">
          <BarChart
            width={"100%"}
            height={"100%"}
            data={graphData}
            margin={{
              top: 10,
              right: 10,
              left: 10,
              bottom: 10,
            }}
            barSize={20}
          >
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis />
            {graphGrid ? <CartesianGrid /> : null}
            <Bar
              dataKey="value"
              fill={COLORS[0]}
              background={{ fill: "#eee" }}
            />
            {graphLegend ? <Legend /> : null}s
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (graphType === "pie") {
      return (
        <ResponsiveContainer debounce={300} width="100%" height="100%">
          <PieChart width={"100%"} height={"100%"}>
            <Pie
              data={graphData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={this.renderCustomizedLabel}
              outerRadius={"85%"}
              fill="#eee"
              dataKey="value"
            >
              {graphData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            {graphLegend ? <Legend /> : null}
          </PieChart>
        </ResponsiveContainer>
      );
    } else if (graphType === "line") {
      return (
        <ResponsiveContainer debounce={300} width="100%" height="100%">
          <LineChart
            width={"100%"}
            height={"100%"}
            data={graphData}
            margin={{
              top: 10,
              right: 10,
              left: 10,
              bottom: 10,
            }}
          >
            {graphGrid ? <CartesianGrid /> : null}
            <XAxis dataKey="name" />
            <Line type="monotone" dataKey="value" stroke={COLORS[0]} />
            {graphLegend ? <Legend /> : null}
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (graphType === "scatter") {
      return (
        <ResponsiveContainer debounce={300} width="100%" height="100%">
          <ScatterChart
            width={"100%"}
            height={"100%"}
            margin={{
              top: 10,
              right: 10,
              bottom: 10,
              left: 10,
            }}
          >
            {graphGrid ? <CartesianGrid /> : null}
            <XAxis type="category" dataKey="name" name="" unit="" />
            <YAxis type="number" dataKey="value" name="" unit="" />
            <Scatter name="A school" data={graphData} fill={COLORS[0]} />
            {graphLegend ? <Legend /> : null}
          </ScatterChart>
        </ResponsiveContainer>
      );
    } else {
      return null;
    }
    
  };

  render() {
    return (
      <Paper
        className="common-tile"
        ref={this.props.animationTime ? this.section10Ref : null}
        sx={{
          
          height: "100%",
          width:"100%",
          borderRadius: this.props.borderRadius
            ? this.props.borderRadius
            : "0px",
          border: this.props.border ? this.props.border : "0px solid gray",
          background: this.props.bgcolor
            ? this.props.bgcolor
            : "rgba(255,255,255,0)",
        }}
        square={false}
        elevation={this.props.shadow ? this.props.shadow : 0}
      >

        <Grid
          container
          onClick={this.props.linksTo ? this.props.linksTo : null}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
            
            padding: this.props.padding ? this.props.padding : "0px",
            borderRadius: this.props.borderRadius
              ? this.props.borderRadius
              : "0px",
          }}
        >
          <Grid
            item
            sx={{
              height: {
                xs: this.props.topHeight ? this.props.topHeight[0] : "0px",
                sm: this.props.topHeight ? this.props.topHeight[0] : "0px",
                md: this.props.topHeight ? this.props.topHeight[1] : "0px",
                lg: this.props.topHeight ? this.props.topHeight[2] : "0px",
                xl: this.props.topHeight ? this.props.topHeight[2] : "0px",
              },
              textAlign: this.props.topTxtAlign
                ? this.props.topTxtAlign
                : "center",
              background: this.props.topBgColor
                ? this.props.topBgColor
                : "rgba(255,255,255,0)",
              backgroundImage: this.props.topPicture
                ? 'url("' + this.props.topPicture + '")'
                : null,
              backgroundPosition: this.props.topPictureAlign
                ? this.props.topPictureAlign
                : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: this.props.topPictureSize
                ? this.props.topPictureSize
                : "contain",
              ...(!this.props.topPicture && { 
                background: this.props.topBgColor
                ? this.props.topBgColor
                : "rgba(255,255,255,0)",  
              }),  
              fontWeight: this.props.topTxtType
                ? this.props.topTxtType
                : "normal",
              fontFamily: this.props.topTxtFamily
                ? this.props.topTxtFamily
                : "times new roman",
              fontSize: this.props.topTxtSize ? this.props.topTxtSize : "16px",
              color: this.props.topTxtColor ? this.props.topTxtColor : "black",
              borderRadius: this.props.borderRadius
                ? this.props.borderRadius
                : "0px",
            }}
            xs={12}
          >
              <Grid
                item
                sx={{
                 
                  height: "100%",
                  position: 'relative'
                }}
                xs={12}
              >
              {this.props.tagTopRightTxt || this.props.tagTopRightIcon ? (

                <Box position="absolute" right= {this.props.tagTopRightPaddingRight ? this.props.tagTopRightPaddingRight : "5%"} top={this.props.tagTopRightPaddingTop ? this.props.tagTopRightPaddingTop : "5%"}
               onClick = {() => this.props.tagTopRightCallback ? this.props.tagTopRightCallback(this.props.uid) : null}
                sx={{
                    zIndex:"1",
                    "&:hover": {
                      cursor: this.props.tagTopRightCallback? "pointer":"default",
                    },
                    padding: this.props.tagTopRightPadding
                      ? this.props.tagTopRightPadding
                      : "0px",
                    background: this.props.tagTopRightBgColor
                      ? this.props.tagTopRightBgColor
                      : "rgba(255,255,255,0)",
                    textAlign: this.props.tagTopRightAlign
                      ? this.props.tagTopRightTxtAlign
                      : "center",
                    fontWeight: this.props.tagTopRightTxtType
                      ? this.props.tagTopRightTxtType
                      : "normal",
                    fontFamily: this.props.tagTopRightTxtFamily
                      ? this.props.tagTopRightTxtFamily
                      : "times new roman",
                    fontSize: {
                      xs: this.props.tagTopRightTxtSize
                        ? this.props.tagTopRightTxtSize[0]
                        : "1.0rem",
                      sm: this.props.tagTopRightTxtSize
                        ? this.props.tagTopRightTxtSize[0]
                        : "1.0rem",
                      md: this.props.tagTopRightTxtSize
                        ? this.props.tagTopRightTxtSize[1]
                        : "1.0rem",
                      lg: this.props.tagTopRightTxtSize
                        ? this.props.tagTopRightTxtSize[2]
                        : "1.0rem",
                      xl: this.props.tagTopRightTxtSize
                        ? this.props.tagTopRightTxtSize[2]
                        : "1.0rem",
                    },
                    color: this.props.tagTopRightTxtColor
                      ? this.props.tagTopRightTxtColor
                      : "black",
                    border: this.props.tagTopRightBorder ? this.props.tagTopRightBorder : "0px solid #333333",

                    borderTopLeftRadius: this.props.tagTopRightBorderRadius
                      ? this.props.tagTopRightBorderRadius[0]
                      : "0px",
                    borderTopRightRadius: this.props.tagTopRightBorderRadius
                      ? this.props.tagTopRightBorderRadius[1]
                      : "0px",
                    borderBottomRightRadius: this.props.tagTopRightBorderRadius
                      ? this.props.tagTopRightBorderRadius[2]
                      : "0px",
                    borderBottomLeftRadius: this.props.tagTopRightBorderRadius
                      ? this.props.tagTopRightBorderRadius[3]
                      : "0px",                      
                  }}
                >
                  {this.props.tagTopRightTxt ? this.props.tagTopRightTxt : this.props.tagTopRightIcon ? this.props.tagTopRightIcon : null}
                </Box>
              ):null}



              {this.props.tagTopLeftTxt || this.props.tagTopLeftIcon ? (
                <Box position="absolute" left= {this.props.tagTopLeftPaddingLeft ? this.props.tagTopLeftPaddingLeft : "5%"} top={this.props.tagTopLeftPaddingTop ? this.props.tagTopLeftPaddingTop : "5%"}
               onClick = {() => this.props.tagTopLeftCallback ? this.props.tagTopLeftCallback(this.props.uid) : null}
                sx={{
                   zIndex:"1",
                   "&:hover": {
                      cursor: this.props.tagTopLeftCallback? "pointer":"default",
                    },
                    padding: this.props.tagTopLeftPadding
                      ? this.props.tagTopLeftPadding
                      : "0px",
                    background: this.props.tagTopLeftBgColor
                      ? this.props.tagTopLeftBgColor
                      : "rgba(255,255,255,0)",
                    textAlign: this.props.tagTopLeftAlign
                      ? this.props.tagTopLeftTxtAlign
                      : "center",
                    fontWeight: this.props.tagTopLeftTxtType
                      ? this.props.tagTopLeftTxtType
                      : "normal",
                    fontFamily: this.props.tagTopLeftTxtFamily
                      ? this.props.tagTopLeftTxtFamily
                      : "times new roman",
                    fontSize: {
                      xs: this.props.tagTopLeftTxtSize
                        ? this.props.tagTopLeftTxtSize[0]
                        : "1.0rem",
                      sm: this.props.tagTopLeftTxtSize
                        ? this.props.tagTopLeftTxtSize[0]
                        : "1.0rem",
                      md: this.props.tagTopLeftTxtSize
                        ? this.props.tagTopLeftTxtSize[1]
                        : "1.0rem",
                      lg: this.props.tagTopLeftTxtSize
                        ? this.props.tagTopLeftTxtSize[2]
                        : "1.0rem",
                      xl: this.props.tagTopLeftTxtSize
                        ? this.props.tagTopLeftTxtSize[2]
                        : "1.0rem",
                    },
                    color: this.props.tagTopLeftTxtColor
                      ? this.props.tagTopLeftTxtColor
                      : "black",
                    border: this.props.tagTopLeftBorder ? this.props.tagTopLeftBorder : "0px solid #333333",
                    borderTopLeftRadius: this.props.tagTopLeftBorderRadius
                      ? this.props.tagTopLeftBorderRadius[0]
                      : "0px",
                    borderTopRightRadius: this.props.tagTopLeftBorderRadius
                      ? this.props.tagTopLeftBorderRadius[1]
                      : "0px",
                    borderBottomRightRadius: this.props.tagTopLeftBorderRadius
                      ? this.props.tagTopLeftBorderRadius[2]
                      : "0px",
                    borderBottomLeftRadius: this.props.tagTopLeftBorderRadius
                      ? this.props.tagTopLeftBorderRadius[3]
                      : "0px",
                    }}
                  >
                  {this.props.tagTopLeftTxt ? this.props.tagTopLeftTxt : this.props.tagTopLeftIcon ? this.props.tagTopLeftIcon : null}
             </Box>
            ):null}


            {this.props.tagBottomRightTxt || this.props.tagBottomRightIcon ? (
              <Box position="absolute" right= {this.props.tagBottomRightPaddingRight ? this.props.tagBottomRightPaddingRight : "5%"} bottom={this.props.tagBottomRightPaddingBottom ? this.props.tagBottomRightPaddingBottom : "5%"}
              onClick = {() => this.props.tagBottomRightCallback ? this.props.tagBottomRightCallback(this.props.uid) : null}
              sx={{
                zIndex:"1",
                "&:hover": {
                  cursor: this.props.tagBottomRightCallback? "pointer":"default",
                },
                padding: this.props.tagBottomRightPadding
                  ? this.props.tagBottomRightPadding
                  : "0px",
                background: this.props.tagBottomRightBgColor
                  ? this.props.tagBottomRightBgColor
                  : "rgba(255,255,255,0)",
                textAlign: this.props.tagBottomRightAlign
                  ? this.props.tagBottomRightTxtAlign
                  : "center",
                fontWeight: this.props.tagBottomRightTxtType
                  ? this.props.tagBottomRightTxtType
                  : "normal",
                fontFamily: this.props.tagBottomRightTxtFamily
                  ? this.props.tagBottomRightTxtFamily
                  : "times new roman",
                fontSize: {
                  xs: this.props.tagBottomRightTxtSize
                    ? this.props.tagBottomRightTxtSize[0]
                    : "1.0rem",
                  sm: this.props.tagBottomRightTxtSize
                    ? this.props.tagBottomRightTxtSize[0]
                    : "1.0rem",
                  md: this.props.tagBottomRightTxtSize
                    ? this.props.tagBottomRightTxtSize[1]
                    : "1.0rem",
                  lg: this.props.tagBottomRightTxtSize
                    ? this.props.tagBottomRightTxtSize[2]
                    : "1.0rem",
                  xl: this.props.tagBottomRightTxtSize
                    ? this.props.tagBottomRightTxtSize[2]
                    : "1.0rem",
                },
                color: this.props.tagBottomRightTxtColor
                  ? this.props.tagBottomRightTxtColor
                  : "black",
                border: this.props.tagBottomRightBorder ? this.props.tagBottomRightBorder : "0px solid #333333",
                borderTopLeftRadius: this.props.tagBottomRightBorderRadius
                  ? this.props.tagBottomRightBorderRadius[0]
                  : "0px",
                borderTopRightRadius: this.props.tagBottomRightBorderRadius
                  ? this.props.tagBottomRightBorderRadius[1]
                  : "0px",
                borderBottomRightRadius: this.props.tagBottomRightBorderRadius
                  ? this.props.tagBottomRightBorderRadius[2]
                  : "0px",
                borderBottomLeftRadius: this.props.tagBottomRightBorderRadius
                  ? this.props.tagBottomRightBorderRadius[3]
                  : "0px",
              }}
              >
              {this.props.tagBottomRightTxt ? this.props.tagBottomRightTxt : this.props.tagBottomRightIcon ? this.props.tagBottomRightIcon : null}
            </Box>
            ):null}


            {this.props.tagBottomLeftTxt || this.props.tagBottomLeftIcon ? (
            <Box position="absolute" left= {this.props.tagBottomLeftPaddingLeft ? this.props.tagBottomLeftPaddingLeft : "5%"} bottom={this.props.tagBottomLeftPaddingBottom ? this.props.tagBottomLeftPaddingBottom : "5%"}
                onClick = {() => this.props.tagBottomLeftCallback ? this.props.tagBottomLeftCallback(this.props.uid) : null}
                sx={{
                  zIndex:"1",
                  "&:hover": {
                    cursor: this.props.tagBottomLeftCallback? "pointer":"default",
                  },
                padding: this.props.tagBottomLeftPadding
                  ? this.props.tagBottomLeftPadding
                  : "0px",
                background: this.props.tagBottomLeftBgColor
                  ? this.props.tagBottomLeftBgColor
                  : "rgba(255,255,255,0)",
                textAlign: this.props.tagBottomLeftAlign
                  ? this.props.tagBottomLeftTxtAlign
                  : "center",
                fontWeight: this.props.tagBottomLeftTxtType
                  ? this.props.tagBottomLeftTxtType
                  : "normal",
                fontFamily: this.props.tagBottomLeftTxtFamily
                  ? this.props.tagBottomLeftTxtFamily
                  : "times new roman",
                fontSize: {
                  xs: this.props.tagBottomLeftTxtSize
                    ? this.props.tagBottomLeftTxtSize[0]
                    : "1.0rem",
                  sm: this.props.tagBottomLeftTxtSize
                    ? this.props.tagBottomLeftTxtSize[0]
                    : "1.0rem",
                  md: this.props.tagBottomLeftTxtSize
                    ? this.props.tagBottomLeftTxtSize[1]
                    : "1.0rem",
                  lg: this.props.tagBottomLeftTxtSize
                    ? this.props.tagBottomLeftTxtSize[2]
                    : "1.0rem",
                  xl: this.props.tagBottomLeftTxtSize
                    ? this.props.tagBottomLeftTxtSize[2]
                    : "1.0rem",
                },
                color: this.props.tagBottomLeftTxtColor
                  ? this.props.tagBottomLeftTxtColor
                  : "black",
                border: this.props.tagBottomLeftBorder ? this.props.tagBottomLeftBorder : "0px solid #333333",
                borderTopLeftRadius: this.props.tagBottomLeftBorderRadius
                  ? this.props.tagBottomLeftBorderRadius[0]
                  : "0px",
                borderTopRightRadius: this.props.tagBottomLeftBorderRadius
                  ? this.props.tagBottomLeftBorderRadius[1]
                  : "0px",
                borderBottomRightRadius: this.props.tagBottomLeftBorderRadius
                  ? this.props.tagBottomLeftBorderRadius[2]
                  : "0px",
                borderBottomLeftRadius: this.props.tagBottomLeftBorderRadius
                  ? this.props.tagBottomLeftBorderRadius[3]
                  : "0px",
              }}
              >
              {this.props.tagBottomLeftTxt ? this.props.tagBottomLeftTxt : this.props.tagBottomLeftIcon ? this.props.tagBottomLeftIcon : null}
            </Box>
            ):null}

              {this.props.topProgress ? (
                <Box position="absolute" left="0%" top="0%"  sx={{width:"100%", height:"100%", background:"rgba(255,255,255,.5)"}}
                  >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{width:"100%", height:"100%"}}
                
                >
                    <CircularProgress disableShrink size={this.state.topProgresSize} sx={{opacity:"1"}}/>
                </Grid>    
             </Box>
            ):null}


            {this.props.topTxt || this.props.topWidget || this.props.topGraphType ? (
              <Grid
                container
                direction="row"
                justifyContent={
                  this.props.topHorizontalAlign
                    ? this.props.topHorizontalAlign
                    : "center"
                }
                alignItems={
                  this.props.topVerticalAlign
                    ? this.props.topVerticalAlign
                    : "center"
                }
                onClick = {() => this.props.topCallback ? this.props.topCallback(this.props.uid) : null}
                sx={{                    
                  height: "100%",
                  "&:hover": {
                      cursor: this.props.topCallback? "pointer":"default",
                    },
                  padding: this.props.topPadding
                    ? this.props.topPadding
                    : "0px",
                  background: this.props.topBgColor
                    ? this.props.topBgColor
                    : "rgba(255,255,255,0)",
                  lineHeight: this.props.topTxtLineHeight
                    ? this.props.topTxtLineHeight
                    : "1",                      
                  textAlign: this.props.topTxtAlign
                    ? this.props.topTxtAlign
                    : "center",
                  fontWeight: this.props.topTxtType
                    ? this.props.topTxtType
                    : "normal",
                  fontFamily: this.props.topTxtFamily
                    ? this.props.topTxtFamily
                    : "times new roman",
                  fontSize: {
                    xs: this.props.topTxtSize
                      ? this.props.topTxtSize[0]
                      : "1.0rem",
                    sm: this.props.topTxtSize
                      ? this.props.topTxtSize[0]
                      : "1.0rem",
                    md: this.props.topTxtSize
                      ? this.props.topTxtSize[1]
                      : "1.0rem",
                    lg: this.props.topTxtSize
                      ? this.props.topTxtSize[2]
                      : "1.0rem",
                    xl: this.props.topTxtSize
                      ? this.props.topTxtSize[2]
                      : "1.0rem",
                  },
                  overflowY: this.props.topOverflow
                  ? this.props.topOverflow
                  : "hidden",                    
                  color: this.props.topTxtColor
                    ? this.props.topTxtColor
                    : "black",   
                  borderRadius: this.props.borderRadius
                    ? this.props.borderRadius
                    : "0px", 

                }}
          >
                {this.props.topHeight && this.props.topTxt
                  ? this.props.topTxt
                  : this.props.topHeight && this.props.topWidget
                  ? this.props.topWidget
                  : this.props.topHeight && this.props.topGraphType
                  ? this.renderGraph(
                      this.props.topGraphType,
                      this.props.topGraphData,
                      this.props.topGraphColors,
                      this.props.topGraphGrid,
                      this.props.topGraphLegend
                    )
                  : null}
              </Grid>
            ) : null}
          </Grid>
          </Grid>



          <Grid
            item
            sx={{
              height: {
                xs: this.props.mdlHeight ? this.props.mdlHeight[0] : "0px",
                sm: this.props.mdlHeight ? this.props.mdlHeight[0] : "0px",
                md: this.props.mdlHeight ? this.props.mdlHeight[1] : "0px",
                lg: this.props.mdlHeight ? this.props.mdlHeight[2] : "0px",
                xl: this.props.mdlHeight ? this.props.mdlHeight[2] : "0px",
              },
              padding:
                this.props.mdlHeight && this.props.mdlPadding
                  ? this.props.mdlPadding
                  : "0px",
              textAlign: this.props.mdlTxtAlign
                ? this.props.mdlTxtAlign
                : "center",
              
                backgroundImage: this.props.mdlPicture
                ? 'url("' + this.props.mdlPicture + '")'
                : null,
              backgroundPosition: this.props.mdlPictureAlign
                ? this.props.mdlPictureAlign
                : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: this.props.mdlPictureSize
                ? this.props.mdlPictureSize
                : "contain",
              ...(!this.props.mdlPicture && { 
                background: this.props.mdlBgColor
                ? this.props.mdlBgColor
                : "rgba(255,255,255,0)",  
              }),
              fontWeight: this.props.mdlTxtType
                ? this.props.mdlTxtType
                : "normal",
              fontFamily: this.props.mdlTxtFamily
                ? this.props.mdlTxtFamily
                : "times new roman",
              fontSize: {
                xs: this.props.mdlTxtSize ? this.props.mdlTxtSize[0] : "12px",
                sm: this.props.mdlTxtSize ? this.props.mdlTxtSize[0] : "12px",
                md: this.props.mdlTxtSize ? this.props.mdlTxtSize[1] : "14px",
                lg: this.props.mdlTxtSize ? this.props.mdlTxtSize[2] : "16px",
                xl: this.props.mdlTxtSize ? this.props.mdlTxtSize[2] : "16px",
              },
              color: this.props.mdlTxtColor ? this.props.mdlTxtColor : "black",
              borderTop:
                this.props.mdlBorder && this.props.mdlHeight
                  ? this.props.mdlBorder
                  : "0px solid gray",
              borderBottom:
                this.props.btmHeight && this.props.btmBorder
                  ? this.props.btmBorder
                  : "0px solid gray",
            }}
            xs={12}
          >

            <Grid
                item
                sx={{
                  
                  height: "100%",
                  position: 'relative'
                }}
                xs={12}
              >

              {this.props.mdlTagTxt || this.props.mdlTagIcon ? (

                <Box position="absolute" right= {this.props.mdlTagPaddingRight ? this.props.mdlTagPaddingRight : "5%"} top={this.props.mdlTagPaddingTop ? this.props.mdlTagPaddingTop : "5%"}
                onClick = {() => this.props.mdlTagCallback ? this.props.mdlTagCallback(this.props.uid) : null}
                sx={{
                    zIndex:"1",
                    "&:hover": {
                      cursor: this.props.mdlTagCallback? "pointer":"default",
                    },
                    padding: this.props.mdlTagPadding
                      ? this.props.mdlTagPadding
                      : "0px",
                    background: this.props.mdlTagBgColor
                      ? this.props.mdlTagBgColor
                      : "rgba(255,255,255,0)",
                    textAlign: this.props.mdlTagTxtAlign
                      ? this.props.mdlTagTxtAlign
                      : "center",
                    fontWeight: this.props.mdlTagTxtType
                      ? this.props.mdlTagTxtType
                      : "normal",
                    fontFamily: this.props.mdlTagTxtFamily
                      ? this.props.mdlTagTxtFamily
                      : "times new roman",
                    fontSize: {
                      xs: this.props.mdlTagTxtSize
                        ? this.props.mdlTagTxtSize[0]
                        : "1.0rem",
                      sm: this.props.mdlTagTxtSize
                        ? this.props.mdlTagTxtSize[0]
                        : "1.0rem",
                      md: this.props.mdlTagTxtSize
                        ? this.props.mdlTagTxtSize[1]
                        : "1.0rem",
                      lg: this.props.mdlTagTxtSize
                        ? this.props.mdlTagTxtSize[2]
                        : "1.0rem",
                      xl: this.props.mdlTagTxtSize
                        ? this.props.mdlTagTxtSize[2]
                        : "1.0rem",
                    },
                    color: this.props.mdlTagTxtColor
                      ? this.props.mdlTagTxtColor
                      : "black",
                    border: this.props.mdlTagBorder ? this.props.mdlTagBorder : "0px solid #333333",
                    borderTopLeftRadius: this.props.mdlTagBorderRadius
                      ? this.props.mdlTagBorderRadius[0]
                      : "0px",
                    borderTopRightRadius: this.props.mdlTagBorderRadius
                      ? this.props.mdlTagBorderRadius[1]
                      : "0px",
                    borderBottomRightRadius: this.props.mdlTagBorderRadius
                      ? this.props.mdlTagBorderRadius[2]
                      : "0px",
                    borderBottomLeftRadius: this.props.mdlTagBorderRadius
                      ? this.props.mdlTagBorderRadius[3]
                      : "0px",                      
                  }}
                >
                  {this.props.mdlTagTxt ? this.props.mdlTagTxt : this.props.mdlTagIcon ? this.props.mdlTagIcon : null}
                </Box>

                ):null}


                {this.props.mdlProgress ? (
                <Box position="absolute" left="0%" top="0%"  sx={{width:"100%", height:"100%", background:"rgba(255,255,255,.5)"}}
                  >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{width:"100%", height:"100%"}}
                    
                    >
                        <CircularProgress disableShrink size={this.state.mdlProgresSize} sx={{opacity:"1"}}/>
                    </Grid>    
                </Box>
                ):null}


                {this.props.mdlTxt || this.props.mdlWidget || this.props.mdlGraphType ? ( 
                  <Grid
                    container
                    direction="row"
                    justifyContent={
                      this.props.mdlHorizontalAlign
                        ? this.props.mdlHorizontalAlign
                        : "center"
                    }
                    alignItems={
                      this.props.mdlVerticalAlign
                        ? this.props.mdlVerticalAlign
                        : "center"
                    }
                    onClick = {() => this.props.mdlCallback ? this.props.mdlCallback(this.props.uid) : null}
                    sx={{                    
                      height: "100%",
                      "&:hover": {
                          cursor: this.props.mdlCallback? "pointer":"default",
                        },
                      padding: this.props.mdlPadding
                        ? this.props.mdlPadding
                        : "0px",
                      background: this.props.mdlBgColor
                        ? this.props.mdlBgColor
                        : "rgba(255,255,255,0)",
                      lineHeight: this.props.mdlTxtLineHeight
                        ? this.props.mdlTxtLineHeight
                        : "1",                      
                      textAlign: this.props.mdlTxtAlign
                        ? this.props.mdlTxtAlign
                        : "center",
                      fontWeight: this.props.mdlTxtType
                        ? this.props.mdlTxtType
                        : "normal",
                      fontFamily: this.props.mdlTxtFamily
                        ? this.props.mdlTxtFamily
                        : "times new roman",
                      fontSize: {
                        xs: this.props.mdlTxtSize
                          ? this.props.mdlTxtSize[0]
                          : "1.0rem",
                        sm: this.props.mdlTxtSize
                          ? this.props.mdlTxtSize[0]
                          : "1.0rem",
                        md: this.props.mdlTxtSize
                          ? this.props.mdlTxtSize[1]
                          : "1.0rem",
                        lg: this.props.mdlTxtSize
                          ? this.props.mdlTxtSize[2]
                          : "1.0rem",
                        xl: this.props.mdlTxtSize
                          ? this.props.mdlTxtSize[2]
                          : "1.0rem",
                      },
                      color: this.props.mdlTxtColor
                        ? this.props.mdlTxtColor
                        : "black",     
                      overflowY: this.props.mdlOverflow
                        ? this.props.mdlOverflow
                        : "hidden",                    
  
                    }}
                    >

                    {this.props.mdlHeight && this.props.mdlTxt
                      ? this.props.mdlTxt
                      : this.props.mdlHeight && this.props.mdlWidget
                      ? this.props.mdlWidget
                      : this.props.mdlHeight && this.props.mdlGraphType
                      ? this.renderGraph(
                          this.props.mdlGraphType,
                          this.props.mdlGraphData,
                          this.props.mdlGraphColors,
                          this.props.mdlGraphGrid,
                          this.props.mdlGraphLegend
                        )
                      : null}
                  </Grid>
                ):null}
            </Grid>
          </Grid>

 
          <Grid
          item
          sx={{
            
            height: {
              xs: this.props.btmHeight ? this.props.btmHeight[0] : "0px",
              sm: this.props.btmHeight ? this.props.btmHeight[0] : "0px",
              md: this.props.btmHeight ? this.props.btmHeight[1] : "0px",
              lg: this.props.btmHeight ? this.props.btmHeight[2] : "0px",
              xl: this.props.btmHeight ? this.props.btmHeight[2] : "0px",
            },
            padding:
              this.props.btmHeight && this.props.btmPadding
                ? this.props.btmPadding
                : "0px",
            textAlign: this.props.btmTxtAlign
              ? this.props.btmTxtAlign
              : "center",
            backgroundImage: this.props.btmPicture
              ? 'url("' + this.props.btmPicture + '")'
              : null,
            backgroundPosition: this.props.btmPictureAlign
              ? this.props.btmPictureAlign
              : "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: this.props.btmPictureSize
              ? this.props.btmPictureSize
              : "contain",
            ...(!this.props.btmPicture && { 
              background: this.props.btmBgColor
              ? this.props.btmBgColor
              : "rgba(255,255,255,0)",  
            }),
            fontWeight: this.props.btmTxtType
              ? this.props.btmTxtType
              : "normal",
            fontFamily: this.props.btmTxtFamily
              ? this.props.btmTxtFamily
              : "times new roman",
            fontSize: {
              xs: this.props.btmTxtSize ? this.props.btmTxtSize[0] : "12px",
              sm: this.props.btmTxtSize ? this.props.btmTxtSize[0] : "12px",
              md: this.props.btmTxtSize ? this.props.btmTxtSize[1] : "14px",
              lg: this.props.btmTxtSize ? this.props.btmTxtSize[2] : "16px",
              xl: this.props.btmTxtSize ? this.props.btmTxtSize[2] : "16px",
            },
            color: this.props.btmTxtColor ? this.props.btmTxtColor : "black",
            borderRadius: this.props.borderRadius
                      ? this.props.borderRadius
                      : "0px",
          }}
          xs={12}
          >

          <Grid
              item
              sx={{
                
                height: "100%",
                position: 'relative'
              }}
              xs={12}
            >

            {this.props.btmTagTxt || this.props.btmTagIcon ? (

              <Box position="absolute" right= {this.props.btmTagPaddingRight ? this.props.btmTagPaddingRight : "5%"} top={this.props.btmTagPaddingTop ? this.props.btmTagPaddingTop : "5%"}
              onClick = {() => this.props.btmTagCallback ? this.props.btmTagCallback(this.props.uid) : null}
              sx={{
                    zIndex:"1",
                    "&:hover": {
                      cursor: this.props.btmTagCallback? "pointer":"default",
                    },
                  padding: this.props.btmTagPadding
                    ? this.props.btmTagPadding
                    : "0px",
                  background: this.props.btmTagBgColor
                    ? this.props.btmTagBgColor
                    : "rgba(255,255,255,0)",
                  textAlign: this.props.btmTagTxtAlign
                    ? this.props.btmTagTxtAlign
                    : "center",
                  fontWeight: this.props.btmTagTxtType
                    ? this.props.btmTagTxtType
                    : "normal",
                  fontFamily: this.props.btmTagTxtFamily
                    ? this.props.btmTagTxtFamily
                    : "times new roman",
                  fontSize: {
                    xs: this.props.btmTagTxtSize
                      ? this.props.btmTagTxtSize[0]
                      : "1.0rem",
                    sm: this.props.btmTagTxtSize
                      ? this.props.btmTagTxtSize[0]
                      : "1.0rem",
                    md: this.props.btmTagTxtSize
                      ? this.props.btmTagTxtSize[1]
                      : "1.0rem",
                    lg: this.props.btmTagTxtSize
                      ? this.props.btmTagTxtSize[2]
                      : "1.0rem",
                    xl: this.props.btmTagTxtSize
                      ? this.props.btmTagTxtSize[2]
                      : "1.0rem",
                  },
                  color: this.props.btmTagTxtColor
                    ? this.props.btmTagTxtColor
                    : "black",
                  border: this.props.btmTagBorder ? this.props.btmTagBorder : "0px solid #333333",
                  borderTopLeftRadius: this.props.btmTagBorderRadius
                    ? this.props.btmTagBorderRadius[0]
                    : "0px",
                  borderTopRightRadius: this.props.btmTagBorderRadius
                    ? this.props.btmTagBorderRadius[1]
                    : "0px",
                  borderBottomRightRadius: this.props.btmTagBorderRadius
                    ? this.props.btmTagBorderRadius[2]
                    : "0px",
                  borderBottomLeftRadius: this.props.btmTagBorderRadius
                    ? this.props.btmTagBorderRadius[3]
                    : "0px",                                    
                }}
              >
                {this.props.btmTagTxt ? this.props.btmTagTxt : this.props.btmTagIcon ? this.props.btmTagIcon : null}
              </Box>

              ):null}
            

              {this.props.btmProgress ? (
                <Box position="absolute" left="0%" top="0%"  sx={{width:"100%", height:"100%", background:"rgba(255,255,255,.5)"}}
                  >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{width:"100%", height:"100%"}}
                    
                    >
                        <CircularProgress disableShrink size={this.state.btmProgresSize} sx={{opacity:"1"}}/>
                    </Grid>    
                </Box>
                ):null}

              {this.props.btmTxt || this.props.btmWidget ||this.props.btmGraphType ? ( 
                <Grid
                  container
                  direction="row"
                  justifyContent={
                    this.props.btmHorizontalAlign
                      ? this.props.btmHorizontalAlign
                      : "center"
                  }
                  alignItems={
                    this.props.btmVerticalAlign
                      ? this.props.btmVerticalAlign
                      : "center"
                  }

                  onClick = {() => this.props.btmCallback ? this.props.btmCallback(this.props.uid) : null}
                  sx={{                    
                    height: "100%",
                    "&:hover": {
                        cursor: this.props.btmCallback? "pointer":"default",
                      },
                    padding: this.props.btmPadding
                      ? this.props.btmPadding
                      : "0px",
                    background: this.props.btmBgColor
                      ? this.props.btmBgColor
                      : "rgba(255,255,255,0)",
                    lineHeight: this.props.btmTxtLineHeight
                      ? this.props.btmTxtLineHeight
                      : "1",                      
                    textAlign: this.props.btmTxtAlign
                      ? this.props.btmTxtAlign
                      : "center",
                    fontWeight: this.props.btmTxtType
                      ? this.props.btmTxtType
                      : "normal",
                    fontFamily: this.props.btmTxtFamily
                      ? this.props.btmTxtFamily
                      : "times new roman",
                    fontSize: {
                      xs: this.props.btmTxtSize
                        ? this.props.btmTxtSize[0]
                        : "1.0rem",
                      sm: this.props.btmTxtSize
                        ? this.props.btmTxtSize[0]
                        : "1.0rem",
                      md: this.props.btmTxtSize
                        ? this.props.btmTxtSize[1]
                        : "1.0rem",
                      lg: this.props.btmTxtSize
                        ? this.props.btmTxtSize[2]
                        : "1.0rem",
                      xl: this.props.btmTxtSize
                        ? this.props.btmTxtSize[2]
                        : "1.0rem",
                    },
                    color: this.props.btmTxtColor
                      ? this.props.btmTxtColor
                      : "black",                    
                    overflowY: this.props.btmOverflow
                      ? this.props.btmOverflow
                      : "hidden",                    
                  }}
                >

                  {this.props.btmHeight && this.props.btmTxt
                    ? this.props.btmTxt
                    : this.props.btmHeight && this.props.btmWidget
                    ? this.props.btmWidget
                    : this.props.btmHeight && this.props.btmGraphType
                    ? this.renderGraph(
                        this.props.btmGraphType,
                        this.props.btmGraphData,
                        this.props.btmGraphColors,
                        this.props.btmGraphGrid,
                        this.props.btmGraphLegend
                      )
                    : null}
                </Grid>
              ):null}
          </Grid>
          </Grid>
        </Grid>
      </Paper>
                      
    );
    
  }
  
}
export default CommonTile;
