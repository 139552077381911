import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import Image from 'mui-image'
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import _ from "lodash";


class SpotlightListings extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      favorites:[],
      pictureHeight: "100%",
      pictureWidth: "60%",
      spotlightListingsTiles: [
        {
          id: "1",
          image: "homepic1.jpg",
          address: "1234 Main Street Lane Baldwinsville",
          summary: "$245,000 | 2,700 sf | 4 Bd | 2.5 Ba",
          price:"$245,000",
          status:"Available",
          details:{
            sqf: "2,400",
            bed: "4",
            bath: "2.5"
          },
          mls:"12344567",
          liked: false,
          broker:"Howard Hanna",
          position: {
            lat: 43.1833377059405,
            lng: -76.29868574434212,
          },
          pictures: [
            "homepic1.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
          ],          
        },
        {
          id: "2",
          image: "homepic2.jpg",
          address: "1234 Main Street Lane Baldwinsville",
          summary: "$245,000 | 2,700 sf | 4 Bd | 2.5 Ba",
          price:"$245,000",
          status:"Available",
          details:{
            sqf: "2,400",
            bed: "4",
            bath: "2.5"
          },
          mls:"12344567",
          liked: true,
          broker:"Howard Hanna",
          position: {
            lat: 43.1833377059405,
            lng: -76.29768574434212,
          },
          pictures: [
            "homepic2.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
          ],          
        },
        {
          id: "3",
          image: "homepic3.jpg",
          address: "1234 Main Street Lane Baldwinsville",
          summary: "$245,000 | 2,700 sf | 4 Bd | 2.5 Ba",
          price:"$245,000",
          status:"Pending",
          details:{
            sqf: "2,400",
            bed: "4",
            bath: "2.5"
          },
          mls:"12344567",
          liked: false,
          broker:"Howard Hanna",
          position: {
            lat: 43.1743377059405,
            lng: -76.29868574434212,
          },
          pictures: [
            "homepic3.jpg",
            "homepic2.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
            "homepic1.jpg",
            "homepic2.jpg",
            "homepic3.jpg",
          ],          
        },

      ],      
    }
  }


  toggleSpotlightListings = () => {
    this.setState({
      SpotlightListings: !this.state.SpotlightListings,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };

  getContactTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#F0F0F0",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={"hh-sign.png"}
            topPictureAlign={"20% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"center"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["400px", "100%", "100%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["16px", "16px", "16px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"#f0f0f0"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0px", "0px"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={"Contact Donna"}
            btmTxtColor={"black"}
            btmTxtSize={["16px", "12px", "12px"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          overflowY: "scroll",

          background: "#FFFFFF",
          height: "100%",
          width: "100%",
          borderBottom: "0px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "20px",
            fontSize: "2rem",
          }}
          xs={12}
        >
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "70%",
              lg: "70%",
              md: "70%",
              sm: "70%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            {tileBuilder}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "20%",
              lg: "20%",
              md: "20%",
              sm: "20%",
            },
            padding: "20px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
          <Button
                onClick={() => this.props.showRequestShowing(this.props.listing ? this.props.listing.ListingId : null)}
                variant="contained"
                sx={{
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Download These Featured Listings
              </Button>
              </Grid>
        </Grid>
      </Grid>
    );
  };


  getSpotlightListingsTiles = () => {
    return (
      <React.Fragment>
        {this.state.spotlightListingsTiles.map((tile, index) => {
          return (
            <Grid
              item
              sx={{
                width: "100%",
                padding: "20px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                background: "rgba(255,255,255,1)",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
        <CommonTile
          uid={tile.ListingId}
          animationType={null}
          animationTime={null}
          tagTopRightIcon={ this.props.favorites && this.props.favorites.some(obj => obj.mls === tile.mls) ? <FavoriteIcon sx={{fontSize:"26px", color:"rgba(200,0,0,.75)"}} /> :<FavoriteIcon sx={{fontSize:"26px", color:"#333333"}} />}
          tagTopRightTxt={null}
          tagTopRightPadding={"5px"}
          tagTopRightTxtColor={"black"}
          tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
          tagTopRightPaddingRight={"4px"}
          tagTopRightPaddingTop={"4px"}
          tagTopRightTxtType={"bold"}
          tagTopRightTxtAlign={"center"}
          tagTopRightBgColor={"rgba(255,255,255,.5)"}
          tagTopRightBorder={"0px solid black"}
          tagTopRightBorderRadius={"5px"}
          tagTopRightCallback={null}

          tagBottomRightIcon={null}
          tagBottomRightTxt={null}
          tagBottomRightPadding={"5px"}
          tagBottomRightTxtColor={"black"}
          tagBottomRightTxtSize={["1rem", "1rem", "1rem"]}
          tagBottomRightPaddingRight={"5px"}
          tagBottomRightPaddingBottom={"5px"}
          tagBottomRightTxtType={"bold"}
          tagBottomRightTxtAlign={"center"}
          tagBottomRightBgColor={"rgba(255,255,255,.5)"}
          tagBottomRightBorder={"0px solid black"}
          tagBottomRightBorderRadius={"5px"}
          tagBottomRightCallback={null}

          tagTopLeftIcon={null}
          tagTopLeftTxt={
            tile.status
          }
          tagTopLeftPadding={"5px 10px 5px 10px"}
          tagTopLeftTxtColor={"black"}
          tagTopLeftTxtSize={["1rem", "1rem", "1rem"]}
          tagTopLeftPaddingLeft={"5px"}
          tagTopLeftPaddingTop={"5px"}
          tagTopLeftTxtType={"bold"}
          tagTopLeftTxtAlign={"center"}
          tagTopLeftBgColor={tile.status === "Available" ? "rgba(115, 190, 115,.95)" : "rgba(255, 255, 0,.85)"}
          tagTopLeftBorder={"0px solid black"}
          tagTopLeftBorderRadius={"5px"}

          tagBottomLeftIcon={null}
          tagBottomLeftTxt={null}
          tagBottomLeftPadding={"10px"}
          tagBottomLeftTxtColor={"#333333"}
          tagBottomLeftTxtSize={["1rem", "1rem", "1rem"]}
          tagBottomLeftPaddingLeft={"5px"}
          tagBottomLeftPaddingBottom={"5px"}
          tagBottomLeftTxtType={"bold"}
          tagBottomLeftTxtAlign={"center"}
          tagBottomLeftBgColor={"rgba(255,255,255,.9)"}
          tagBottomLeftBorder={"0px solid black"}
          tagBottomLeftBorderRadius={"5px"}
          tagBottomLeftCallback={null}

          topCallback={this.props.showListingReport}
          topPadding={"0px"}
          topPicture={null}
          topPictureAlign={"center"}
          topPictureSize={"cover"}
          topTxt={null}
          topTxtColor={"white"}
          topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
          topTxtType={"bold"}
          topTxtAlign={"left"}
          topVerticalAlign={"flex-end"}
          topHorizontalAlign={"flex-start"}
          topBgColor={"rgba(255,255,255,0)"}
          topHeight={["300px", "65%", "75%"]}
          topGraphType={null}
          topGraphData={null}
          topWidget={
            <Image src={tile.image} fit="cover" duration={500} />
          }

          mdlTagIcon={null}
          mdlTagTxt={null}
          mdlTagPadding={"0px"}
          mdlTagTxtColor={"black"}
          mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
          mdlTagPaddingRight={"4px"}
          mdlTagPaddingTop={"2px"}
          mdlTagTxtType={"bold"}
          mdlTagTxtAlign={"center"}
          mdlTagBgColor={"rgba(255,255,255,0)"}
          mdlTagBorder={"0px solid black"}
          mdlTagBorderRadius={"5px"}                
          mdlPadding={"0px"}
          mdlPicture={null}
          mdlPictureAlign={null}
          mdlTxtLineHeight={1.5}
          mdlTxt={null}

          mdlWidget={

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "#FFFFFF",
              height:"100%",
              width:"100%",     
              border: "0px solid #AAAAAA",
            }}
          >
              <Grid
                item
                sx={{
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: "1.2rem",
                  fontWeight:"bold"
                }}
                xs={4}
              >
                 &nbsp;{tile.price}
            </Grid>                 
            <Grid
                item
                sx={{
                  overflowX:"hidden",
                  textAlign:"center",
                  fontSize: "1rem",
                }}
                xs={8}
              >  {tile.details.sqf} sf |&nbsp;
                 {tile.details.bed}bd|&nbsp;
                 {tile.details.bath} ba
            </Grid>                 

              <Grid
            item
            sx={{
              overflowX:"hidden",
              textAlign:"center",
              fontSize: {
                xs:"1rem",
                sm:"1rem",
                md:"1rem",
                lg:"1rem",
              }
            }}
            xs={12}
          >
            {tile.address}
          </Grid>                 
         </Grid>                 
            
          
            }
          mdlTxtColor={"black"}
          mdlTxtSize={["1rem", "1rem",  "1rem"]}
          mdlTxtType={"normal"}
          mdlTxtAlign={"center"}
          mdlHorizontalAlign={"center"}
          mdlVerticalAlign={"flex-end"}
          mdlBgColor={"#FFFFFF"}
          mdlBorder={"1px solid black"}
          mdlHeight={["75px", "25%", "15%"]}
          mdlGraphType={null}
          mdlGraphData={null}                
          btmPadding={"2px"}
          btmPicture={null}
          btmPictureAlign={null}
          btmTxt={ 
          <center>
            <div>
            <img alt="image" src="idx-logo-sm.png" height="12px"/>  MLS:  {tile.mls} - Listing By {tile.broker}
            </div>
          </center>      
        }
          btmTxtColor={"#333333"}
          btmTxtSize={[".75rem", ".6rem",  ".75rem"]}
          btmTxtType={"normal"}
          btmTxtAlign={"left"}
          btmHorizontalAlign={"center"}
          btmVerticalAlign={"center"}
          btmBgColor={"white"}
          btmBorder={"0px solid black"}
          btmHeight={["4rem", "10%", "10%"]}
          btmGraphType={null}
          btmGraphData={null}
          border={"1px solid #AAAAAA"}
          shadow={4}
          downloads={""}
          linksTo={""}
          growTime={4000}
          padding={"0px"}
          borderRadius={null}
          bgcolor={"rgba(255,255,255,1)"}
        />            </Grid>
          );
        })}
      </React.Fragment>
    );
  };

  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showSpotlightListings}
          onClose={this.props.toggleSpotlightListings}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "90%",
                sm: "90%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "#F0F0F0",
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          border: "0px solid #333333",
        }}
      >

      <Grid
            item
            sx={{
              background: "#F0F0F0",
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingLeft: "10px",
                fontSize:{
                  xs:".75rem",
                  sm:".75rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem"
                }               
              }}
            >
            Featured Listings
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: "#F0F0F0",
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant="contained"
                sx={{
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleSpotlightListings}
                variant="contained"
                sx={{
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "#F0F0F0",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  background: "#F0F0F0",
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.spotlightListingsSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top SpotlightListingsage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getSpotlightListingsTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default SpotlightListings;
