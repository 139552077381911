import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AlertDialog from './AlertDialog'
import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class FairHousing extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      nysalertDialogOpen: false,
      hudalertDialogOpen: false
    }
  }

  closeAlertDialog = () => {

    this.setState({
      nysalertDialogOpen: false,
      hudalertDialogOpen: false
    })

  }

  openNysFairHousing = () => {
    this.setState({nysalertDialogOpen:true})
  }

  gotoNysFairHousingWebsite = () => {
    window.location.href = 'https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf';
  }


  openHudFairHousing = () => {
    this.setState({hudalertDialogOpen:true})
  }

  gotoHudFairHousingWebsite = () => {
    window.location.href = 'https://www.justice.gov/crt/fair-housing-act-1';
  }


  getContactTile = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"10px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"Fair Housing"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1rem", "1.5rem", "1.5rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"20px"}
            mdlPicture={null}
            mdlPictureAlign={"50% 50%"}
            mdlPictureSize={"contain"}
            mdlTxtLineHeight={"1.5"}
            mdlTxt={ "Howard Hanna and our team have been a supporter and advocate of fair housing throughout our history. We are committed to equal housing opportunities and providing housing to all persons regardless of race, color, religion, sex, disability, national origin or any other protected category under federal, state or local law (including, where applicable, age, marital status, sexual orientation, gender identity or expression, familial status, ancestry, and source of income) for buyers, sellers, real estate and non real estate community members and peers. Howard Hanna creates and implements educational resources and policies to ensure compliance with all federal, state and local fair housing laws."}
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", "1.25rem", "1.25rem"]}
            mdlTxtType={"plain"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["200px", "45%", "55%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(255,255,255,.75)",
                  padding:"10px",
                  height: "100%",
                  width: "100%",
                  borderBottom: "0px solid #333333",
                }}
              >
                <Grid
                  item
                  sx={{
                    width: "80%",
                    margin:"10px",
                    padding: "10px",
                    fontSize: "1rem",
                    textAlign:"center",
                    border: "1px solid #333333",
                    color:this.props.theme.bannerTextColor,
                    background:this.props.theme.bannerColor,
                    cursor: "pointer",
                  }}
                  onClick = {this.openHudFairHousing}

                  xs={12}
                  md={3}
                >
                  Federal Fair Housing
                  </Grid>
                  <Grid
                  item
                  sx={{
                    width: "80%",
                    padding: "10px",
                    margin:"10px",
                    fontSize: "1rem",
                    textAlign:"center",
                    border: "1px solid #333333",
                    color:this.props.theme.bannerTextColor,
                    background:this.props.theme.bannerColor,
                    cursor: "pointer",
                  }}
                  onClick = {this.openNysFairHousing}
                  xs={12}
                  md={3}
                >
                  New York Fair Housing
                  </Grid>
              </Grid>
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["150px", "45%", "35%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );

  };


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "10px",
            fontSize: "2rem",
          }}
          xs={12}
        >
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              
              height: "100%",
              width: "100%",
            }}
          >
           
            {this.getContactTile()}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };


 
  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showFairHousing}
          onClose={this.props.toggleFairHousing}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",

                },               
                color:this.props.theme.bannerTextColor
              }}
            >
             Howard Hanna
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleFairHousing}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.FairHousingSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top realestate in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      <AlertDialog theme={this.props.theme} open={this.state.nysalertDialogOpen}  yesButtonLabel="Yes" yesButtonAction={this.gotoNysFairHousingWebsite} noButtonLabel="No" noButtonAction={this.closeAlertDialog} closeAlertDialog={this.closeAlertDialog} title="View NYS Fair Housing Notice" message="Would you like to be redirected to the New York State Fair Housing Notice?"/>
      <AlertDialog theme={this.props.theme} open={this.state.hudalertDialogOpen}  yesButtonLabel="Yes" yesButtonAction={this.gotoHudFairHousingWebsite} noButtonLabel="No" noButtonAction={this.closeAlertDialog} closeAlertDialog={this.closeAlertDialog} title="Visit HUD.gov" message="Would you like to be redirected to HUD Fair Housing website?"/>

      </Drawer>
    );
  }
}

export default FairHousing;
