import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "../CommonTile";
import Footer from "../Footer";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class OurTeamAboutMe extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",
      parentPage: "OurTeam",
      selectedIndex:0,   

      subService: "About Me",            
    }
  }


  componentDidUpdate(prevProps, prevState) {
      if (prevProps.showOurTeamAboutMe !== this.props.showOurTeamAboutMe) {
        this.setState({
          subService:"My Services"  
        })
      }
  }

  toggleOurTeamAboutMe = () => {
    this.setState({
      OurTeamAboutMe: !this.state.OurTeamAboutMe,
      subService:"My Services"
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,1)",
          height: "100%",
          width: "100%",
          borderBottom: "0px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "90%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                height: {
                  xl: "5%",
                  lg: "5%",
                  md: "10%",
                  sm: "10%",
                },
                fontWeight: "bold",
                paddingTop: {
                  xs:"25px",
                  md:"10px",
                  lg:"10px",
                  xl:"10px",
                },
                paddingBottom:{
                  xs:"20px",
                  md:"10px",
                  lg:"15px",
                  xl:"15px",
                },
                fontSize: {
                  xs:"1.25rem",
                  md:"1.25rem",
                  lg:"1.5rem"
                },
                borderBottom:"0px solid " + this.props.theme.bannerColor,
                color:this.props.theme.bannerColor,
              }}
              xs={12}
            >
              I'll Be With You Every Step Of The Way
            </Grid>    
            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "60%",
                  md: "0%",
                },
                textAlign:"right"
              }}
              xs={12}
              sm={12}
              md={0}
              lg={0}
              >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundImage: 'url("profilepics/profile-round-lowres.png")',
                        backgroundPosition: "50% 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        borderRadius:{
                          xs:"50px",
                          sm:"50px",
                          md:"0px",
                          lg:"0px",
                          xl:"0px"
                        },
                        width: "100%",
                        height: {
                          xs:"250px",
                          sm:"300px",
                          md:"80%",
                          lg:"0%",
                          xl:"0%"
                        },
                        paddingTop: "0px",
                      }}
                    >
                  </Grid> 
            </Grid>   
            <Grid
              item
              sx={{
                textAlign:"center",
                height: {
                  sm: "50%",                              
                  md: "55%",                              
                  lg: "50%",                              
                  xl: "60%",                              
                },
               
              }}
              xs={12}
              sm={12}
              md={2}
              lg={2}
            >
                <CommonTile
                  animationType={null}
                  animationTime={null}
                  tagPicture={null}
                  tagTxt={null}
                  tagTxtColor={"black"}
                  tagTxtSize={["12px", "12px", "16px"]}
                  tagTxtType={"bold"}
                  tagTxtAlign={"center"}
                  tagVerticalAlign={"flex-start"}
                  tagHorizontalAlign={"flex-end"}
                  tagBgColor={"#73be73"}
                  tagBorder={"0px solid black"}
                  tagBorderRadius={"0px"}
                  topPadding={"15px"}
                  topPicture={null}
                  topPictureAlign={"left"}
                  topPictureSize={"100px"}
                  topTxt={null}
                  topTxtColor={this.props.theme.bannerTextColor}
                  topTxtSize={["1rem", "1rem", "1rem"]}
                  topTxtType={"bold"}
                  topTxtAlign={"left"}
                  topHorizontalAlign={"center"}
                  topVerticalAlign={"center"}
                  topBgColor={this.props.theme.bannerColor}
                  topHeight={["0px", "0%", "0%"]}
                  topGraphType={null}
                  topGraphData={null}
                  mdlPadding={"5px"}
                  mdlPicture={null}
                  mdlPictureAlign={null}
                  mdlTxt={
                    <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", border:"0px solid black", padding:"0px"}} >
                          <Grid item xs={12}>
                          <Grid container sx={{width:"100%", padding:"0px"}} >
                            <Grid item 
                              onClick={() => {this.setState({selectedEntry: "About Me" })}}
                              sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                border: "1px solid #DDDDDD", 
                                },
                                height:"20%",
                                border: this.state.selectedEntry === "About Me" ? "1px solid #DDDDDD" :"1px solid #FFFFFF", 
                                background: this.state.selectedEntry === "About Me" ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", 
                                fontSize:".85rem",
                                fontWeight:"bold", 
                                padding:"10px"}} xs={12}>
                                    <Grid container justifyContent="center" alignItems="center" sx={{width:"100%", height:"100%", padding:"0px"}} >                                
                                      About Me
                                    </Grid>
                                      
                              </Grid>


                              <Grid item 
                              onClick={() => {this.setState({selectedEntry: "Recent Sales" })}}
                              sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                border: "1px solid #DDDDDD", 
                                },
                                height:"20%",
                                border: this.state.selectedEntry === "Recently Sold" ? "1px solid #DDDDDD" :"1px solid #FFFFFF", 
                                background: this.state.selectedEntry === "Recently Sold" ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", 
                                fontSize:".85rem",
                                fontWeight:"bold", 
                                padding:"10px"}} xs={12}>
                                    <Grid container justifyContent="center" alignItems="center" sx={{width:"100%", height:"100%", padding:"0px"}} >                                
                                      My Recent Sales
                                    </Grid>   
                              </Grid>
                      </Grid>
                      </Grid>
                      </Grid>
                  }
                  mdlTxtColor={this.props.theme.textColor}
                  mdlTxtSize={["1rem", "1rem", "1rem"]}
                  mdlTxtType={"bold"}
                  mdlTxtAlign={"center"}
                  mdlHorizontalAlign={"center"}
                  mdlVerticalAlign={"center"}
                  mdlBgColor={"rgba(255,255,255,1)"}
                  mdlBorder={"0px solid black"}
                  mdlHeight={["400px", "100%", "100%"]}
                  mdlGraphType={null}
                  mdlGraphData={null}
                  btmPadding={"0px"}
                  btmPicture={null}
                  btmPictureAlign={null}
                  btmTxt={null}
                  btmTxtColor={this.props.theme.textColor}
                  btmTxtSize={["1rem", ".85rem", ".85rem"]}
                  btmTxtType={"bold"}
                  btmTxtAlign={"left"}
                  btmTxtLineHeight={"1.25"}
                  btmOverflow={"hidden"}
                  btmHorizontalAlign={"flex-start"}
                  btmVerticalAlign={"flex-start"}
                  btmBgColor={"white"}
                  btmBorder={"0px solid black"}
                  btmHeight={["0px", "0%", "0%"]}
                  btmGraphType={null}
                  btmGraphData={null}
                  border={"0px solid black"}
                  shadow={0}
                  downloads={""}
                  linksTo={""}
                  growTime={4000}
                  padding={"0px"}
                  borderRadius={null}
                  bgcolor={"rgba(255,255,255,1)"}
                />

            </Grid>     
            <Grid
              item
              sx={{
                textAlign:"center",
                height: {
                  sm: "50%",                              
                  md: "55%",                              
                  lg: "50%",                              
                  xl: "60%",                              
                },
              }}
              xs={12}
              sm={12}
              md={7}
              lg={8}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  textAlign:"justify",
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  padding: {
                    xs:"10px 20px 10px 10px",
                    md:"5px 0px 5px 20px",
                    lg:"10px 50px 10px 50px"
                  },
                  lineHeight:"1.5",
                  color:this.props.theme.textColor,
                  fontSize:{
                    xs:"1rem",
                    md:".75rem",
                    lg:".85rem",
                  }
                }}
              >
                      I am committed to providing you 
                      with personalized attention and expert advice to help you achieve 
                      your real estate goals. With a deep understanding of the local 
                      market, contract procedures and the negotiation process, I will work to ensure that your journey is as smooth and 
                      stress-free as possible. 
                  <br/><br/> 
                      For my sellers, I pride myself on my ability to assess sales trends and to provide  
                      accurate market and home valuations you can use to make informed decisions.  
                      When you decide to list with me you'll get access to all my services, including custom marketing and staging 
                      help to showcase your home to its full potential.
                  <br/><br/>    
                      For my buyers, When you work with me you will also get access to all my services that I'll use to find homes that 
                      align seamlessly with your preferences and lifestyle. 
                      When we find your perfect home I'll negotiate on your
                      behalf which includes handling your contract from initial signing all the way through closing.
                  <br/><br/>   
                      I'll be with you every step of the way.                
              </Grid>
            </Grid>

            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "50%",
                  md: "55%",
                  lg: "60%",
                },
                borderBottom:"0px solid #CCCCCC",
                textAlign:"center"
              }}
              xs={0}
              sm={0}
              md={3}
              lg={2}
              >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  backgroundImage: 'url("profilepics/profile-round-lowres.png")',
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              >
              </Grid>
            </Grid>
            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "40%",
                  md: "40%",
                  lg: "40%",
                  xl: "35%",

                },
                borderTop:"1px solid #DDDDDD",
                borderBottom:"1px solid #DDDDDD",
                textAlign:"left"
              }}
              xs={12}
              >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  padding: "0px",
                }}
              >
                {tileBuilder}
              </Grid>
            </Grid>
          </Grid>



        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "12%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
            
          }}
          xs={12}
        >
           <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };


  nextSlide = () => {
    
    if(this.props.testimonials && (this.props.testimonials.length > 0 )){
      if(this.state.selectedIndex+1 < (this.props.testimonials.length)){
        this.setState({
          selectedIndex: this.state.selectedIndex+1
        })
      }else{
        this.setState({
          selectedIndex: 0
        })
      }
    }
  }

  previousSlide = () => {
    if(this.props.testimonials && this.props.testimonials.length > 0){
      if(this.state.selectedIndex > 0){
        this.setState({
          selectedIndex: this.state.selectedIndex-1
        })
      }else{
        this.setState({
          selectedIndex: this.props.testimonials.length-1
        })
      }
    }
  }


  getTestimonialTiles = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "5px 10px 0px 10px",
            height: {
              sm: "100%",
            },
            background: this.props.theme.bannerColor,
          }}
          xl={2}
          lg={2}
          md={2}
          sm={12}
          xs={12}
        >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(255,255,255,0)",
                height:"100%",
                width:"100%",     
                border: "0px solid #AAAAAA",
              }}
            >
            <Button
              onClick={() => this.previousSlide()}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.bannerColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"25%",
                height:"25%",
                background: this.props.theme.bannerColor,
                color:this.props.theme.bannerTextColor,
                border:"1px solid " + this.props.theme.bannerTextColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.75rem",
                  lg:"1.75rem",
                  xl:"1.75rem",
                }
              }}
            >
              <ArrowBackIcon sx={{fontWeight:"bold"}}/>
            </Button>

          </Grid>
          </Grid>

        <Grid
          item
          sx={{
            
            padding: "5px 0px 0px 0px",
            height: {
              sm: "100%",
            },
            background: this.props.theme.bannerColor,
          }}
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
        >

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                fontSize:"1.2rem",
                background: "rgba(255,255,255,0)",
                height:"100%",
                width:"100%",     
                border: "0px solid #AAAAAA",
                color:this.props.theme.bannerTextColor,
                fontWeight:"bold"
              }}
            >
              <div>
                <div style={{fontSize:"1.2rem", fontWeight:"bold"}}>
                  {this.props.testimonials ? this.props.testimonials[this.state.selectedIndex].title : ""}
                </div>                
                <div style={{fontSize:".8rem", fontWeight:"normal"}}>
                  <br/>
                  {this.props.testimonials ? this.props.testimonials[this.state.selectedIndex].summary : ""}
                </div>                
                <div style={{fontSize:".8rem", fontWeight:"normal", float:"right"}}>
                  <br/>
                  {this.props.testimonials ? this.props.testimonials[this.state.selectedIndex].name : ""}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>                
              </div>                
          </Grid>
          </Grid>
          <Grid
          item
          sx={{
            
            padding: "10px 10px 0px 10px",
            height: {
              sm: "100%",
            },
            background: this.props.theme.bannerColor
          }}
          xl={2}
          lg={2}
          md={2}
          sm={12}
          xs={12}
        >

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(255,255,255,0)",
                height:"100%",
                width:"100%",     
                border: "0px solid #AAAAAA",
              }}
            >
            <Button
              onClick={() => this.nextSlide()}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.bannerColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"25%",
                height:"25%",
                color:this.props.theme.bannerTextColor,
                background: this.props.theme.bannerColor,
                border:"1px solid " + this.props.theme.bannerTextColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.75rem",
                  lg:"1.75rem",
                  xl:"1.75rem",
                }
              }}
            >
              <ArrowForwardIcon sx={{fontWeight:"bold"}}/>
            </Button>

          </Grid>
          </Grid>

          </React.Fragment>
        )
  }

  render() {

 
    return (
      <React.Fragment>
      <Drawer
          anchor="bottom"
          open={this.props.showAboutMe}
          onClose={this.props.toggleAboutMe}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              backgroundImage: "url('" + this.props.theme.background + "')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
                  height: {
                xs: "86%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              marginLeft: "auto",
              pointerEvents:"auto",
              border: "0px solid gray"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"0px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                },             
                color:this.props.theme.bannerTextColor  
              }}
            >
            {this.props.name}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("OurTeamAboutMe")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleAboutMe}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.OurTeamAboutMeAboutMeSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top OurTeamAboutMeAboutMeage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getTestimonialTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}
            </Grid>
        </Grid>

      </Grid>

      </Drawer>
             
        </React.Fragment>          
    );
  }
}

export default OurTeamAboutMe;
