import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Fade from "@mui/material/Fade";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import PlayIcon from '@mui/icons-material/PlayCircle';
import Contact from './Contact';
import Image from 'mui-image'
import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class RecentlySold extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      inProgress:false,
      pictureHeight: "100%",
      pictureWidth: "60%",
      selectedEntry:1,
      pictureHeight: "100%",
      pictureWidth: "60%",
      fade: true,
    }
  }


  toggleRecentlySold = () => {
    this.setState({
      RecentlySold: !this.state.RecentlySold,
    });
  };

  /*
    * Contact
    */
  toggleContact = () => {
    this.setState({
      showContact: !this.state.showContact,
    });
  };

  showContact = () => {
    this.setState({
      showContact: !this.state.showContact,
    });
  }


  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  
  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/favorites-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "I will work to ensure that your journey is as smooth and stress-free as possible. Whether you are a first-time buyer or an experienced home owner, I am here to support you every step of the way.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "15px",
            height: {
              xl: "80%",
              lg: "70%",
              md: "70%",
              sm: "70%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"5px"}
            topPicture={null}
            topPictureAlign={null}
            topPictureSize={"contain"}
            topTxt={"My Automated Search Service"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.2rem", "1rem", "1.2rem"]}
            topTxtType={"bold"}
            topTxtAlign={"center"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={"profilepics/favorites-profile.png"}
            mdlPictureAlign={"50% 100%"}
            mdlPictureSize={"contain"}
            mdlTxt={null
            }
            mdlCallback={null}
            mdlTxtColor={this.props.theme.bannerColor}
            mdlTxtSize={["1rem", "1rem", "1.2rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerTextColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["250px", "70%", "70%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <React.Fragment>      
              <Button
              onClick={() => this.props.showContact("Favorites")}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"95%",
                padding: "10px",
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1rem",
                  lg:".75rem",
                  xl:".75rem",
                }
              }}
            >Lets Talk, Text Or Email</Button>
              </React.Fragment>      
            }
            btmWidget={null}
            btmCallback={null}
            btmTxtColor={this.props.theme.bannerTextColor}
            btmTxtSize={["1rem","1rem","1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={this.props.theme.bannerColor}
            btmBorder={"1px solid " + this.props.theme.bannerColor}
            btmHeight={["100px", "20%", "20%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"5px solid "+ this.props.theme.bannerColor}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

 

  getRecentlySoldListingsTiles = () => {
    return (
      <React.Fragment>
        {this.props.recentlySold && this.props.recentlySold.map((tile, index) => {
          return (
            <Grid
              item
              sx={{
                
                width: "100%",
                padding: "15px",
                height: {
                  xl: "60%",
                  lg: "60%",
                  md: "70%",
                  sm: "60%",
                },
              }}
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
            >
        <CommonTile
          uid={tile.ListingId}
          animationType={null}
          animationTime={null}
          tagTopRightIcon={ null }
          tagTopRightTxt={null}
          tagTopRightPadding={"5px 10px 5px 10px"}
          tagTopRightTxtColor={this.props.theme.bannerTextColor}
          tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
          tagTopRightPaddingRight={"0px"}
          tagTopRightPaddingTop={"10px"}
          tagTopRightTxtType={"bold"}
          tagTopRightTxtAlign={"center"}
          tagTopRightBgColor={this.props.theme.bannerColor}
          tagTopRightBorder={"0px solid white"}
          tagTopRightBorderRadius={["20px","0px","0px","20px"]}
          tagTopRightCallback={null}

          tagBottomRightIcon={
            tile.agentsid == "Seller's Agent" ?
            <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="contain" height={25} width={75} duration={200}/>
            :
            null
          }
          tagBottomRightTxt={
            null
          }
          tagBottomRight          
          tagBottomRightPadding={"5px 10px 5px 10px"}
          tagBottomRightTxtColor={"black"}
          tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
          tagBottomRightPaddingRight={"0px"}
          tagBottomRightPaddingBottom={"0px"}
          tagBottomRightTxtType={"bold"}
          tagBottomRightTxtAlign={"center"}
          tagBottomRightBgColor={this.props.theme.highlightColor}
          tagBottomRightBorder={"0px solid black"}
          tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
          tagBottomRightCallback={null}

          tagTopLeftIcon={<Image src={"general/sold-corner-green.png"} bgColor="rgba(255,255,255,0)" fit="contain" height={80} width={75} duration={200}/>}
          tagTopLeftTxt={null}
          tagTopLeftPadding={"0px 0px 0px 0px"}
          tagTopLeftTxtColor={this.props.theme.bannerTextColor}
          tagTopLeftTxtSize={[".75rem", ".75rem",  ".75rem"]}
          tagTopLeftPaddingLeft={"0px"}
          tagTopLeftPaddingTop={"0px"}
          tagTopLeftTxtType={"bold"}
          tagTopLeftTxtAlign={"center"}
          tagTopLeftBgColor={"rgba(12,101,58,0)"}
          tagTopLeftBorder={"0px solid black"}
          tagTopLeftBorderRadius={["0px", "0px", "0px", "0px"]}

          tagBottomLeftIcon={null}
          tagBottomLeftTxt={tile.agentsid}
          tagBottomLeftPadding={"5px 10px 5px 10px"}
          tagBottomLeftTxtColor={"black"}
          tagBottomLeftTxtSize={["1rem", "1rem", "1rem"]}
          tagBottomLeftPaddingLeft={"0px"}
          tagBottomLeftPaddingBottom={"0px"}
          tagBottomLeftTxtType={"bold"}
          tagBottomLeftTxtAlign={"center"}
          tagBottomLeftBgColor={this.props.theme.bannerTextColor}
          tagBottomLeftBorder={"0px solid black"}
          tagBottomLeftBorderRadius={["0px", "20px", "0px", "0px"]}
          tagBottomLeftCallback={this.displayListingReport}

          topCallback={null}
          topPadding={"0px"}
          topPicture={null}
          topPictureAlign={"center"}
          topPictureSize={"cover"}
          topTxt={null}
          topTxtColor={"white"}
          topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
          topTxtType={"bold"}
          topTxtAlign={"left"}
          topVerticalAlign={"flex-end"}
          topHorizontalAlign={"flex-start"}
          topBgColor={"rgba(255,255,255,0)"}
          topHeight={["250px", "75%", "75%"]}
          topGraphType={null}
          topGraphData={null}
          topWidget={
            <Image src={tile.image} fit="cover" duration={200} />
          }
          mdlTagIcon={null}
          mdlTagTxt={null}
          mdlTagPadding={"0px"}
          mdlTagTxtColor={"black"}
          mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
          mdlTagPaddingRight={"4px"}
          mdlTagPaddingTop={"2px"}
          mdlTagTxtType={"bold"}
          mdlTagTxtAlign={"center"}
          mdlTagBgColor={"rgba(255,255,255,0)"}
          mdlTagBorder={"0px solid black"}
          mdlTagBorderRadius={"5px"}                
          mdlPadding={"0px"}
          mdlPicture={null}
          mdlPictureAlign={null}
          mdlTxtLineHeight={1.5}
          mdlTxt={null}

          mdlWidget={
            
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "rgba(255,255,255,0)",
              height:"100%",
              width:"100%",     
              border: "0px solid #AAAAAA",
            }}
          >
              <Grid
                item
                sx={{
                  background: "rgba(255,255,255,0)",
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                }}
                xs={9}
                sm={9}
                md={9}
                lg={9}
                xl={9}
                
              >

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "rgba(255,255,255,0)",
                    height:"100%",
                    width:"100%",     
                    border: "0px solid #AAAAAA",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      fontSize: {
                        xs:"1rem",
                        sm:"1rem",
                        md:"1rem",
                        lg:"1rem",
                        xl:"1rem",
                      },
                      fontWeight:"normal",
                    }}
                    xs={12}
                  >
                      <Box sx={{
                        fontSize:{
                        xs:"1.25rem",
                        sm: "1.25rem",
                        md:"1.25rem",
                        lg:"1.25rem",
                        xl:"1.25rem",
                        },
                        paddingLeft:"10px",
                        fontWeight:"bold", display:"inline"}} >
                    {"$"+tile.price ? tile.price.toLocaleString():"--"}
                    </Box>
                      &nbsp;|&nbsp;
                    {tile.details ? tile.details : ""} 
                  </Grid>                           

                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      paddingLeft:"10px",
                      fontSize: {
                        xs:"1rem",
                        sm: "1rem",
                        md:"1rem",
                        lg:"1rem",
                      }
                    }}
                    xs={12}
                  >
                  {tile.address}
                </Grid>       

                <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      paddingLeft:"10px",
                      fontSize: {
                        xs:"1rem",
                        sm: "1rem",
                        md:"1rem",
                        lg:"1rem",
                      },
                      paddingTop:"5px"
                    }}
                    xs={12}
                  >
                  <div style={{fontSize:".5rem"}}>
                  <img alt="image" src="howardhanna/idx-logo-sm.png" height="10px"/>  MLS:  {tile.mlsid} - Listing By {tile.listingoffice}
                  </div>

              </Grid>                 

              </Grid>                 
              </Grid>                 

              <Grid
                item
                sx={{
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                  height:"100%",
                  width:"100%",     
                }}
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                
              >

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                sx={{
                  background: "rgba(255,255,255,0)",
                  height:"100%",
                  width:"100%",     
                  border: "0px solid #AAAAAA",
                  paddingTop:
                  {
                    lg:"0px",
                  },
                
                }}
              >
                {tile.agentsid == "Seller's Agent" ?
                 <Image src={"profilepics/profile-full-white-lowres.jpg"} bgColor="rgba(255,255,255,0)" fit="contain" duration={200}/>
                 :
                 null
                 }
              </Grid>

              </Grid>
           </Grid>            
          
            }
          mdlTxtColor={"black"}
          mdlTxtSize={["1rem", "1rem",  "1rem"]}
          mdlTxtType={"normal"}
          mdlTxtAlign={"center"}
          mdlHorizontalAlign={"center"}
          mdlVerticalAlign={"flex-end"}
          mdlBgColor={this.props.theme.highlightColor }
          mdlBorder={"1px solid " + this.props.theme.highlightColor }
          mdlHeight={["100px", "25%", "25%" ]}
          mdlGraphType={null}
          mdlGraphData={null}                
          btmPadding={"0px"}
          btmPicture={null}
          btmPictureAlign={null}
          btmTxt={ null }
          btmTxtColor={"#333333"}
          btmTxtSize={[".75rem", ".75rem", "1rem"]}
          btmTxtType={"normal"}
          btmTxtAlign={"left"}
          btmHorizontalAlign={"center"}
          btmVerticalAlign={"center"}
          btmBgColor={this.props.theme.highlightColor}
          btmBorder={"0px solid black"}
          btmHeight={["0px", "0%", "0%"]}
          btmGraphType={null}
          btmGraphData={null}
          border={"2px solid " + this.props.theme.bannerColor}
          shadow={4}
          downloads={""}
          linksTo={""}
          growTime={4000}
          padding={"0px"}
          borderRadius={null}
          bgcolor={"rgba(255,255,255,1)"}
        />
          </Grid>
          );
        })}
      </React.Fragment>
    );
  };

  getIdxTile = () => {

    const currentDate = new Date();

    // Get day, month, year components
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
    const year = currentDate.getFullYear();

    // Get time components
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const localTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Format the output
    const formattedTimestamp = "March 2024 EST"

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            background: "rgba(255,255,255,0)",
            padding: "0px",
            height: {
              xl: "10%",
              lg: "15%",
              md: "15%",
              sm: "50%",
            },
            border:"0px solid black"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"0px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "0px", "0px"]}
            topTxtType={"normal"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["0px", "0%", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["0px", "0px", "0px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"rgba(255,255,255,0)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            padding:"0px 10px 0px 0px",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={2}
                          sm={2}
                          md={1}
                        >
                          
                            <img alt="image" src="howardhanna/idx-lg.png" height={25} width={40}/>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:".55rem",
                              sm:".55rem",
                              md:".55rem",
                              lg:".55rem",
                              xl:".55rem"
                            },
                            height:"100%",
                            width:"100%",   
                            fontWeight:"normal",
                          }}
                          xs={10}
                        >         
                          <div>
                          <div>The data relating to real estate on this web site comes in part from the Internet Data Exchange (IDX)  
                          Program of NYSAMLS’s. Real estate listings held by firms other than Howard Hanna Real Estate Services, are marked 
                          with the Listing Broker’s Firm Name. Data provided is for consumers’ personal, non-commercial use and 
                          may not be used for any purpose other than to identify prospective properties that consumers may be 
                          interested in purchasing. Listing Data last updated at { formattedTimestamp }</div>
                    
                          <div>Disclaimer: All information deemed reliable but not guaranteed and should be independently verified. All 
                          properties are subject to prior sale, change or withdrawal. Neither the listing broker(s) nor Howard Hanna Real  
                          Estate Services shall be responsible for any typographical errors, misinformation, misprints, and shall be held 
                          totally harmless. © {year} . CNYIS, UNYREIS, WNYREIS. All rights reserved.</div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={0}
                          sm={0}
                          md={1}
                        >
                        </Grid>
                        </Grid>
            }
            btmTxtColor={"black"}
            btmTxtSize={["1rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"justify"}
            btmHorizontalAlign={"flex-end"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255,255,255,0)"}
            btmBorder={"0px solid black"}
            btmHeight={["425px", "100%", "100%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"0px solid black"}
            shadow={0}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"10px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,0)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getFooter = () => {
    return (
      <Grid
      item
      sx={{
        width: "100%",
        height: {
          xl: "10%",
          lg: "10%",
          md: "10%",
          sm: "10%",
        },
        padding: "5px",
        fontSize: "2rem",
      }}
      xs={12}
    >
      <Footer theme={this.props.theme} />
    </Grid>
    )
  } 
  

  render() {
 
    return (
      <React.Fragment> 
      <Drawer
          anchor="bottom"
          open={this.props.showRecentlySold}
          onClose={this.props.toggleRecentlySold}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "86%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                fontWeight:"bold",
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",

                },               
                color:this.props.theme.bannerTextColor
              }}
            >
             Recently Sold
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("RecentlySold")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleRecentlySold}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                background: "rgba(255,255,255,.75)"
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

              {this.getRecentlySoldListingsTiles()}
             
              {this.getIdxTile()}
              {this.getFooter()}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
        <Contact
        showContact={this.state.showContact}
        toggleContact={this.toggleContact}
        theme={this.props.theme}
      />
    </React.Fragment> 
    );
  }
}

export default RecentlySold;
