import React from 'react';
import { FormControl, InputLabel, Select, Checkbox, ListItemText, MenuItem } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import AlertDialog from './AlertDialog'
class SearchSelect extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      alertDialogOpen:false,
      inProgress: false,
      openSquareFootages: false,
      openPrices: false,
      openBedrooms: false,
      openBathrooms: false,
      selectedLocations: ["Visible Map Area"],
      selectedBuildingStyles: ["Any"],
      selectedPrices: [150000,550000],
      selectedSquareFootages: [1000,3000],
      selectedBathrooms: [1,4],
      selectedBedrooms: [2,4],
      selectedExtras: ["None"],
    };
  }

  handleLocationsChange = (event) => {
    if(event.target.value[event.target.value.length-1] == "Visible Map Area"){
      this.setState({ selectedLocations: ["Visible Map Area"] });
    }else if (event.target.value.includes("Visible Map Area")){
      let newArray = event.target.value.filter(item => item !== "Visible Map Area");
      this.setState({ selectedLocations: newArray });
    }else{  
      this.setState({ selectedLocations: event.target.value });
    }
  };

  handleBuildingStylesChange = (event) => {
    this.setState({ selectedBuildingStyles: event.target.value });
  };


  handleBathroomsChange = (event) => {
    this.setState({ selectedBathrooms: event.target.value });
  };

  //
  // Square Footates
  //
  handleSquareFootagesChange = (event, newValue) => {
    this.setState({ selectedSquareFootages: newValue });
  };

  closeSquareFootages = () => {
    this.setState({
      openSquareFootages: false
    })   
  }

  openSquareFootages = () => {
    this.setState({
      openSquareFootages: true
    })   
  }

  //
  // Prices
  //
  handlePricesChange = (event, newValue) => {
    this.setState({ selectedPrices: newValue });
  };

  closePrices = () => {
    this.setState({
      openPrices: false
    })   
  }

  openPrices = () => {
    this.setState({
      openPrices: true
    })   
  }



  //
  // Bedrooms
  //
  handleBedroomsChange = (event, newValue) => {
    this.setState({ selectedBedrooms: newValue });
  };

  closeBedrooms = () => {
    this.setState({
      openBedrooms: false
    })   
  }

  openBedrooms = () => {
    this.setState({
      openBedrooms: true
    })   
  }

  //
  // Bedrooms
  //
  handleBathroomsChange = (event, newValue) => {
    this.setState({ selectedBathrooms: newValue });
  };

  closeBathrooms = () => {
    this.setState({
      openBathrooms: false
    })   
  }

  openBathrooms = () => {
    this.setState({
      openBathrooms: true
    })   
  }


  //
  // Extras
  //
  handleExtrasChange = (event) => {
    this.setState({ selectedExtras: event.target.value });
  };


  doSearch = () => {

    const { selectedLocations } = this.state;
    const { selectedBuildingStyles } = this.state;
    const { selectedPrices } = this.state;
    const { selectedSquareFootages } = this.state;
    const { selectedBedrooms } = this.state;
    const { selectedBathrooms } = this.state;
    const { selectedExtras } = this.state;

    this.setState({inProgress:true }, () => {
      this.props.getSearchResults(
        "SearchListings",
        this.props.searchExtents,
        selectedLocations,
        selectedBuildingStyles,
        selectedPrices,
        selectedSquareFootages,
        selectedBedrooms,
        selectedBathrooms,
        selectedExtras,
        this.callback
    )});
  }

  callback = (response) => {
    if(response && response.status === 200){
      this.setState({
        inProgress:false, 
      }, () => {this.props.updateTiles(response.data.searchResults)})
    }else{
      this.setState({
        inProgress:false, 
        alertDialogOpen: true 
      })
    }
  }

  closeAlertDialog = () => {

    this.setState({
      alertDialogOpen: false
    })

  }

  render() {
    const { selectedLocations } = this.state;
    const { selectedBuildingStyles } = this.state;
    const { selectedPrices } = this.state;
    const { selectedSquareFootages } = this.state;
    const { selectedBedrooms } = this.state;
    const { selectedBathrooms } = this.state;
    const { selectedExtras } = this.state;

    const locations = [
      "Visible Map Area", 
      "Baldwinsville",
      "Brewerton",
      "Bridgeport",
      "Camillus",
      "Cicero",
      "Clay",
      "Dewitt",
      "East Syracuse",
      "Elbridge",
      "Fabius",
      "Fayetteville",
      "Jamesville",
      "Jordan",
      "Kirkville",
      "Lafayette",
      "Liverpool",
      "Manlius",
      "Marcellus",
      "Marietta",
      "Memphis",
      "Minoa",
      "Mottville",
      "Nedrow",
      "North Syracuse",
      "Plainville",
      "Pompey",
      "Skaneateles",
      "Syracuse City",
      "Tully",
      "Warners",];
    const buildingStyles = ['Any', 'Townhouse', 'Condo', 'Ranch', 'Two Story'];
    
    const bathrooms = ['Any', '1', '1.5', '2', '2.5', '3', '3.5', '4+'];
    const extras = ["None", "Pool", "Waterfront", "Large Garage (3 Or More)", "Large Lot (1-2 Acres)", "Very Large Lot (2-5 Acres)", "Extra Large Lot (5+ Acres)", "Newer Build (Less Than 10 Years Old)", "Historic Build (More Than 100 Years Old)"];

    return (
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              border:"0px solid black",
              height: "100%",
              width: "100%",
              padding:"5px 1px 0px 1px",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "25px",
            }}
    >

      <FormControl sx={{
        width:{
          xs:"100%", 
          sm:"100%", 
          md:"20%", 
          lg:"25%", 
          xl:"25%" 
        },
        borderTopLeftRadius: "25px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "25px",
        color:this.props.theme.bannerTextColor,
        padding:"10px 5px 2px 5px"}}>

        <InputLabel
          sx={{
            '&.Mui-focused': {
              color: this.props.theme.bannerTextColor,
            },
            color:this.props.theme.bannerTextColor,
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "25px",
          }}            
          id="location-select-label">Local Area</InputLabel>
        <Select
          size="small"
          labelId="location-select-label"
          id="location-select"
          multiple          
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
              backgroundColor: "white",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "25px",
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            },
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "25px",

          }}
          value={selectedLocations}
          onChange={this.handleLocationsChange}
          renderValue={(selected) => selected.join(', ')}
        >
          {locations.map((location) => (
            <MenuItem 
              sx = {{
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "25px",    
              }}
              key={location} value={location}>
              <Checkbox checked={selectedLocations.indexOf(location) > -1} />
              <ListItemText primary={location} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

            {/*
      <FormControl sx={{
        width:{
          xs:"50%", 
          sm:"50%", 
          md:"10%", 
          lg:"10%", 
          xl:"10%" 
        },
        padding:"10px 5px 2px 5px"}}>

      <InputLabel  
      
        sx={{
          '&.Mui-focused': {
            color: this.props.theme.bannerTextColor,
          },
          color:this.props.theme.bannerTextColor
        }}  
    
        id="buildingStyles-select-label">Styles</InputLabel>
        <Select
          size="small"
          labelId="buildingStyles-select-label"
          id="buildingStyles-select"
          multiple
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
              backgroundColor: "white"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            }
        }}
          value={selectedBuildingStyles}
          onChange={this.handleBuildingStylesChange}
          renderValue={(selected) => selected.join(', ')}
        >
          {buildingStyles.map((buildingStyle) => (
            <MenuItem key={buildingStyle} value={buildingStyle}>
              <Checkbox checked={selectedBuildingStyles.indexOf(buildingStyle) > -1} />
              <ListItemText primary={buildingStyle} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          */}
      <FormControl sx={{
        width:{
          xs:"50%", 
          sm:"50%", 
          md:"15%", 
          lg:"18%", 
          xl:"18%" 
        },
        padding:"10px 5px 2px 5px"}}>

      <InputLabel  
      
        sx={{
          '&.Mui-focused': {
            color: this.props.theme.bannerTextColor,
          },
          color:this.props.theme.bannerTextColor
        }}  
    
        id="prices-select-label">Prices ($)</InputLabel>
        <Select
          size="small"
          labelId="prices-select-label"
          id="prices-select"  
          open={this.state.openPrices}   
          onOpen = {this.openPrices}
          value={selectedPrices}
          renderValue={(selected) => { return(selected[1] < 1000000 ?"$"+selected[0]/1000 + "K - $" + selected[1]/1000 + "k" : "$"+selected[0]/1000 + "K - $1M+" )}}
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
              backgroundColor: "white"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            }
        }}
        >
          <Box sx={{ 
            width: {
              xs:250,
              sm:250,
              md:500,
              lg:500,
              xl:500,
          },
          padding:{
            xs:"50px 10px 10px 10px",
            sm:"50px 10px 10px 10px",
            md:"50px 10px 10px 10px",
            lg:"0px 10px 10px 10px",
            xl:"0px 10px 10px 10px",
  
          }
           }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                paddingBottom: "25px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1rem",
                },
                height: {
                  xs: "10%",
                },
              }}
              xs={12}
            >
              <Button
                onClick={this.closePrices} sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.bannerColor,
                    background:this.props.theme.bannerColor
                  },
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  fontSize:".75rem",

                  border:"1px solid " + this.props.theme.bannerTextColor,
                  cursor: "pointer", float:"right", marginTop:0, color:this.props.theme.bannerTextColor,background:this.props.theme.bannerColor}}>Close</Button>
            </Grid>

            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "50%",
                },
              }}
              xs={12}
            >
              <Slider
                marks={true}
                min={50000}
                max={1000000}
                step={25000}
                valueLabelDisplay="on"
                getAriaLabel={() => 'Prices'}
                value={selectedPrices}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: 12, // Set the font size here
                    background:this.props.theme.textColor
                  },
                  color:this.props.theme.textColor
                }}
                onChange={this.handlePricesChange}
              />   
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                color:this.props.theme.bannerColor,
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >
              
              ${selectedPrices[0].toLocaleString()}  - ${selectedPrices[1] < 1000000 ? selectedPrices[1].toLocaleString() : "1,000,000+"}
            </Grid> 
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontWeight: "bold",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >        
            <Button variant="outlined" 
            onClick={this.closePrices} 
            sx={{
              '&:hover': {
                border:"1px solid #AAAAAA",
                background:this.props.theme.bannerColor,      
              },
              textTransform: "none",
              width: "20%",
              height: "100%",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
              borderBottomLeftRadius: "15px",
              color:this.props.theme.bannerTextColor,
              border:"1px solid " + this.props.theme.buttonColor,      
              background:this.props.theme.bannerColor,      
            
            }}
              >Save</Button> 
            </Grid>
            </Grid>
           </Box>
        </Select>
      </FormControl>

      <FormControl sx={{
        width:{
          xs:"50%", 
          sm:"50%", 
          md:"15%", 
          lg:"17%", 
          xl:"17%" 
        },
        padding:"10px 5px 2px 5px"}}>
      <InputLabel  
      
        sx={{
          '&.Mui-focused': {
            color: this.props.theme.bannerTextColor,
          },
          color:this.props.theme.bannerTextColor
        }}  
    
        id="squareFootages-select-label">Living Area (sf)</InputLabel>
        <Select
          size="small"
          labelId="squareFootages-select-label"
          id="squareFootages-select"  
          open={this.state.openSquareFootages}   
          onOpen = {this.openSquareFootages}
          value={selectedSquareFootages}
          renderValue={(selected) => { return( selected[1] < 5000 ? selected[0].toLocaleString() + " - " + selected[1].toLocaleString() : selected[0].toLocaleString() + " - 5,000+")}}
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
              backgroundColor: "white"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            }
        }}
        >
          <Box sx={{ 
            width: {
              xs:250,
              sm:250,
              md:500,
              lg:500,
              xl:500,
          },
          padding:{
          xs:"50px 10px 10px 10px",
          sm:"50px 10px 10px 10px",
          md:"50px 10px 10px 10px",
          lg:"0px 10px 10px 10px",
          xl:"0px 10px 10px 10px",
          }
           }}>
             <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                paddingBottom: "25px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1rem",
                },
                height: {
                  xs: "10%",
                },
              }}
              xs={12}
            >
              <Button
                onClick={this.closeSquareFootages} sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.bannerColor,
                    background:this.props.theme.bannerColor
                  },
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  fontSize:".75rem",
                  border:"1px solid " + this.props.theme.bannerTextColor,
                  cursor: "pointer", float:"right", marginTop:0, color:this.props.theme.bannerTextColor,background:this.props.theme.bannerColor}}>Close</Button>
            </Grid>

            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "50%",
                },
              }}
              xs={12}
            >
                <Slider
                  marks={true}
                  min={500}
                  max={5000}
                  step={100}
                  valueLabelDisplay="on"
                  getAriaLabel={() => 'Square Footage'}
                  value={selectedSquareFootages}
                  sx={{
                    '& .MuiSlider-valueLabel': {
                      fontSize: 12, // Set the font size here
                      background:this.props.theme.textColor
                    },
                    color:this.props.theme.textColor
                  }}
  
                  onChange={this.handleSquareFootagesChange}
                />              
              </Grid>
              <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                color:this.props.theme.bannerColor,
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >
                {selectedSquareFootages[0].toLocaleString()} sf.  - {selectedSquareFootages[1] < 5000 ? selectedSquareFootages[1].toLocaleString() : "5,000+"} sf.
            </Grid> 
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontWeight: "bold",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >
      
              <Button 
              variant="outlined" 
              onClick={this.closeSquareFootages} 
              sx={{
                '&:hover': {
                  border:"1px solid #AAAAAA",
                  background:this.props.theme.bannerColor,      
                },
                textTransform: "none",
                width: "20%",
                height: "100%",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                color:this.props.theme.bannerTextColor,
                border:"1px solid " + this.props.theme.buttonColor,      
                background:this.props.theme.bannerColor,      
              
              }}
                >Save</Button> 
              </Grid>            
            </Grid>            
           </Box>
        </Select>
      </FormControl>


      <FormControl sx={{
        width:{
          xs:"50%", 
          sm:"50%", 
          md:"10%", 
          lg:"10%", 
          xl:"10%" 
        },
        padding:"10px 5px 2px 5px"}}>

      <InputLabel  
      
        sx={{
          '&.Mui-focused': {
            color: this.props.theme.bannerTextColor,
          },
          color:this.props.theme.bannerTextColor
        }}  
    
        id="bedrooms-select-label">Bedrooms</InputLabel>
        <Select
          size="small"
          labelId="bedrooms-select-label"
          id="bedrooms-select"  
          open={this.state.openBedrooms}   
          onOpen = {this.openBedrooms}
          value={selectedBedrooms}
          renderValue={(selected) => selected.join(' - ')}
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
              backgroundColor: "white"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            }
        }}
        >
          <Box sx={{ 
            width: {
              xs:250,
              sm:250,
              md:500,
              lg:500,
              xl:500,
          },
          padding:{
            xs:"50px 10px 10px 10px",
            sm:"50px 10px 10px 10px",
            md:"50px 10px 10px 10px",
            lg:"0px 10px 10px 10px",
            xl:"0px 10px 10px 10px",
  
          }
           }}>
                         <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                paddingBottom: "25px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1rem",
                },
                height: {
                  xs: "10%",
                },
              }}
              xs={12}
            >
              <Button
                onClick={this.closeBedrooms} sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.bannerColor,
                    background:this.props.theme.bannerColor,
                  },
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  fontSize:".75rem",
                  border:"1px solid " + this.props.theme.bannerTextColor,
                  cursor: "pointer", float:"right", marginTop:0, color:this.props.theme.bannerTextColor,background:this.props.theme.bannerColor}}>Close</Button>
            </Grid>

            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "10px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "50%",
                },
              }}
              xs={12}
            >
              <Slider
                marks={true}
                min={1}
                max={10}
                step={1}
                valueLabelDisplay="on"
                getAriaLabel={() => 'Bedrooms'}
                value={selectedBedrooms}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: 12, // Set the font size here
                    background:this.props.theme.textColor
                  },
                  color:this.props.theme.textColor
                }}

                onChange={this.handleBedroomsChange}
              />   
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                color:this.props.theme.bannerColor,
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >
              
              {selectedBedrooms[0].toLocaleString()}  - {selectedBedrooms[1].toLocaleString()} Bedrooms
            </Grid> 
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontWeight: "bold",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >        
            <Button 
            variant="outlines" 
            onClick={this.closeBedrooms} 
            sx={{
              '&:hover': {
                border:"1px solid #AAAAAA",
                background:this.props.theme.bannerColor,      
              },
              textTransform: "none",
              width: "20%",
              height: "100%",
              borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
              color:this.props.theme.bannerTextColor,
              border:"1px solid " + this.props.theme.buttonColor,      
              background:this.props.theme.bannerColor,      
            
            }}
           >Save</Button> 
            </Grid>
            </Grid>
           </Box>
        </Select>
      </FormControl>

      <FormControl sx={{
        width:{
          xs:"50%", 
          sm:"50%", 
          md:"10%", 
          lg:"10%", 
          xl:"10%" 
        },
        padding:"10px 5px 2px 5px"}}>

      <InputLabel  
      
        sx={{
          '&.Mui-focused': {
            color: this.props.theme.bannerTextColor,
          },
          color:this.props.theme.bannerTextColor
        }}  
  
      id="bedrooms-select-label">Bathrooms</InputLabel>
        <Select
          size="small"
          labelId="bathrooms-select-label"
          id="bathrooms-select"  
          open={this.state.openBathrooms}   
          onOpen = {this.openBathrooms}
          value={selectedBathrooms}
          renderValue={(selected) => selected.join(' - ')}
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
              backgroundColor: "white"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            }
        }}
        >
          <Box sx={{ 
            width: {
              xs:250,
              sm:250,
              md:500,
              lg:500,
              xl:500,
          },
          padding:{
            xs:"50px 10px 10px 10px",
            sm:"50px 10px 10px 10px",
            md:"50px 10px 10px 10px",
            lg:"0px 10px 10px 10px",
            xl:"0px 10px 10px 10px",
  
          }
           }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                paddingBottom: "25px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1rem",
                },
                height: {
                  xs: "10%",
                },
              }}
              xs={12}
            >
              <Button
                onClick={this.closeBathrooms} sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.bannerColor,
                    background:this.props.theme.bannerColor
                  },
                  border:"1px solid " + this.props.theme.bannerTextColor,
                  cursor: "pointer", float:"right", marginTop:0, color:this.props.theme.bannerTextColor,background:this.props.theme.bannerColor}}>Close</Button>

            </Grid>

            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "60%",
                },
              }}
              xs={12}
            >
              <Slider
                marks={true}
                min={1}
                max={10}
                step={.5}
                valueLabelDisplay="on"
                getAriaLabel={() => 'Bathrooms'}
                value={selectedBathrooms}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: 12, // Set the font size here
                    background:this.props.theme.textColor
                  },
                  color:this.props.theme.textColor
                }}
                onChange={this.handleBathroomsChange}
              />   
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                color:this.props.theme.bannerColor,
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >
              
              {selectedBathrooms[0].toLocaleString()}  - {selectedBathrooms[1].toLocaleString()} Bathrooms

            </Grid> 
            <Grid
              item
              sx={{
                textAlign: "center",
                padding: "5px",
                borderBottom: "0px solid #A0A0A0",
                fontWeight: "bold",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "20%",
                },
              }}
              xs={12}
            >        
            <Button 
              variant="outlined" 
              onClick={this.closeBathrooms} 
              sx={{
                '&:hover': {
                  border:"1px solid #AAAAAA",
                  background:this.props.theme.bannerColor,      
                },
                textTransform: "none",
                width: "20%",
                height: "100%",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                color:this.props.theme.bannerTextColor,
                border:"1px solid " + this.props.theme.buttonColor,      
                background:this.props.theme.bannerColor,      
              
              }}
                  
                >Save</Button> 
            </Grid>
            </Grid>
           </Box>
        </Select>
      </FormControl>

              {/*
      <FormControl sx={{
        width:{
          xs:"50%", 
          sm:"50%", 
          md:"10%", 
          lg:"10%", 
          xl:"10%" 
        },
        padding:"10px 5px 2px 5px"}}>

      <InputLabel  
          sx={{
            '&.Mui-focused': {
              color: this.props.theme.bannerTextColor,
            },
            color:this.props.theme.bannerTextColor
          }}  
          id="extras-select-label">Extras</InputLabel>
        <Select
          size="small"
          labelId="extras-select-label"
          id="extras-select"
          multiple
          sx={{
            '& .MuiSelect-select.MuiSelect-select':{
                backgroundColor: "white"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border:"1px solid " + this.props.theme.bannerTextColor
            },
            background:"white",
            fontSize:{
              xs:"1rem", 
              sm:"1rem", 
              md:"1rem", 
              lg:"1rem", 
              xl:"1rem"
            },
            padding:{
              xs:"2px", 
              sm:"2px", 
              md:"0px", 
              lg:"5px", 
              xl:"5px"
            }
        }}
          value={selectedExtras}
          onChange={this.handleExtrasChange}
          renderValue={(selected) => selected.join(', ')}
        >
          {extras.map((extra) => (
            <MenuItem key={extra} value={extra}>
              <Checkbox checked={selectedExtras.indexOf(extra) > -1} />
              <ListItemText primary={extra} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          */}
      <Button
          onClick={this.props.toggleAppSize}
          variant={this.props.theme.buttonVariant}
          sx={{
            '&:hover': {
              border:"1px solid " + this.props.theme.buttonTextColor,
              background:this.props.theme.buttonHoverColor
            },

            padding:"7px 5px 7px 5px",
            margin:"5px 0px 0px 10px",
            textTransform: "none",
            textAlign: "center",
              width:{
                xs:"0%", 
                sm:"0%", 
                md:"0%", 
                lg:"5%", 
                xl:"5%" 
              },
            display:{
              xs:"none", 
              sm:"none", 
              md:"none", 
              lg:"inline", 
              xl:"inline" 
            },
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            color:this.props.theme.buttonTextColor,
            border:"1px solid " + this.props.theme.buttonColor,
          }}
        >
          <PhotoSizeSelectLargeIcon />
        </Button>


      <Button
          onClick={this.doSearch}
          variant={this.props.theme.buttonVariant}
          sx={{
            '&:hover': {
              border:"1px solid " + this.props.theme.buttonTextColor,
              background:this.props.theme.buttonHoverColor
            },
            padding:{
              xs:"5px",
              sm:"5px",
              md:"5px",
              lg:"10px",
              xl:"10px"
            },
            margin:"5px 0px 0px 5px",
            textTransform: "none",
            textAlign: "center",
              width:{
                xs:"100%", 
                sm:"100%", 
                md:"10%", 
                lg:"6%", 
                xl:"6%" 
              },
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              color:this.props.theme.buttonTextColor,
              border:"1px solid " + this.props.theme.buttonColor,

          }}
        >
          {this.state.inProgress ?
            <CircularProgress disableShrink size={24} sx={{
              color: 'white'}}></CircularProgress>
          
          : "Search"}
        </Button>


        <Button
          onClick={this.props.toggleSearchListings}
          variant={this.props.theme.buttonVariant}
          sx={{
            '&:hover': {
              border:"1px solid " + this.props.theme.buttonTextColor,
              background:this.props.theme.buttonHoverColor
            },
            padding:{
              xs:"5px",
              sm:"5px",
              md:"5px",
              lg:"10px",
              xl:"10px"
            },
            margin:"5px 0px 0px 5px",
            textTransform: "none",
            textAlign: "center",
              width:{
                xs:"100%", 
                sm:"100%", 
                md:"10%", 
                lg:"6%", 
                xl:"6%" 
              },
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              borderBottomLeftRadius: "0px",
              color:this.props.theme.buttonTextColor,
              border:"1px solid " + this.props.theme.buttonColor,
          }}
        >
          Close
        </Button>

      </Grid>

    );
  }
}

export default SearchSelect;
